import React, { memo, useEffect, useRef, useState } from "react";
import "./InstituteDashboard.css";
import {
  getInstituteDashBoardData,
  getScheduledCourses,
} from "../../../Apis/getApis";
import DashboardCardsInstitute from "../../Components/DashboardComponents/dashboardCards/dashboardCardsInstitute";
import ChartAndSubCard from "../../Components/DashboardComponents/insitituteMidCards/chartAndSubCard";
import InstituteFollowCard from "../../Components/DashboardComponents/instituteFinalCard/InstituteFollowCard";

const InstituteDashboard = memo(() => {
  const [courseDetails, setCourseDetails] = useState();
  const [profileVisits, setProfileVisits] = useState(0);
  const [totalCourses, setTotalCourses] = useState(0);

  const [lableData, setLabelData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [range, setRange] = useState("");

  const [showChart, setShowChart] = useState(false);

  const [DashboardData, setDashboardData] = useState([]);

  const instituteOnboardingRef = useRef();
  const courseRef = useRef();

  const setFocus = () => {
    courseRef.current.focus();
  };

  useEffect(() => {
    getInstituteDashBoardData(fromDate, toDate, range)
      .then((res) => {
        setProfileVisits(res?.data?.data?.profile_visits);
        setTotalCourses(res?.data?.data?.total_courses);
        setTimeout(() => {
          setShowChart(true);
        }, 200);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (
      (fromDate !== "" && toDate !== "") ||
      range !== "" ||
      (fromDate === "" && toDate === "" && range === "")
    ) {
      getScheduledCourses(fromDate, toDate, range)
        .then((res) => {
          let labelArray = [];
          let enrolledData = [];
          let dataArr = res?.data?.data?.results;

          for (let i = 0; i < dataArr.length; i++) {
            labelArray.push(dataArr[i].course_name);
            enrolledData.push(
              dataArr[i]?.total_seats - dataArr[i]?.available_seats
            );
          }
          setLabelData(labelArray);
          setUserData(enrolledData);
          console.log(res);
          setCourseDetails(res?.data?.data?.results);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [fromDate, toDate, range]);

  return (
    <div className="dashBoard-container">
      <div
        className="instituteOnboarding-list-view mb-5"
        tabIndex={-1}
        ref={instituteOnboardingRef}
      >
        <div className="headerPart">
          <h4>DashBoard</h4>
        </div>

        <div
          className="dashboard-data-card InstituteMainContainer"
          style={{
            height: "auto",
            display: "flex",
            justifyContent: "start",
            flexWrap: "wrap",
            flexDirection: "column",
            minHeight: "auto",
            width: "100%",
            borderTopColor: "ActiveBorder",
          }}
        >
          <div>
            <DashboardCardsInstitute
              profileVisits={profileVisits}
              totalCourses={totalCourses}
            />
          </div>
          <div>
            <ChartAndSubCard
              lableData={lableData}
              userData={userData}
              fromDate={fromDate}
              setFromDate={setFromDate}
              toDate={toDate}
              setToDate={setToDate}
              range={range}
              setRange={setRange}
            />
          </div>
          <div>
            <InstituteFollowCard />
          </div>
        </div>
      </div>
    </div>
  );
});

export default InstituteDashboard;
