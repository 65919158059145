import React, { useEffect, useState } from "react";
import { allowedTypesImages } from "../../../../utils/variables/folderStructureFiles";
import { FaCloudUploadAlt, FaEye, FaEyeSlash } from "react-icons/fa";
import {
  saveInstituteOnBoarding,
  verifiedEmailCheck,
  verifyEmail,
} from "../../../../Apis/postApis";
import { getCities, getCountries, getStates } from "../../../../Apis/getApis";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import validator from "validator";
import { v4 as uuidV4 } from "uuid";
import {
  isValidEmail,
  successToast,
  uploadFile,
} from "../../../../utils/helperFunctions/HelperFunctions";
import { toast, ToastContainer } from "react-toastify";
const CompanyOnboardingForm = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cities, setCitiesList] = useState([]);
  const [showPassword, setShowpassword] = useState(false);
  const [loading, setLoading] = useState(false);
  // form fields
  const [contactPerson, setContactPerson] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [entityName, setEntityName] = useState("");
  const [email, setEmail] = useState("");
  const [websiteName, setWebsiteName] = useState("");
  const [countryName, setCountryName] = useState("Country");
  const [city, setCity] = useState("City");
  const [state, setState] = useState("State");
  const [address, setAddress] = useState("");
  const [otherAddress, setOtherAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [otherPhone, setOtherPhone] = useState("");
  const [image, setImage] = useState("");
  const [countryId, setCountryId] = useState();
  const [stateId, setStateId] = useState();
  const [cityId, setCityId] = useState();
  const [file, setFile] = useState(null);
  const [pincode, setPincode] = useState("");
  const navigate = useNavigate();
  const [phoneCode, setPhoneCode] = useState("");

  const errorToast = (msg) => toast.error(msg);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (allowedTypesImages.includes(selectedFile?.type)) {
      setFile(selectedFile);
      let res = uploadFile(selectedFile);
      res.then((data) => {
        console.log(data, "uploadedImage");
        setImage(data);
      });
    } else {
      errorToast("Invalid file type. Only images are allowed.");
    }
  };

  const handleCancel = () => {
    setEmail("");
    setContactPerson("");
    setAddress("");
    setEntityName("");
    setPassword("");
    setCitiesList([]);
    setCity("");
    setCityId();
    setConfirmPassword("");
    setWebsiteName("");
    setPhone("");
    setOtherPhone("");
    setOtherAddress("");
    setStateList([]);
    setStateId();
    setState("");
    setPincode("");
  };

  function validUrl() {
    if (websiteName !== "") {
      if (!validator.isURL(websiteName)) {
        errorToast("Please enter valid url");
        setWebsiteName("");
        return;
      }
    }
  }

  useEffect(() => {
    setWidth(window.innerWidth);
  }, [window.innerWidth]);

  const saveForm = (e) => {
    e.preventDefault();
    console.log("i am here");

    if (entityName == "") {
      errorToast(`Please Enter Company Name`);
      return;
    }
    if (contactPerson == "") {
      errorToast("Please Enter Contact person name");
      return;
    }
    if (password == "") {
      errorToast("Please Enter password");
      return;
    }
    if (confirmPassword == "") {
      errorToast("Please Select Confirm the password");
      return;
    }
    if (email == "") {
      errorToast("Please enter email Id");
      return;
    }
    if (websiteName == "") {
      errorToast("Please Enter Website URL");
      return;
    }
    if (countryId == "") {
      errorToast("Please Select the country");
      return;
    }
    if (address == "") {
      errorToast("Please Give address");
      return;
    }
    if (phone == "") {
      errorToast("Please Enter the phone number");
      return;
    }
    let object = {
      email: email,
      first_name: entityName,
      contact_person: contactPerson,
      website: websiteName,
      mobile_number1: phone,
      mobile_number2: otherPhone,
      address1: address,
      address2: otherAddress,
      state: stateId,
      city: cityId,
      country: countryId,
      pin_code: pincode,
      status: "pending",
      user_type: "company",
      password: password,
      profile_pic: image,
    };

    if (!validator.isEmail(email)) {
      errorToast("Please enter valid Email");
      return;
    } else {
      saveInstituteOnBoarding(object)
        .then((res) => {
          successToast("Company Added successfully");
          navigate("/onboarding-company");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const checkEmail = () => {
    if (email !== "") {
      if (isValidEmail(email)) {
        verifyEmail({ email: email })
          .then((resData) => {
            verifiedEmailCheck({ email: email })
              .then((res) => {
                console.log(res, "resEmail");
                if (res?.data?.data == 1) {
                } else if (res?.data?.data == 0) {
                  errorToast("User with this email Id Already exist");
                  setEmail("");
                }
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        errorToast("Invalid Email id");
        setEmail("");
      }
    }
  };

  const getCountryById = (id) => {
    for (let i = 0; i < countriesList.length; i++) {
      if (countriesList[i]?.id == id) {
        return countriesList[i];
      }
    }
  };

  const getStateById = (id) => {
    for (let i = 0; i < stateList.length; i++) {
      if (stateList[i]?.id == id) {
        return stateList[i];
      }
    }
  };

  const getCityById = (id) => {
    for (let i = 0; i < cities.length; i++) {
      if (cities[i]?.id == id) {
        return cities[i];
      }
    }
  };

  const passwordMatch = () => {
    if ((password !== "" && password.length <= 3) || password.length >= 16) {
      setPassword("");
      errorToast(
        "Password Must be more than 3 characters and less than 15 characters"
      );
    }
    if (
      (confirmPassword !== "" && confirmPassword.length <= 3) ||
      confirmPassword.length >= 16
    ) {
      setConfirmPassword("");
      errorToast(
        "ConfirmPassword Must be more than 3 characters and less than 15 characters"
      );
    }

    if (
      password !== "" &&
      confirmPassword !== "" &&
      password !== confirmPassword
    ) {
      errorToast("Password and confirm Password are not matching");
      setPassword("");
      setConfirmPassword("");
    }
  };

  const makeCountry = (item) => {
    setCountryName((prev) => (prev = item.name));
    setCountryId(item?.id);
    setPhoneCode(item?.phone_code);
    getStates(item.id)
      .then((res) => {
        setStateList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const makeState = (item) => {
    setState(item.name);
    setStateId(item?.id);
    setState((prev) => (prev = item.name));
    getCities(item.id)
      .then((res) => {
        setCitiesList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const makeCity = (item) => {
    setCityId(item?.id);
    setCity((prev) => (prev = item?.name));
  };

  useEffect(() => {
    setLoading(true);
    getCountries()
      .then((res) => {
        setLoading(false);
        setCountriesList(res?.data?.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  return (
    <div
      className=" boarding-form fluid-container"
      style={{ marginBottom: "20px", paddingLeft: "0px" }}
    >
      <div className="instituteOnboarding-list-view">
        <div className="headerPart">
          <h4>Add Shipping Company</h4>
        </div>
      </div>

      <form className="fluid-container mt-2 p-4 bg-white shadow rounded-top position-relative">
        {/* <span className='mustStar h-25' style={{ textAlign: 'end', fontSize: "15px", position: 'absolute', top: "-20px", left: "-16px" }}>Fields marked (*) are mandatory.</span> */}
        <div className="row mb-4 d-flex flex-row justify-content-around">
          <div className="col-md-5">
            {/* <label className="form-label" htmlFor="instituteName">
                            Company Name<span className='mustStar'>*</span>
                        </label> */}
            <input
              placeholder="Company Name"
              required
              id="instituteName"
              value={entityName}
              onChange={(e) => setEntityName(e.target.value)}
              type="text"
              className=" sailors-Input-field px-2"
            />
          </div>
          <div className="col-md-5">
            {/* <label className="form-label" htmlFor="contactPerson">
                            Contact Person<span className='mustStar'>*</span>
                        </label> */}
            <input
              placeholder="Contact Person"
              required
              id="contactPerson"
              type="text"
              value={contactPerson}
              onChange={(e) => setContactPerson(e.target.value)}
              className="sailors-Input-field px-2"
            />
          </div>
        </div>

        <div className="row mb-3 d-flex flex-row justify-content-around">
          <div className="col-md-5">
            {/* <label className="form-label" htmlFor="password">
                            Password<span className='mustStar'>*</span>
                        </label> */}

            <input
              placeholder="Password"
              required
              id="password"
              value={password}
              onBlur={() => passwordMatch()}
              onChange={(e) => setPassword(e.target.value)}
              type={showPassword ? "text" : "password"}
              className=" sailors-Input-field px-2"
            />
            {!showPassword ? (
              <FaEyeSlash
                onClick={() => setShowpassword(!showPassword)}
                style={{
                  position: "absolute",
                  cursor: "pointer",
                  color: "#0000004D",
                  width: "25px",
                  height: "25px",
                  right: "22",
                  top: "10",
                }}
              />
            ) : (
              <FaEye
                onClick={() => setShowpassword(!showPassword)}
                style={{
                  position: "absolute",
                  cursor: "pointer",
                  color: "#0000004D",
                  width: "25px",
                  height: "25px",
                  right: "22",
                  top: "10",
                }}
              />
            )}
            <p
              className="mt-2 ml-1"
              style={{ fontSize: "10px", color: "#76767680" }}
            >
              Password with 1 digit, 1 upper and 1 special
            </p>
          </div>
          <div className="col-md-5">
            {/* <label className="form-label" htmlFor="confirmPassword">
                            Confirm Password<span className='mustStar'>*</span>
                        </label> */}
            <input
              placeholder="Confirm Password"
              required
              id="confirmPassword"
              value={confirmPassword}
              onBlur={() => passwordMatch()}
              onChange={(e) => setConfirmPassword(e.target.value)}
              type="password"
              className="sailors-Input-field px-2"
            />
            <p
              className="mt-2 ml-1"
              style={{ fontSize: "10px", color: "#76767680" }}
            >
              Password with 1 digit, 1 upper and 1 special
            </p>
          </div>
        </div>

        <div className="row mb-4 d-flex flex-row justify-content-around">
          <div className="col-md-5">
            {/* <label className="form-label" htmlFor="instituteName">
                            Pin Code
                        </label> */}
            <input
              id="instituteName"
              placeholder="Pin Code"
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
              type="number"
              className=" sailors-Input-field px-2"
            />
          </div>
          <div className="col-md-5 ">
            {/* <label className="form-label" htmlFor="email">
                            Email<span className='mustStar'>*</span>
                        </label> */}
            <input
              placeholder="email"
              required
              id="email"
              value={email}
              onBlur={checkEmail}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              className="sailors-Input-field px-2"
            />
          </div>
        </div>

        <div className="row mb-4 d-flex flex-row justify-content-around">
          <div className="col-md-5">
            {/* <label className="form-label" htmlFor="websiteName">
                            Website URL<span className='mustStar'>*</span>
                        </label> */}
            <input
              placeholder="Website URL"
              id="websiteName"
              value={websiteName}
              onBlur={validUrl}
              onChange={(e) => setWebsiteName(e.target.value)}
              type="text"
              className="sailors-Input-field px-2"
            />
          </div>
          <div className="col-md-5">
            {/* <label className="form-label" htmlFor="country">
                            Country<span className='mustStar'>*</span>
                        </label> */}
            <select
              id="country"
              style={{ minHeight: "40px" }}
              value={countryId}
              onChange={(e) => makeCountry(getCountryById(e.target.value))}
              className="sailors-Input-field"
            >
              <option value="">Select Country</option>
              {countriesList?.map((item) => {
                return (
                  <option
                    value={item?.id}
                    key={uuidV4()}
                    className="dropdown-item"
                    style={{ height: "50px" }}
                  >
                    {item?.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <div className="row mb-4 d-flex flex-row justify-content-around">
          <div className="col-md-5">
            {/* <label className="form-label" htmlFor="state">
                            State
                        </label> */}
            <select
              id="state"
              style={{ minHeight: "40px" }}
              value={stateId}
              onChange={(e) => makeState(getStateById(e.target.value))}
              className="form-select sailors-Input-field"
            >
              <option>Select State</option>
              {stateList?.map((item) => {
                return (
                  <option
                    value={item.id}
                    key={uuidV4()}
                    className="dropdown-item"
                    style={{ height: "50px" }}
                  >
                    {item?.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-md-5">
            {/* <label className="form-label" htmlFor="city">
                            City
                        </label> */}
            <select
              id="city"
              value={cityId}
              style={{ minHeight: "40px" }}
              onChange={(e) => makeCity(getCityById(e.target.value))}
              className="form-select sailors-Input-field"
            >
              <option>Select City</option>
              {cities?.map((item) => {
                return (
                  <option
                    value={item?.id}
                    key={uuidV4()}
                    className="dropdown-item"
                    style={{ height: "50px" }}
                  >
                    {item?.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <div className="row mb-4 d-flex flex-row justify-content-around">
          <div className="col-md-5">
            {/* <label className="form-label" htmlFor="address">
                            Address<span className='mustStar'>*</span>
                        </label> */}
            <textarea
              rows="3"
              id="address"
              placeholder="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              type="text"
              className="sailors-Input-field px-2"
            />
          </div>
          <div className="col-md-5">
            {/* <label className="form-label" htmlFor="otherAddress">
                            Other Address
                        </label> */}
            <textarea
              rows="3"
              id="otherAddress"
              placeholder="Other Address"
              value={otherAddress}
              onChange={(e) => {
                setOtherAddress(e.target.value);
              }}
              type="text"
              className="sailors-Input-field px-2"
            />
          </div>
        </div>

        <div className="row mb-3 d-flex flex-row justify-content-around mx-10">
          <div className="col-md-5">
            {/* <label className="form-label" htmlFor="contactNumber">
                            Contact Number<span className='mustStar'>*</span>
                        </label> */}
            <div className="input-group">
              <div
                className="input-group-text sailors-Input-field"
                style={{ maxWidth: "70px", textAlign: "center" }}
              >
                {" "}
                {phoneCode !== "" ? phoneCode : "📞"}
              </div>
              <input
                id="contactNumber"
                placeholder="Contact Number"
                type="number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="form-control sailors-Input-field"
              />
            </div>
          </div>
          <div className="col-md-5">
            {/* <label className="form-label" htmlFor="otherContactNumber">
                            Other Contact Number
                        </label> */}
            <div className="input-group">
              <div
                className="input-group-text sailors-Input-field"
                style={{ maxWidth: "70px", textAlign: "center" }}
              >
                {" "}
                {phoneCode !== "" ? phoneCode : "📞"}
              </div>
              <input
                value={otherPhone}
                placeholder="Other Contact Number"
                onChange={(e) => setOtherPhone(e.target.value)}
                id="otherContactNumber"
                type="number"
                className="form-control sailors-Input-field"
              />
            </div>
          </div>
        </div>

        <div className="mb-4 d-flex flex-row justify-content-around ">
          <label className="form-label w-25" htmlFor="uploadLogo">
            Upload Logo{" "}
            <FaCloudUploadAlt style={{ width: "30px", marginLeft: "10px" }} />
          </label>
          <input
            id="uploadLogo"
            type="file"
            onChange={(e) => handleFileChange(e)}
            className="col-md-5 w-50 p-2 px-5 sailors-Input-field"
          />
        </div>

        <div
          className="form-btns"
          style={{ marginBottom: "20px", marginTop: "50px" }}
        >
          <button type="submit" onClick={(e) => saveForm(e)} className="btn">
            Save
          </button>
          <button
            type="button"
            onClick={handleCancel}
            className="btn btn-OnboardForm"
            style={{ border: "1px solid #005A80" }}
          >
            Cancel
          </button>
        </div>
      </form>
      <ToastContainer
        position="top-center"
        style={{
          maxHeight: "50px",
        }}
      />
    </div>
  );
};

export default CompanyOnboardingForm;
