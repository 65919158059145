import React from "react";
const AdminDashboardCards = React.memo(({ dashboardsAllData }) => {
  const { job_application_data, total_jobs_count, learning_course_data } =
    dashboardsAllData;
  const {
    total_job_application_count,
    pending_job_application_count,
    rejected_job_application_count,
    hired_job_application_count,
  } = job_application_data;
  return (
    <div className="mt-4 fluid-container adminDashboard-cards">
      <div className="w-full">
        <div
          className="card mb-2"
          style={{ height: "16.625rem", borderTop: "4px solid #0000001A" }}
        >
          <div className="card-body">
            <div className="d-flex flex-column mb-5 h-auto gap-y-2">
              <img
                src="/assets/adminAssets/dashboardAssets/dashboardCard.svg"
                alt="img"
                className="mb-2"
                style={{ width: "50px", height: "50px", margin: "auto" }}
              />
              <div className="w-auto w-50">
                <h5
                  className="card-title ms-2"
                  style={{
                    textDecoration: "underline 1px #2A737A66",
                    textUnderlineOffset: "10px",
                    color: "#337B99",
                  }}
                >
                  Added Jobs
                </h5>
              </div>
              <h6
                className="card-subtitle mb-2 text-muted mt-2 ms-2"
                style={{ fontSize: "14px", fontWeight: "400" }}
              >
                - Total Jobs Posted: {total_jobs_count}
              </h6>
              {/* <h6
                className="card-subtitle mb-2 text-muted mt-2 ms-2"
                style={{ fontSize: "14px", fontWeight: "400" }}
              >
                - Featured Jobs: 200
              </h6> */}
            </div>
          </div>
        </div>
      </div>

      <div className="w-full">
        <div
          className="w-full card mb-2"
          style={{ height: "16.625rem", borderTop: "4px solid #0000001A" }}
        >
          <div className="card-body">
            <div className="d-flex flex-column h-auto mb-5 gap-y-2">
              <img
                src="/assets/adminAssets/dashboardAssets/dashboardCard.svg"
                alt="img"
                className="size-7 mb-2"
                style={{ width: "50px", height: "50px", margin: "auto" }}
              />
              <div className="w-auto w-50">
                <h5
                  className="card-title ms-2"
                  style={{
                    textDecoration: "underline 1px #2A737A66",
                    textUnderlineOffset: "10px",
                    color: "#337B99",
                  }}
                >
                  Total Applications
                </h5>
              </div>
              <h6
                className="card-subtitle mb-1 text-muted mt-1 ms-2"
                style={{ fontSize: "14px", fontWeight: "400" }}
              >
                - Total Job Applications: {total_job_application_count}
              </h6>
              <h6
                className="card-subtitle mb-1 text-muted mt-1 ms-2"
                style={{ fontSize: "14px", fontWeight: "400" }}
              >
                - Pending Applications: {pending_job_application_count}
              </h6>
              <h6
                className="card-subtitle mb-1 text-muted mt-1 ms-2"
                style={{ fontSize: "14px", fontWeight: "400" }}
              >
                - Approved Applications: {hired_job_application_count}
              </h6>
              <h6
                className="card-subtitle mb-1 text-muted mt-1 ms-2"
                style={{ fontSize: "14px", fontWeight: "400" }}
              >
                - Rejected Applications: {rejected_job_application_count}
              </h6>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full">
        <div
          className="container card mb-2"
          style={{
            height: "16.625rem",
            borderTop: "4px solid #0000001A",
            marginLeft: "0px",
          }}
        >
          <div className="card-body mb-3">
            <div className="d-flex flex-column h-auto mb-5 gap-y-2">
              <img
                src="/assets/adminAssets/dashboardAssets/dashboardCard.svg"
                alt="img"
                className="size-7 mb-2"
                style={{ width: "50px", height: "50px", margin: "auto" }}
              />
              <div className="w-auto w-50">
                <h5
                  className="card-title ms-2"
                  style={{
                    textDecoration: "underline 1px #2A737A66",
                    textUnderlineOffset: "10px",
                    color: "#337B99",
                  }}
                >
                  Learning Count
                </h5>
              </div>
              <h6
                className="card-subtitle mb-1 text-muted mt-2 ms-2"
                style={{ fontSize: "14px", fontWeight: "400" }}
              >
                - Total Learning Courses:{" "}
                {learning_course_data?.institute_learning_course_count}
              </h6>
              <h6
                className="card-subtitle mb-1 text-muted mt-2 ms-2"
                style={{ fontSize: "14px", fontWeight: "400" }}
              >
                - Interested Learners:{" "}
                {learning_course_data?.pending_learners_count}
              </h6>
              <h6
                className="card-subtitle mb-1 text-muted mt-2 ms-2"
                style={{ fontSize: "14px", fontWeight: "400" }}
              >
                - Enrolled Learners:{" "}
                {learning_course_data?.admitted_learners_count}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default AdminDashboardCards;
