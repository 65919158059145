import React, { useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { BallTriangle } from "react-loader-spinner";
import "./report.css";
import {
  getEnrollData,
  GetLearningCourses,
  loadMoreData,
} from "../../../Apis/getApis";
import { useDownloadExcel } from "react-export-table-to-excel";
import Popup from "reactjs-popup";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import SpecialReport from "./specialReportPage/SpecialReport";

const Reports = () => {
  const [name, setName] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [studentList, setStudentList] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [courseData, setCourseData] = useState([]);
  const [course, setCourse] = useState("");
  const [toDate, setToDate] = useState("");
  const [status, setStatus] = useState("");
  const [showIt, setShowIt] = useState(false);
  const [specialReport, setSpecialReport] = useState(false);
  const tableRef = useRef();
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Users table",
    sheet: "Users",
  });
  const [openPdfRef, setOpenPdfRef] = useState(false);
  const closeModal = () => setOpenPdfRef(!openPdfRef);

  const ManualSearch = () => {
    if (fromDate !== "" && toDate !== "") {
    }
    getStudentData()
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  async function getStudentData() {
    async function loadData(resNext) {
      if (resNext == null) {
        return;
      }

      await loadMoreData(resNext)
        .then((res) => {
          setCourseData((prev) => [...prev, ...res?.data?.data?.results]);
          loadData(res?.data?.data.next);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    await GetLearningCourses("accepted")
      .then((res) => {
        setCourseData((prev) => [...prev, ...res?.data?.data?.results]);
        loadData(res?.data?.data.next);
      })
      .catch((err) => {
        console.log(err);
      });

    getEnrollData(name, status, fromDate, toDate)
      .then((res) => {
        setStudentList(res?.data?.data?.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleClear = () => {
    setCourse("");
  };

  useEffect(() => {
    getStudentData();
  }, []);

  useEffect(() => {
    getStudentData();
  }, []);

  const printPdf = () => {
    setSpecialReport(true);
    // setTimeout(()=>{
    //     window.print()
    // },1000)

    const content = ReactDOMServer.renderToString(
      <SpecialReport studentList={studentList} />
    );
    const printWindow = window.open("", "", "width=800,height=600");
    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
        </head>
        <body>
          ${content}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
    printWindow.close();

    // const input = document.getElementById("divToPrint");
    // html2canvas(input).then((canvas) => {
    //   const imgData = canvas.toDataURL("image/png");
    //   const pdf = new jsPDF();
    //   pdf.addImage(imgData, "JPEG", 0, 0);
    //   // pdf.output('dataurlnewwindow');
    //   pdf.save("download.pdf");
    // });
  };

  return (
    <div>
      <div className="institute-container animate__animated animate__fadeIn">
        <div className="instituteOnboarding-list-view">
          <div className="headerPart">
            <h4>Reports</h4>
          </div>
        </div>

        <div className="table-container">
          <div className="allDropDown allField-learning-courses">
            <div className="row mb-3">
              <label className="col-sm-3 col-form-label">Select Course</label>
              <div className="col-sm-9">
                <select
                  value={course}
                  onChange={(e) => {
                    setCourse(e.target.value);
                  }}
                  className="form-select sailors-Input-field bg-white"
                >
                  <option>Select Course Title</option>
                  {courseData.length > 0 ? (
                    courseData?.map((item, index) => {
                      return (
                        <option value={item.id}>{item?.course_name}</option>
                      );
                    })
                  ) : (
                    <center style={{ marginTop: "10%" }}>
                      !Opps check the Internet
                    </center>
                  )}
                </select>
              </div>
            </div>

            <div className="row mb-3">
              <label className="col-sm-3 col-form-label">
                Period<span className="mustStar">*</span>
              </label>
              <div className="col-sm-9 d-flex justify-content-between align-items-center">
                <input
                  type="text"
                  style={{ maxWidth: "280px" }}
                  placeholder="From date 🗓️"
                  className="dateInput sailors-Input-field"
                  onFocus={(e) => (e.target.type = "date")}
                  value={fromDate}
                  onBlur={(e) => {
                    if (fromDate == "") {
                      e.target.type = "text";
                    }
                  }}
                  onChange={(e) => setFromDate(e.target.value)}
                />
                <p
                  className="w-25"
                  style={{ maxWidth: "30px", textAlign: "center" }}
                >
                  To
                </p>
                <input
                  type="text"
                  style={{ maxWidth: "280px" }}
                  placeholder="To date 🗓️"
                  className="dateInput sailors-Input-field"
                  value={toDate}
                  onChange={(e) => {
                    setToDate(e.target.value);
                  }}
                  onFocus={(e) => (e.target.type = "date")}
                  onBlur={(e) => {
                    if (toDate == "") {
                      e.target.type = "text";
                    }
                  }}
                />
              </div>
            </div>

            <div className="row mb-3">
              <label className="col-sm-3 col-form-label">Select Status</label>
              <div className="col-sm-9">
                <select
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                  className="form-select sailors-Input-field bg-white"
                >
                  <option value="">Select Status</option>
                  <option value="pending">Pending</option>
                  <option value="rejected">Shortlisted</option>
                  <option value="admitted">Admitted</option>
                </select>
              </div>
            </div>

            <div className="form-btns">
              <button type="submit" onClick={ManualSearch} className="btn">
                Search
              </button>
              <button
                type="button"
                onClick={handleClear}
                className="btn btn-OnboardForm"
                style={{ border: "1px solid #005A80" }}
              >
                Clear
              </button>
            </div>
          </div>
        </div>

        {/* <div className='table-container' id="divToPrint">
        <div className='allDropDown allField-learning-courses'>

        <div className="row mb-3">
            <label className="col-sm-3 col-form-label">Select Course<span className='mustStar'>*</span></label>
            <div className="col-sm-9">
              <select className="form-select sailors-Input-field bg-white" value={course} onChange={(e) => { setCourse(e.target.value) }}>
                {courseData.length > 0 && <option>Select Course Category</option>}
                {courseData.length > 0 ? courseData?.map((item, index) => {
                  return (
                    <option value={item?.id}>{item?.course_name}</option>
                  )
                }) : <option >!Opps check the Internet</option>}

              </select>
            </div>
          </div>

          <span className='d-flex add-course-container justify-content-center' style={{ maxWidth: "100%", padding: "10px" }}>
            <p style={{ maxWidth: "231px" }}>Period</p>
            <div className='form-control sailors-Input-field' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <input type='text' style={{ maxWidth: "180px" }} placeholder='From date' className='dateInput' onFocus={(e) => (e.target.type = 'date')} value={fromDate} onBlur={(e) => {
                if (fromDate == "") {
                  e.target.type = 'text'
                }
              }} onChange={(e) => setFromDate(e.target.value)} />
              <span style={{ maxWidth: "100px", textAlign: 'center' }}>To</span>
              <input type='text' style={{ maxWidth: "180px" }} className='dateInput' placeholder='To Date' value={toDate} onChange={(e) => { setToDate(e.target.value) }} onFocus={(e) => (e.target.type = 'date')} onBlur={(e) => {
                if (toDate == "") {
                  e.target.type = 'text'
                }
              }} />
            </div>
          </span>

          <span className='d-flex add-course-container justify-content-center' style={{ maxWidth: "100%", padding: "10px" }}>
            <p style={{ maxWidth: "231px" }}>Course Status</p>
            <div className='subCheckDivMain sailors-Input-field'>
              <div className='subCheckDiv' style={{ width: '170px' }}>
                <label style={{ marginTop: '26px' }}>Pending</label>
                <input type="radio" name='status' value='pending' checked={status === 'pending' ? true : false} onChange={() => setStatus('pending')} style={{ width: "24px", height: '30px' }} />
              </div>

              <div className='subCheckDiv' style={{ width: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <label style={{ marginTop: '26px', marginRight: '5px' }}>Admitted</label>
                <input type='radio' name='status' value='admitted' onChange={() => setStatus('admitted')} checked={status === 'admitted' ? true : false} style={{ width: "30px", height: '30px' }} />
              </div>

              <div className='subCheckDiv' style={{ width: '170px' }}>
                <label style={{ marginTop: '26px' }}>Rejected</label>
                <input type="radio" name='status' value='non-admitted' checked={status === 'non-admitted' ? true : false} onChange={() => setStatus('non-admitted')} style={{ width: "24px", height: '30px' }} />
              </div>
            </div>
          </span>
        
          <div className='form-btns'>
            <button  type="submit" onClick={ManualSearch} className="btn" style={{border:"1px solid #0a66c2"}}>Search</button>
            <button type='button' className="btn btn-OnboardForm" onClick={handleClear} style={{border:"1px solid #0a66c2"}}>Clear</button>
          </div>
        </div>
        

      </div> */}

        <div
          className="instituteOnboarding-list-view"
          style={{ marginTop: "20px" }}
        >
          <div className="headerPart">
            <h4>Courses List</h4>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <label style={{ maxWidth: "50px" }}>PDF</label>
                <input
                  type="radio"
                  name="download"
                  checked={showIt}
                  onClick={() => setShowIt(!showIt)}
                  style={{ maxWidth: "30px" }}
                />
                <label style={{ maxWidth: "70px" }}>Excel</label>
                <input
                  type="radio"
                  style={{ maxWidth: "30px" }}
                  name="download"
                  checked={!showIt}
                  onClick={() => setShowIt(!showIt)}
                />
              </div>
            </div>
            {showIt && (
              <button
                class="button-30"
                style={{ maxWidth: "150px" }}
                onClick={() => {
                  setTimeout(() => {
                    printPdf();
                  }, 100);
                }}
              >
                Download PDF
              </button>
            )}
            {!showIt && (
              <button
                class="button-30"
                style={{ maxWidth: "170px" }}
                onClick={onDownload}
              >
                Download Excel
              </button>
            )}
            {/* <button class="button-30" style={{maxWidth:'170px'}} onClick={onDownload} >Download Excel</button> */}
          </div>
        </div>
        {loading ? (
          <div style={{ maxWidth: "100px", maxHeight: "100px" }}>
            <BallTriangle
              height={20}
              width={20}
              radius={5}
              color="red"
              ariaLabel="ball-triangle-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
            <span>Loading...</span>
          </div>
        ) : (
          <div className="table-container">
            {studentList?.length > 0 ? (
              <div
                className="table-Data"
                id="divToPrint"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                {/* <img src="/assets/authAssets/Sailors-Hub-logo.gif" alt="SailorsHub" className='navBarLogo' style={{margin:"auto",marginTop:"15px",marginBottom:"10px"}}/> */}
                <center style={{ fontSize: "18px" }}>Report</center>
                <table className="table" ref={tableRef}>
                  <thead>
                    <tr
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingBottom: "20px",
                      }}
                    >
                      <th
                        scope="col"
                        style={{ maxWidth: "70px", textAlign: "start" }}
                      >
                        Sr No.
                      </th>
                      <th
                        scope="col"
                        style={{
                          width: "calc(100%/5)",
                          maxWidth: "300px",
                          textAlign: "start",
                        }}
                      >
                        Candidate Name
                      </th>
                      <th
                        scope="col"
                        style={{
                          width: "calc(100%/5)",
                          maxWidth: "300px",
                          textAlign: "start",
                        }}
                      >
                        Course Name
                      </th>
                      <th
                        scope="col"
                        style={{
                          width: "calc(100%/5)",
                          maxWidth: "300px",
                          textAlign: "start",
                        }}
                      >
                        Contact
                      </th>
                      <th
                        scope="col"
                        style={{
                          width: "calc(100%/5)",
                          maxWidth: "300px",
                          textAlign: "start",
                        }}
                      >
                        Course Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {studentList.map((item, index) => {
                      return (
                        <tr
                          className=""
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            paddingBottom: "20px",
                          }}
                        >
                          <th
                            style={{ maxWidth: "70px", textAlign: "start" }}
                            scope="row"
                          >
                            {index + 1}.
                          </th>
                          <td
                            style={{
                              width: "calc(100%/5)",
                              maxWidth: "100%",
                              textAlign: "start",
                            }}
                          >
                            {item.candidate_name}
                          </td>
                          <td
                            style={{
                              width: "calc(100%/5)",
                              maxWidth: "100%",
                              textAlign: "start",
                            }}
                          >
                            {item.course_name}
                          </td>
                          <td
                            style={{
                              width: "calc(100%/5)",
                              maxWidth: "100%",
                              textAlign: "start",
                            }}
                          >
                            {item.mobile_number1}
                          </td>
                          <td
                            style={{
                              width: "calc(100%/5)",
                              maxWidth: "100%",
                              textAlign: "start",
                            }}
                          >
                            {item.status}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <center>No Data found</center>
            )}
          </div>
        )}

        <Popup open={openPdfRef} closeOnDocumentClick onClose={closeModal}>
          <div className="modal">
            <p className="close" onClick={closeModal}>
              &times;
            </p>
          </div>
        </Popup>
      </div>
    </div>
  );
};

export default Reports;
