// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ql-container{
    min-height:300px ;
}
.ql-picker-options{
    min-height: 150px;
}
.ql-picker-item{
    max-height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/Admin/components/editor/QuillEditorNews/quill.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,gBAAgB;AACpB","sourcesContent":[".ql-container{\n    min-height:300px ;\n}\n.ql-picker-options{\n    min-height: 150px;\n}\n.ql-picker-item{\n    max-height: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
