import React from "react";
import { Doughnut } from "react-chartjs-2";
import "bootstrap/dist/css/bootstrap.min.css";
import "./sailorchart.css"; // Custom styles if needed
import "chart.js/auto";

const SailorChart = React.memo(({ dashboardsAllData }) => {
  const {
    total_sailors_count,
    verified_sailors_count,
    unverified_sailors_count,
    rejected_sailors_count,
  } = dashboardsAllData;
  const data = {
    labels: ["Verified Sailors", "Unverified Sailors", "Rejected Sailors"],
    datasets: [
      {
        data: [
          verified_sailors_count,
          unverified_sailors_count,
          rejected_sailors_count,
        ],
        backgroundColor: ["#4F8D00", "#EF5350", "#FFCD4B"],
        hoverBackgroundColor: ["#81C784", "#e36c6a", "#d9ba5d"],
        borderWidth: 2,
      },
    ],
  };

  const options = {
    cutout: "78%", // This will make it a doughnut chart
    plugins: {
      legend: {
        display: false, // Disable legend to match your design
      },
    },
  };

  return (
    <div className=" h-100">
      <div style={{ borderTop: "5px solid #5C95AD", borderRadius: "10px" }}>
        <div
          className="card p-3 w-70 h-100"
          style={{
            borderTop: "none",
            boxShadow: "-1px 3px 20px 0px #0000001A",
          }}
        >
          <div className="d-flex justify-content-center mb-3">
            <h5 className="admin-report-card-title">Total Sailors</h5>
          </div>
          <div className="doughnut-chart-container d-flex justify-content-center m-auto">
            <Doughnut data={data} options={options} />
            <div className="chart-overlay d-flex align-items-center justify-content-center">
              <img
                src="assets/commonAssets/cardSailorIcon.png"
                alt="captain"
                className=""
              ></img>{" "}
              {/* You can use a relevant icon */}
            </div>
          </div>
          <ul className="list-group list-group-flush text-start ps-5">
            <li className="list-group-item w-auto">
              Total Registered Sailors:{" "}
              <span className="value">{total_sailors_count}</span>
            </li>
            <li className="list-group-item w-auto">
              <span className="dot green"></span> Verified Sailors:{" "}
              <span className="value">{verified_sailors_count}</span>
            </li>
            <li className="list-group-item w-auto">
              <span className="dot red"></span> Unverified Sailors:{" "}
              <span className="value">{unverified_sailors_count}</span>
            </li>
            <li className="list-group-item w-auto">
              <span className="dot yellow"></span> Rejected Sailors:{" "}
              <span className="value">{rejected_sailors_count}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
});

export default SailorChart;
