import React, { useEffect, useState } from 'react'
import { FaCaretDown, FaPen } from 'react-icons/fa';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { BallTriangle } from 'react-loader-spinner';
import { GetLearningCourses, getCountries, getLearningCourseSearch, getRolesAndRank, getScheduledCourses, loadMoreData } from '../../../Apis/getApis';
import { monthsData } from '../../../utils/variables/folderStructureFiles';
import { saveInstituteCourse } from '../../../Apis/postApis';
import Swal from 'sweetalert2';
import { deleteCourseDetails } from '../../../Apis/deleteApis';
import Popup from 'reactjs-popup';
import { updateCourseData } from '../../../Apis/updateApis';
import "../../../css/bootstrap.min.css"
import { deleteToast, errorToast } from '../../../utils/helperFunctions/HelperFunctions';
const ScheduleCourse = () => {
    const [isEdit, setIsEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [scheduledCourseList, setScheduledCourseList] = useState([]);
    const [courseData, setCourseData] = useState([]);
    const [course, setCourse] = useState('');
    const [month, setMonth] = useState(0);
    const [seats, setSeats] = useState('');
    const [availableSeats, setAvailableSeats] = useState('');
    const width = window.innerWidth;
    const [countries, setCountries] = useState([]);
    const [start_time, setStartTime] = useState('');
    const [end_time, setEndTime] = useState('');
    const [expiryUnit, setExpiryUnit] = useState('');
    const [expiryValue, setExpiryValue] = useState('');
    const [batchTimingList, setBatchTimingList] = useState([]);
    const [subscriptionType, setSubscriptionType] = useState('online');
    const [selectedTimings, setSelectedTimings] = useState([]);
    const [selectedItem, setSelectedItem] = useState();
    const [allRanks, setAllRanks] = useState([]);
    const [selectedRanks, setSelectedRanks] = useState([]);
    const [courseFees, setCourseFees] = useState('');
    const [next, setNext] = useState('');
    const [courseSearch, setCourseSearch] = useState('');
    const [courseSearchData, setCourseSeatchData] = useState([]);


    const logInDetails = JSON.parse(localStorage.getItem('userData'));
    const editCourse = (item) => {
        setIsEdit(true)
        setSelectedItem(item);
        setBatchTimingList(item?.batch_durations);
        setCourse(item?.course);
        setSubscriptionType(item?.course_mode);
        setExpiryValue(item?.duration?.value);
        setExpiryUnit(item?.duration?.unit);
        setSeats(item?.total_seats);
        setAvailableSeats(item?.available_seats);
    }

    const handleCancel = () => {
        setIsEdit(false)
        setSelectedItem("");
        setBatchTimingList([]);
        setCourse('');
        setSubscriptionType('');
        setExpiryValue('');
        setExpiryUnit('');
        setSeats('');
        setAvailableSeats('');
    }

    const deleteCourse = (id) => {

        Swal.fire({
            title: "Are you sure?",
            text: "You can revert Changes once you delete!",
            icon: "error",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete Course!"
        }).then((result) => {
            if (result.isConfirmed) {
                deleteCourseDetails(id)
                    .then((res) => {
                        console.log(res);
                        getAllScheduledCourses();
                        deleteToast("Course Deleted Successfully");
                    })
                    .catch((err) => {
                        console.log(err)
                        errorToast("Please check the internet Or try after some Time");
                    })

            }
        });
    }

    useEffect(() => {
        let timer;
        if (courseSearch !== '') {
            timer = setTimeout(() => {
                getLearningCourseSearch(courseSearch)
                    .then((res) => {
                        console.log(res)
                        setCourseSeatchData(res?.data?.data?.results)
                    })
                    .catch((err) => {
                        console.log(err)
                    })

            }, 1000)
        } else {
            setCourseSeatchData([]);
        }
        return (() => {
            clearTimeout(timer);
        })
    }, [courseSearch])

    const getCourseById = (id) => {
        if (courseSearch == '') {
            for (let i = 0; i < courseData.length; i++) {
                if (courseData[i]?.id === id) {
                    return courseData[i]?.course_name
                }
            }
        } else {
            for (let i = 0; i < courseSearchData.length; i++) {
                if (courseSearchData[i]?.id === id) {
                    return courseSearchData[i]?.course_name
                }
            }
        }

        return "Learning Course"
    }

    const findById = (id) => {
        for (let i = 0; i < allRanks.length; i++) {
            if (allRanks[i].id === id) {
                console.log(allRanks[i], "indeed")
                return allRanks[i]
            }
        }
    }

    const saveCourse = () => {
        console.log("data")
        if (expiryUnit == '' || seats == '' || availableSeats == '' || batchTimingList.length == 0) {
            errorToast("Some Data is Missing")
        } else {
            if (!isEdit) {
                saveInstituteCourse({
                    institute: logInDetails?.id,
                    course: course,
                    course_mode: subscriptionType,
                    batch_durations: batchTimingList,
                    duration: {
                        value: expiryValue,
                        unit: expiryUnit
                    },
                    total_seats: seats,
                    available_seats: availableSeats
                })
                    .then((res) => {
                        console.log(res);
                        handleCancel();
                        getAllScheduledCourses();
                    })
                    .catch((err) => {
                        console.log(err);
                        errorToast(err?.response?.data?.message)
                    })
            } else {
                updateCourseData(selectedItem?.id, {
                    institute: logInDetails?.id,
                    course: course,
                    course_mode: subscriptionType,
                    batch_durations: batchTimingList,
                    duration: {
                        value: expiryValue,
                        unit: expiryUnit
                    },
                    total_seats: seats,
                    available_seats: availableSeats
                })
                    .then((res) => {
                        console.log(res);
                        handleCancel();
                        getAllScheduledCourses();

                    })
                    .catch((err) => {
                        console.log(err);
                    })
            }
        }

    }

    const removeCountry = (data) => {
        const countries = [...selectedRanks]
        const newCountry = countries.filter((item) => item !== data);
        setSelectedRanks(newCountry);
    }

    const addTiming = () => {

        if (start_time !== "" && end_time !== "") {
            let start = start_time.split(":");
            let end = end_time.split(":");
            console.log(start, end, "startEnd")
            if (parseInt(end[0]) < parseInt(start[0])) {
                errorToast("batch starting hour is more than end hours");
                return;
            } else if (parseInt(end[0]) == parseInt(start[0])) {
                if (parseInt(end[1]) < parseInt(start[1])) {
                    errorToast("batch starting minutes is more than end minutes");
                    return;
                }
            }
        }

        if (month === 0 || month === undefined || start_time === '' || end_time === '') {
            errorToast("Something is missing. "+' Check the time or month');
            return;
        } else {

            let obj = {
                start_time: start_time,
                end_time: end_time,
                month: month
            }
            console.log(obj, "obj")
            setBatchTimingList([...batchTimingList, obj])
            setStartTime('');
            setEndTime('');
            setMonth(0);
        }
    }

    async function getAllJobRanks() {
        async function loadData(resNext) {
            if (resNext == null) {
                return;
            }
            await loadMoreData(resNext)
                .then((res) => {
                    console.log(res);
                    setAllRanks(prev => [...prev, ...res?.data?.data?.results]);
                    loadData(res?.data?.data?.next)
                })
                .catch((err) => {
                    console.log(err);
                })
        }

        await getRolesAndRank()
            .then((res) => {
                setAllRanks(res?.data?.data?.results);
                loadData(res?.data?.data?.next);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    async function getAllLearningCourses() {

        async function loadData(resNext) {
            if (resNext == null) {
                return;
            }
            await loadMoreData(resNext)
                .then((res) => {
                    console.log(res);
                    setCourseData(prev => [...prev, ...res?.data?.data?.results]);
                    loadData(res?.data?.data?.next)
                })
                .catch((err) => {
                    console.log(err);
                })
        }

        await GetLearningCourses('accepted')
            .then((res) => {
                setCourseData(res?.data?.data?.results);
                loadData(res?.data?.data?.next);
            })
            .catch(err => {
                console.log(err)
            })
    }

    function getAllScheduledCourses() {
        getScheduledCourses()
            .then((res) => {
                setScheduledCourseList(res?.data?.data?.results)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {

        getCountries()
            .then((res) => {
                setCountries(res?.data?.data)
            })
            .catch((err) => {
                console.log(err)
            })

        getAllLearningCourses();
        getAllScheduledCourses();
        getAllJobRanks();
    }, [])

    const addCountry = (countryData) => {
        const isThere = selectedRanks.some((item) => isEdit ? item.id == countryData.id : item == countryData.id);
        if (!isThere) {
            if (isEdit) {
                setSelectedRanks([...selectedRanks, countryData.id])
            } else {
                setSelectedRanks([...selectedRanks, countryData.id])
            }
        }
    }

    const deleteTiming = (data) => {
        let arr = [...batchTimingList];
        let newArr = arr.filter((item) => {
            return item !== data;
        });
        setBatchTimingList(newArr)
    }

    useEffect(() => {

        if (course && courseData.length > 0) {
            let arr = [];
            const getCourse = courseData.filter((item) => item?.id == course);

            if (getCourse[0]?.countries.length > 0) {
                for (let i = 0; i < getCourse[0].countries.length; i++) {
                    let p = 0, q = countries.length - 1;
                    while (p < q) {
                        if (countries[p].id == getCourse[0].countries[i].id) {
                            let obj = {

                            }
                        }
                        p++;
                        q--;
                    }
                }
            } else {

            }
        }
    }, [course])

    return (
        <div className='institute-container animate__animated animate__fadeIn' style={{ transition: 'ease-in-out 250ms' }}>
            <div className='instituteOnboarding-list-view'>
                <div className='headerPart'>
                    <h4>Schedule Course</h4>
                </div>
            </div>
            <div className='table-container' style={{ transition: 'ease-in-out 250ms' }}>
                <div className='allDropDown allField-learning-courses' style={{ transition: 'ease-in-out 250ms' }}>

                    <span className='d-flex add-course-container add-course-country-container justify-content-center' style={{ maxWidth: "100%", padding: "20px" }}>
                        <div className="dropdown dropDown-locality">
                            <button type="button" className={width < 500 ? "btn drdpbtn sailors-Input-field col" : "btn drdpbtn sailors-Input-field row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white", maxWidth: "200px" }}>
                                Ranks<FaCaretDown style={{ width: "15px" }} />
                            </button>
                            <div className="dropdown-menu" style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "300px", height: "200px", overflowY: 'scroll' }}>

                                {allRanks.length > 0 ? allRanks?.map((item, index) => {
                                    return (
                                        <div className="dropdown-item" style={{ height: "50px", cursor: "pointer" }} onClick={() => addCountry(item)}>{item?.name}</div>
                                    )
                                }) : <center style={{ marginTop: "10%" }}>!Opps check the Internet</center>}



                            </div>
                        </div>
                        <div className={selectedRanks.length > 0 ? 'selectedCountries-container' : 'selectedCountries-container noDataContainer'}>

                            {selectedRanks.length > 0 ? <>{selectedRanks.map((item) => {
                                return (
                                    <div className='selectedCountry' >
                                        <p style={{ marginTop: "37px", }}>{findById(item)?.name}</p>
                                        <button onClick={() => removeCountry(item)} type="button" className="close" style={{ width: "15px", height: "15px" }}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                )
                            })}</> : <center style={{ marginTop: "15px" }}>Please Select the Rank</center>}
                        </div>
                    </span>

                    <span className='add-course-container add-course-country-container' style={{ maxWidth: "100%", padding: "20px" }}>
                        <p style={{ maxWidth: "231px" }}>Learning Course Name</p>
                        <div className="dropdown dropDown-locality dropDown-subLearning-Courses" style={{ maxWidth: "928px", marginRight: "-22px" }}>
                            <button type="button" className={width < 500 ? "btn drdpbtn form-control sailors-Input-field col" : "btn form-control drdpbtn sailors-Input-field row"} onError={(err) => { console.log(err, "pavan") }} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white", maxWidth: "100%" }}>
                                {getCourseById(course)} <FaCaretDown style={{ width: "15px" }} />
                            </button>
                            <div className="dropdown-menu" id='scrollableDiv' style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "90%", height: "200px", overflowY: 'scroll' }}>
                                <div className='searchBar-Container position-sticky' style={{ maxHeight: "50px", top: "0" }}>
                                    <div className="input-group" style={{ marginBottom: "-100px", paddingLeft: "10px" }}>
                                        <input type="text" value={courseSearch} onChange={(e) => setCourseSearch(e.target.value)} className="form-select form-control" placeholder="Search ......" aria-label="Recipient's username" />
                                    </div>
                                </div>


                                {courseSearch == '' && <React.Fragment>{courseData.length > 0 ? courseData?.map((item, index) => {
                                    return (
                                        <div className="dropdown-item" onClick={() => { setCourse(item.id) }} style={{ height: "50px", cursor: "pointer" }} >{getCourseById(item?.id)}</div>
                                    )
                                }) : <center style={{ marginTop: "10%" }}>!Opps check the Internet</center>}</React.Fragment>}

                                {courseSearch !== '' && <>{courseSearchData.length > 0 ? courseSearchData.reverse()?.map((item, index) => {
                                    return (
                                        <div className="dropdown-item" onClick={() => { setCourse(item.id) }} style={{ height: "50px", cursor: "pointer" }} >{getCourseById(item?.id)}</div>
                                    )
                                }) : <center style={{ marginTop: "10%", maxHeight: "30px" }}>No Match</center>}</>}
                            </div>
                        </div>
                    </span>

                    <span className='d-flex add-course-container justify-content-center' style={{ maxWidth: "100%", padding: "10px" }}>
                        <p  className="col-form-label" style={{ maxWidth: "231px" }}>Course Mode</p>
                        <div className='subCheckDivMain'>
                            <div className='subCheckDiv'>
                                <label>Online</label>
                                <input type='radio' style={{ accentColor: '#005A80' }} name='subscription' value='online' onChange={() => setSubscriptionType('online')} checked={subscriptionType === 'online' ? true : false} />
                            </div>
                            <div className='subCheckDiv'>
                                <label>Offline</label>
                                <input type="radio" style={{ accentColor: '#005A80' }} name='subscription' value='offline' checked={subscriptionType === 'offline' ? true : false} onChange={() => setSubscriptionType('offline')} />
                            </div>
                        </div>
                    </span>

                    <span className='d-flex add-course-container justify-content-center' style={{ maxWidth: "100%", padding: "10px" }}>
                        <p className='col-form-label' style={{ maxWidth: "231px" }}>Batch Timing </p>
                        <div className='form-control sailors-Input-field' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <input type='time' style={{ maxWidth: "80px" }} value={start_time} onChange={(e) => setStartTime(e.target.value)} />
                            <span style={{ maxWidth: "30px", textAlign: 'center' }}>To</span>
                            <input type='time' style={{ maxWidth: "80px" }} value={end_time} onChange={(e) => setEndTime(e.target.value)} />
                            <div className="dropdown dropDown-locality dropDown-subLearning-Courses" style={{ maxWidth: "150px", border: 'none',background:'transparent' }}>
                                <button type="button" className={width < 500 ? "btn drdpbtn form-control col" : "btn form-control drdpbtn row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white", maxWidth: "100%", display: 'flex', flexDirection: 'row', marginTop:"-8px" }}>
                                    {monthsData[month - 1]?.month || 'Month'}<FaCaretDown style={{ width: "15px" }} />
                                </button>
                                <div className="dropdown-menu" style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "150px", height: "200px", overflowY: 'scroll' }}>

                                    {monthsData.length > 0 ? monthsData?.map((item, index) => {
                                        return (
                                            <div key={item?.value} className="dropdown-item" onClick={() => { setMonth(item.value) }} style={{ height: "50px", cursor: "pointer" }} >{item?.month}</div>
                                        )
                                    }) : <center style={{ marginTop: "10%" }}>!Opps check the Internet</center>}

                                </div>
                            </div>
                            <button onClick={addTiming} style={{ width: "45px", background: '#005A80', color: 'white', borderRadius: "5px" }}>Add</button>
                        </div>

                    </span>

                    {batchTimingList.length > 0 && <span className='d-flex add-course-container justify-content-center' style={{ maxWidth: "100%", padding: "10px", transition: 'ease-in-out 700ms' }} >
                        <div className='table-container' style={{ marginTop: "10px" }}>
                            <div className='table-Data  animate__animated animate__fadeInDown '>
                                <table className='table'>
                                    <thead>
                                        <tr style={{ display: 'flex', justifyContent: "flex-start" }}>
                                            <th style={{ fontWeight: "700", maxWidth: "60px" }}>Sr No.</th>
                                            <th style={{ fontWeight: "700", textAlign: 'center' }}>Start Time</th>
                                            <th style={{ fontWeight: "700", textAlign: 'center' }}>End Time</th>
                                            <th style={{ fontWeight: "700", textAlign: 'center' }}>Month</th>
                                            <th style={{ fontWeight: "700" }}>Actions</th>
                                        </tr>
                                    </thead>
                                    <div style={{ maxHeight: "400px", height: "auto", overflowY: 'scroll', textAlign: 'center', display: 'flex', justifyContent: 'center' }} >

                                        <tbody className='animate_animated animate__fadeInUp' style={{ height: 'auto', textAlign: 'center' }}>
                                            {batchTimingList.length > 0 ? batchTimingList.map((item, index) => {
                                                return (
                                                    <tr className='' style={{ display: 'flex', justifyContent: "space-around", maxHeight: "60px" }}>
                                                        <th scope="row" style={{ width: "120px" }}>{index + 1}</th>
                                                        <td style={{ textWrap: 'wrap', textAlign: 'center' }}>{item.start_time}</td>
                                                        <td style={{ textWrap: 'wrap', textAlign: 'center' }}>{item?.end_time}</td>
                                                        <td style={{ textWrap: 'wrap', textAlign: 'center' }}>{monthsData[item?.month - 1].month}</td>
                                                        <td>
                                                            <div style={{ cursor: "pointer", display: 'flex', flexDirection: "row", justifyContent: 'center' }}>
                                                                <RiDeleteBin6Line onClick={() => deleteTiming(item)} title='Delete' style={{ width: "40px", height: "30px", color: "#F84F31", border: "1px solid gray", padding: "5px", borderRadius: "10px" }} />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }) : <center>No data found</center>}

                                        </tbody>
                                    </div>
                                </table>
                            </div>
                        </div>
                    </span>}

                    <div className="row mb-3">
                        <label className="col-sm-3 col-form-label">Course duration<span className='mustStar'>*</span></label>
                        <div className="col-sm-5 col-xl-3 col-xs-5 w-75 d-flex flex-row align-items-center" style={{width:"100%"}}>
                            <input type="number" value={expiryValue} onChange={(e) => setExpiryValue(e.target.value)} className="form-control sailors-Input-field" placeholder='Enter Value' style={{minWidth:"200px"}}/>
                            <select className='w-100 sailors-Input-field' value={expiryUnit} onChange={(e) => setExpiryUnit(e.target.value)} style={{minWidth:"220px",marginLeft:"5px"}}>
                                <option>Select Month/year</option>
                                <option value={'months'}>Month</option>
                                <option value={'years'}>year</option>
                                <option value={'weeks'}>Week</option>
                                <option value={'days'}>Day</option>

                            </select>
                        </div>
                    </div>

                    {/* <span className='add-course-container add-course-country-container' style={{ maxWidth: "100%", padding: "0px 20px" }}>
                        <p style={{ maxWidth: "231px" }}>Course duration</p>
                        <div className="dropdown dropDown-locality dropDown-subLearning-Courses" style={{ maxWidth: "928px", marginRight: "-22px" }}>
                            <button type="button" className={width < 500 ? "btn drdpbtn form-control sailors-Input-field col" : "btn form-control drdpbtn sailors-Input-field row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white", maxWidth: "100%" }}>
                                {expiryValue !== "" ? expiryValue : 'Select duration'}{" " + expiryUnit}<FaCaretDown style={{ width: "15px" }} />
                            </button>
                            <div className="dropdown-menu" style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "95%", height: "200px", overflowY: 'scroll' }}>
                                <div className='searchBar-Container' style={{ maxHeight: "50px" }}>

                                    <div className="input-group position-sticky" style={{ marginBottom: "-100px", paddingLeft: "10px" }}>
                                        <input className='position-static form-control' type="number" value={expiryValue} onChange={(e) => setExpiryValue(e.target.value)} placeholder="value..." aria-label="Recipient's username" />
                                    </div>

                                </div>
                                {["months", "years", "weeks", "days"]?.map((item, index) => {
                                    return (
                                        <div className="dropdown-item" onClick={() => setExpiryUnit(item)} style={{ height: "50px", cursor: "pointer" }} >{item}</div>
                                    )
                                })}

                            </div>
                        </div>
                    </span> */}

                    <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Course Fees<span className='mustStar'>*</span></label>
                        <div className="col-sm-9">
                            <input type="text" maxLength={10} minLength={10} value={courseFees} onChange={(e) => setCourseFees(e.target.value)} className="form-control sailors-Input-field" />
                        </div>
                    </div>

                    {/* <span className='d-flex add-course-container justify-content-center' style={{ maxWidth: "100%", padding: "10px" }}>
                        <p style={{ maxWidth: "231px" }}>Course Fees</p>
                        <input type='number' value={courseFees} onChange={(e) => setCourseFees(e.target.value)} className='form-control sailors-Input-field' style={{ maxWidth: "1024px" }} />
                    </span> */}

                    <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Total seats<span className='mustStar'>*</span></label>
                        <div className="col-sm-9">
                            <input type="text" maxLength={10} minLength={10} value={seats} onChange={(e) => setSeats(e.target.value)} className="form-control sailors-Input-field" />
                        </div>
                    </div>

                    {/* <span className='d-flex add-course-container justify-content-center' style={{ maxWidth: "100%", padding: "10px" }}>
                        <p style={{ maxWidth: "231px" }}>Total seats</p>
                        <input type='number' value={seats} onChange={(e) => setSeats(e.target.value)} className='form-control sailors-Input-field' style={{ maxWidth: "1024px" }} />
                    </span> */}

                    <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Available seats<span className='mustStar'>*</span></label>
                        <div className="col-sm-9">
                            <input type="text" maxLength={10} minLength={10} value={availableSeats} onChange={(e) => setAvailableSeats(e.target.value)} className="form-control sailors-Input-field" />
                        </div>
                    </div>

                    {/* <span className='d-flex add-course-container justify-content-center' style={{ maxWidth: "100%", padding: "10px" }}>
                        <p style={{ maxWidth: "231px" }}>Available seats</p>
                        <input type='number' value={availableSeats} onChange={(e) => { setAvailableSeats(e.target.value) }} className='form-control sailors-Input-field' style={{ maxWidth: "1024px" }} />
                    </span> */}


                </div>
                <div className='form-btns' style={{ marginBottom: "10px" }}>
                    <button type="submit" onClick={saveCourse} className="btn">{isEdit ? "Update" : "Save"}</button>
                    <button onClick={handleCancel} className="btn btn-OnboardForm">Cancel</button>
                </div>
            </div>
            <div className='instituteOnboarding-list-view' style={{ marginTop: "20px" }}>
                <div className='headerPart'>
                    <h4>All Scheduled Courses</h4>
                </div>
            </div>

            {loading ?
                <div style={{ maxWidth: "100px", maxHeight: "100px" }}>

                    <BallTriangle
                        height={20}
                        width={20}
                        radius={5}
                        color="red"
                        ariaLabel="ball-triangle-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                    <span>Loading...</span>
                </div>

                : <div className='table-container'>

                    <div className='table-Data'>
                        <table className='table'>
                            <thead>
                                <tr style={{ display: 'flex', justifyContent: "flex-start" }}>
                                    <th style={{ fontWeight: "700", maxWidth: "90px" }}>Sr No.</th>
                                    <th style={{ fontWeight: "700", textAlign: 'center' }}>Course Name</th>
                                    <th style={{ fontWeight: "700", textAlign: 'center' }}>Duration</th>
                                    <th style={{ fontWeight: "700", textAlign: 'center' }}>Batch time</th>
                                    <th style={{ fontWeight: "700" }}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {scheduledCourseList.length > 0 ? scheduledCourseList.map((item, index) => {
                                    return (
                                        <tr className='' style={{ display: 'flex', justifyContent: "space-around" }}>
                                            <th scope="row">{index + 1}</th>
                                            <td style={{ textWrap: 'wrap', textAlign: 'center' }}>{getCourseById(item.course)}</td>
                                            <td style={{ textWrap: 'wrap', textAlign: 'center' }}>{item?.duration?.value + " " + item?.duration?.unit}</td>
                                            <td style={{ textWrap: 'wrap', textAlign: 'center' }}>
                                                {/* <button className='btn btn-primary' onClick={()=>showTimings(item)} >See Timings</button> */}
                                                <Popup
                                                    trigger={<button className="button" style={{background:'#005A80',maxWidth:"150px",borderRadius:"5px",color:'white'}}> See Timings </button>}
                                                    modal
                                                    onOpen={() => {
                                                        setSelectedTimings(item.batch_durations);
                                                    }}
                                                >

                                                    <div className="modal" style={{ width: "500px", height: "500px", display: "contents" }}>


                                                        <div className="content" style={{ overflowY: "scroll" }}>
                                                            <center style={{ height: "40px", marginBottom: "30px" }}>
                                                                <h3 >Batch Timings</h3>
                                                            </center>
                                                            {selectedTimings.map((item, index) => {
                                                                return (
                                                                    <center style={{ display: "flex", justifyContent: 'space-between', maxHeight: "40px", background: index % 2 == 1 ? "grey" : "yellow" }}>
                                                                        <span>{item.start_time}</span>
                                                                        <span>To</span>
                                                                        <span>{item.end_time}</span>
                                                                        <span>{monthsData[item.month].month}</span>
                                                                    </center>
                                                                )
                                                            })}
                                                        </div>

                                                    </div>
                                                </Popup>
                                            </td>
                                            <td>
                                                <div style={{ cursor: "pointer", display: 'flex', flexDirection: "row", justifyContent: 'start' }}>
                                                    <FaPen onClick={() => editCourse(item)} title='Details' style={{ width: "40px", height: "30px", color: "#0CB1C0", border: "1px solid gray", padding: "5px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px" }} /><RiDeleteBin6Line onClick={() => deleteCourse(item.id)} title='Delete' style={{ width: "40px", height: "30px", color: "#F84F31", border: "1px solid gray", padding: "5px", borderTopRightRadius: "10px", borderBottomRightRadius: "10px" }} />
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }) : <center>No data found</center>}
                            </tbody>
                        </table>
                    </div>
                </div>}

            {/* <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Batch Timings</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            {selectedTimings.map((item) => {
                                return (
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <span>{item?.start_time}</span>
                                        <span>To</span>
                                        <span>{item?.end_time}</span>
                                    </div>
                                )
                            })}
                            
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            
                        </div>
                    </div>
                </div>
            </div> */}

        </div>
    )
}

export default ScheduleCourse
