import React, { useEffect, useRef, useState } from "react";
import { FaPen } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import ReactDOMServer from "react-dom/server";
import { BallTriangle } from "react-loader-spinner";
import {
  getAllJobsByCompany,
  getCandidateDataByJobAndStatus,
  loadMoreData,
} from "../../../Apis/getApis";
import "../../../css/bootstrap.min.css";
import { errorToast } from "../../../utils/helperFunctions/HelperFunctions";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  DownloadTableExcel,
  useDownloadExcel,
} from "react-export-table-to-excel";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import SpecialReportCompany from "../../../Institute/pages/Reports/specialReportCompany/SpecialReportCompany";

const CompanyReport = () => {
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [candidateList, setCandidateList] = useState([]);
  const [selectedJob, setSelectedJob] = useState("");
  const [next, setNext] = useState(null);
  const tableRef = useRef();

  console.log("testId", process.env.REACT_APP_ACCESSKEY);

  const printPdf = () => {
    // setTimeout(()=>{
    //     window.print()
    // },1000)

    const content = ReactDOMServer.renderToString(
      <SpecialReportCompany studentList={candidateList} />
    );
    const printWindow = window.open("", "", "width=800,height=600");
    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
        </head>
        <body>
          ${content}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
    printWindow.close();

    // const input = document.getElementById("divToPrint");
    // html2canvas(input).then((canvas) => {
    //   const imgData = canvas.toDataURL("image/png");
    //   const pdf = new jsPDF();
    //   pdf.addImage(imgData, "JPEG", 0, 0);
    //   // pdf.output('dataurlnewwindow');
    //   pdf.save("download.pdf");
    // });
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Users table",
    sheet: "Users",
  });
  function getExcel() {
    if (tableRef.current !== undefined) {
      onDownload(tableRef.current);
    }
  }

  const [status, setStatus] = useState("");
  const [postedJobs, setPostedJobs] = useState([]);

  function loadData() {
    loadMoreData(next)
      .then((res) => {
        console.log(res);
        setCandidateList((prev) => [...prev, ...res?.data?.data?.results]);
        setNext(res?.data?.data?.next);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getCandidateDataByJob() {
    if (!selectedJob || selectedJob == "Select Job Title") {
      errorToast("Select Job");
      return;
    }
    if (!status || status == "Select Status") {
      errorToast("Select Status");
      return;
    }

    getCandidateDataByJobAndStatus(selectedJob, status)
      .then((res) => {
        setCandidateList((prev) => (prev = res?.data?.data?.results));
        setNext(res?.data?.data?.next);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function jobsData() {
    async function loadData(resNext) {
      if (resNext == null) {
        return;
      }
      await loadMoreData(resNext)
        .then((res) => {
          setPostedJobs((prev) => [...prev, ...res?.data?.data?.results]);
          loadData(res?.data?.data?.next);
        })
        .cath((err) => {
          throw err;
        });
    }

    await getAllJobsByCompany()
      .then((res) => {
        console.log(res);
        setPostedJobs(res?.data?.data?.results);
        loadData(res?.data?.data?.next);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    jobsData();
  }, []);

  return (
    <div
      className="institute-container animate__animated animate__fadeIn"
      id="scrollableDiv"
    >
      <div className="instituteOnboarding-list-view">
        <div className="headerPart">
          <h4>Reports</h4>
        </div>
      </div>
      <div className="table-container">
        <div className="allDropDown allField-learning-courses">
          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">Select Job</label>
            <div className="col-sm-9">
              <select
                value={selectedJob}
                onChange={(e) => {
                  setSelectedJob(e.target.value);
                }}
                className="form-select sailors-Input-field bg-white"
              >
                <option>Select Job Title</option>
                {postedJobs.length > 0 ? (
                  postedJobs?.map((item, index) => {
                    return (
                      <option key={item?.id.toString()} value={item.id}>
                        {item?.job_title}
                      </option>
                    );
                  })
                ) : (
                  <option style={{ marginTop: "10%" }}>
                    !Opps check the Internet
                  </option>
                )}
              </select>
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">
              Period<span className="mustStar">*</span>
            </label>
            <div className="col-sm-9 d-flex justify-content-start">
              <input
                type="text"
                style={{ maxWidth: "auto" }}
                placeholder="From date 🗓️"
                className="dateInput sailors-Input-field"
                onFocus={(e) => (e.target.type = "date")}
                value={fromDate}
                onBlur={(e) => {
                  if (fromDate == "") {
                    e.target.type = "text";
                  }
                }}
                onChange={(e) => setFromDate(e.target.value)}
              />
              <p
                className="w-25 m-auto"
                style={{ maxWidth: "30px", textAlign: "center" }}
              >
                To
              </p>
              <input
                type="text"
                style={{ maxWidth: "auto" }}
                placeholder="To date 🗓️"
                className="dateInput sailors-Input-field"
                value={toDate}
                onChange={(e) => {
                  setToDate(e.target.value);
                }}
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => {
                  if (toDate == "") {
                    e.target.type = "text";
                  }
                }}
              />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">Select Status</label>
            <div className="col-sm-9">
              <select
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
                className="form-select sailors-Input-field bg-white"
              >
                <option value="">Select Status</option>
                <option value="pending">Pending</option>
                <option value="viewed">In Progress</option>
                <option value="rejected">Shortlisted</option>
                <option value="shortlisted">Rejected</option>
                <option value="hired">Hired</option>
                <option value="joined">Joined</option>
              </select>
            </div>
          </div>

          <div className="form-btns">
            <button
              type="submit"
              onClick={getCandidateDataByJob}
              className="btn"
            >
              Search
            </button>
            <button
              type="button"
              onClick={() => {
                setStatus((prev) => (prev = ""));
                setSelectedJob((prev) => (prev = ""));
              }}
              className="btn btn-OnboardForm"
              style={{ border: "1px solid #005A80" }}
            >
              Clear
            </button>
          </div>
        </div>
      </div>

      <div
        className="instituteOnboarding-list-view"
        style={{ marginTop: "20px" }}
      >
        <div className="headerPart">
          <h4>Candidate List</h4>

          {/* {candidateList?.length > 0 && (
            <button
              class="button-30"
              style={{ maxWidth: "170px" }}
              onClick={onDownload}
            >
              {" "}
              Export excel{" "}
            </button>
          )} */}
        </div>
      </div>
      {loading ? (
        <div style={{ maxWidth: "100px", maxHeight: "100px" }}>
          <BallTriangle
            height={20}
            width={20}
            radius={5}
            color="red"
            ariaLabel="ball-triangle-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
          <span>Loading...</span>
        </div>
      ) : (
        <div className="table-container">
          {candidateList?.length > 0 ? (
            <div className="table-Data">
              <table className="table" ref={tableRef}>
                <thead>
                  <tr style={{ display: "flex", justifyContent: "center" }}>
                    <th scope="col" style={{ maxWidth: "70px" }}>
                      Sr No.
                    </th>
                    <th
                      scope="col"
                      style={{
                        width: "calc(100%/6)",
                        maxWidth: "100%",
                        textAlign: "start",
                      }}
                    >
                      Candidate Name
                    </th>
                    <th
                      scope="col"
                      style={{
                        width: "calc(100%/6)",
                        maxWidth: "100%",
                        textAlign: "start",
                      }}
                    >
                      Application Date
                    </th>
                    <th
                      scope="col"
                      style={{
                        width: "calc(100%/6)",
                        maxWidth: "100%",
                        textAlign: "start",
                      }}
                    >
                      Contact
                    </th>
                    <th
                      scope="col"
                      style={{
                        width: "calc(100%/6)",
                        maxWidth: "100%",
                        textAlign: "start",
                      }}
                    >
                      Candidate Status
                    </th>
                    <th
                      scope="col"
                      style={{
                        width: "calc(100%/6)",
                        maxWidth: "100%",
                        textAlign: "start",
                      }}
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <InfiniteScroll
                    dataLength={candidateList.length}
                    next={loadData}
                    hasMore={next !== null ? true : false}
                    loader={<h6>Loading...</h6>}
                  >
                    {candidateList.map((item, index) => {
                      return (
                        <tr
                          key={item?.id.toString()}
                          className=""
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <th style={{ maxWidth: "70px" }} scope="row">
                            {index + 1}
                          </th>
                          <td
                            style={{
                              width: "calc(100%/6)",
                              maxWidth: "100%",
                              textAlign: "start",
                            }}
                          >
                            {item.candidate_name}
                          </td>
                          <td
                            style={{
                              width: "calc(100%/6)",
                              maxWidth: "100%",
                              textAlign: "start",
                            }}
                          >
                            {item.application_date.slice(0, 10)}
                          </td>
                          <td
                            style={{
                              width: "calc(100%/6)",
                              maxWidth: "100%",
                              textAlign: "start",
                              wordBreak: "break-word",
                            }}
                          >
                            {item.mobile_number}
                          </td>
                          <td
                            style={{
                              width: "calc(100%/6)",
                              maxWidth: "100%",
                              textAlign: "start",
                            }}
                          >
                            {item.status}
                          </td>
                          <td
                            style={{
                              width: "calc(100%/6)",
                              maxWidth: "100%",
                              textAlign: "start",
                            }}
                          >
                            <React.Fragment
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "start",
                              }}
                            >
                              <FaPen
                                data-toggle="modal"
                                data-target="#instituteDetailsModal"
                                title="Details"
                                style={{
                                  width: "30px",
                                  color: "#0CB1C0",
                                  border: "1px solid gray",
                                  padding: "5px",
                                  borderTopLeftRadius: "10px",
                                  borderBottomLeftRadius: "10px",
                                }}
                              />{" "}
                              <RiDeleteBin6Line
                                title="Delete"
                                style={{
                                  width: "30px",
                                  color: "#F84F31",
                                  border: "1px solid gray",
                                  padding: "5px",
                                  borderTopRightRadius: "10px",
                                  borderBottomRightRadius: "10px",
                                }}
                              />
                            </React.Fragment>
                          </td>
                        </tr>
                      );
                    })}
                  </InfiniteScroll>
                </tbody>
              </table>
            </div>
          ) : (
            <center>No Data found</center>
          )}
        </div>
      )}
    </div>
  );
};

export default CompanyReport;
