import React from "react";
import SailorChart from "./charts/firstcardChart/SailorChart";
import InstituteChartAdmin from "./charts/firstcardChart/instituteChartAdmin";
import CompanyChartAdmin from "./charts/firstcardChart/CompanyChartAdmin";

const DashboardCard = React.memo(({ dashboardsAllData }) => {
  const {
    rejected_institutes_count,
    total_companies_count,
    verified_companies_count,
    unverified_companies_count,
    rejected_companies_count,
    total_jobs_count,
    job_application_data,
  } = dashboardsAllData;

  const {
    total_job_application_count,
    pending_job_application_count,
    rejected_job_application_count,
    viewed_job_application_count,
    hired_job_application_count,
    shortlisted_job_application_count,
    joined_job_application_count,
  } = job_application_data;

  return (
    <div className="adminDashboard-cards h-100">
      <div className="">
        <SailorChart dashboardsAllData={dashboardsAllData} />
      </div>
      <div>
        <InstituteChartAdmin dashboardsAllData={dashboardsAllData} />
      </div>
      <div>
        <CompanyChartAdmin dashboardsAllData={dashboardsAllData} />
      </div>
    </div>
  );
});

export default DashboardCard;
