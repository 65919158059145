import React, { useEffect, useState } from "react";
import { GetOnboardingInstitute } from "../../../../Apis/getApis";
import "../InstituteOnboarding/institutOnboarding.css";

import { useNavigate } from "react-router-dom";
import PendingCompanies from "./subFolder/CompanyPending";
import VerifiedCompanies from "./subFolder/VerifiedCompanies";
import RejectedCompany from "./subFolder/RejectedCompany";
const CompanyOnBoarding = () => {
  const navigate = useNavigate();
  const [candidatesStatusBar, setCandidatesStatusBar] = useState({
    new: true,
    verified: false,
    rejected: false,
  });
  const handleStatusBarData = (barValue) => {
    switch (barValue) {
      case "pending":
        setCandidatesStatusBar({ new: true, verified: false, rejected: false });
        break;
      case "verified":
        setCandidatesStatusBar({ new: false, verified: true, rejected: false });
        break;
      case "rejected":
        setCandidatesStatusBar({ new: false, verified: false, rejected: true });
        break;
      default:
        setCandidatesStatusBar({ new: true, verified: false, rejected: false });
    }
  };
  return (
    <div className="institute-container animate__animated animate__fadeIn">
      <div className="instituteOnboarding-list-view">
        <div className="headerPart">
          <h4>Shipping Company List</h4>
          <button
            onClick={() => navigate("/onboarding-company/form")}
            className="addCompanyButton w-auto"
          ></button>
        </div>
      </div>
      <table className="table" style={{ border: "none", marginTop: "50px" }}>
        <thead>
          <tr
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              borderBottom: "1px solid #005A80",
              backgroundColor: "#f7fbfe",
            }}
            className="candidates-status-header"
          >
            <p
              onClick={() => handleStatusBarData("pending")}
              style={{ cursor: "pointer", backgroundColor: "#f7fbfe" }}
              className={candidatesStatusBar.new ? "barActive" : "barInactive"}
            >
              <center>New</center>
            </p>
            <p
              onClick={() => handleStatusBarData("verified")}
              style={{ cursor: "pointer", backgroundColor: "#f7fbfe" }}
              className={
                candidatesStatusBar.verified ? "barActive" : "barInactive"
              }
            >
              <center>Verified</center>
            </p>
            <p
              onClick={() => handleStatusBarData("rejected")}
              style={{ cursor: "pointer", backgroundColor: "#f7fbfe" }}
              className={
                candidatesStatusBar.rejected ? "barActive" : "barInactive"
              }
            >
              <center>Rejected</center>
            </p>
          </tr>
        </thead>
      </table>

      {candidatesStatusBar.rejected === true && <RejectedCompany />}
      {candidatesStatusBar.verified === true && <VerifiedCompanies />}
      {candidatesStatusBar.new === true && <PendingCompanies />}
    </div>
  );
};

export default CompanyOnBoarding;
