import React, { Suspense } from "react";
import Loader from "../common/loader/loader";

import AdminDashboardpage from "../Admin/pages/AdminDashboardpage";
import JobPost from "../Admin/pages/Job_post/JobPost";
import Advertisement from "../Admin/pages/advertisement/Advertisement";
import CourseBatch from "../Admin/pages/courseBatch/CourseBatch";
import AddQuestions from "../Admin/pages/masters/Add_Questions/AddQuestions";
import AdminReport from "../Admin/pages/masters/AdminReport/AdminReport";
import Certificates from "../Admin/pages/masters/Cerificates/Certificates";
import Locality from "../Admin/pages/masters/Country_state_city/locality";
import CourseSubject from "../Admin/pages/masters/Course_Subjects/CourseSubject";
import CreateCertificate from "../Admin/pages/masters/Create_Certificate/CreateCertificate";
import JobRank from "../Admin/pages/masters/Job_Rank/JobRank";
import JobCategory from "../Admin/pages/masters/Job_category/JobCategory";
import NewsAndAnnouncement from "../Admin/pages/masters/News_Announcement/NewsAndAnnouncement";
import OtherQuestions from "../Admin/pages/masters/Other_questions/OtherQuestions";
import Shiptype from "../Admin/pages/masters/Ship_Type/ShipType";
import UploadContent from "../Admin/pages/masters/Upload_content/UploadContent";
import LearningCourses from "../Admin/pages/masters/learningCourses/LearningCourses";
import SubLearningCourses from "../Admin/pages/masters/subCourses/SubLearningCourses";

// Onboarding Pages
import InstituteOnBoarding from "../Admin/pages/onboarding/InstituteOnboarding/InstituteOnBoarding";
import InstituteOnboardingForm from "../Admin/pages/onboarding/InstituteOnboarding/InstituteOnboardingform";
import Candidates from "../Admin/pages/onboarding/candidates/Candidates";
import CompanyOnBoarding from "../Admin/pages/onboarding/companyOnboarding/companyOnboarding";
import CompanyOnboardingForm from "../Admin/pages/onboarding/companyOnboarding/companyOnboardingForm";

// Institute Pages
import AvailableCourses from "../Institute/pages/AvailableCourses";
import CourseRequest from "../Institute/pages/CourseRequest/CourseRequest";
import InstituteDashboard from "../Institute/pages/Dashboard/InstituteDashboard";
import InstituteDetails from "../Institute/pages/InstituteProfile/InstituteDetails";
import Reports from "../Institute/pages/Reports/Reports";
import ScheduleCourse from "../Institute/pages/ScheduleCourse/ScheduleCourse";

// Common Pages
import PageNotFound from "../common/PageNotFound/PageNotFound";
import Sidebar from "../common/sidebarComponents/Sidebar";
import WelcomePage from "../common/welcomePage/welcomePage";
import ResetPassword from "../commonAuth/forgotPassword/ResetPassword";
import ForgetPasswordEmail from "../commonAuth/forgotPassword/forgetPasswordEmail";
import Login from "../commonAuth/login/Login";
import CompanyRegister from "../commonAuth/register/Company Register/CompanyRegister";
import InstituteRegister from "../commonAuth/register/Institute Register/InstituteRegister";
import CompanyDepartment from "../Admin/pages/masters/Add_Department/CompanyDepartment";

// Company Pages
import CompanyDashBoard from "../company/pages/CompanyDashboard/CompanyDashBoard";
import CompanyDetails from "../company/pages/CompanyDetails/CompanyDetails";
import JobpostCompany from "../company/pages/companyJobpost/JobpostCompany";
import CompanyReport from "../company/pages/companyReport/CompanyReport";
import CandidateResume from "../company/pages/companyResume/CandidateResume";
import CompanyOtherInfo from "../company/pages/otherInfo/OtherInfo";

// Protected Route
const ProtectRoute = React.lazy(() => import("./ProtectRoute"));

const { BrowserRouter, Routes, Route } = require("react-router-dom");

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register/company" element={<CompanyRegister />} />
          <Route path="/register/institute" element={<InstituteRegister />} />
          <Route path="/forgotPassword" element={<ForgetPasswordEmail />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
          {/* All Routes with login Protection */}
          <Route
            path="/Admin"
            element={
              <ProtectRoute>
                <Sidebar>
                  <AdminDashboardpage />
                </Sidebar>
              </ProtectRoute>
            }
          />
          <Route
            path="/onboarding-institute"
            element={
              <ProtectRoute>
                <Sidebar>
                  <InstituteOnBoarding />
                </Sidebar>
              </ProtectRoute>
            }
          />
          <Route
            path="/onboarding-institute/form"
            element={
              <ProtectRoute>
                <Sidebar>
                  <InstituteOnboardingForm />
                </Sidebar>
              </ProtectRoute>
            }
          />

          <Route
            path="/onboarding-company"
            element={
              <ProtectRoute>
                <Sidebar>
                  <CompanyOnBoarding />
                </Sidebar>
              </ProtectRoute>
            }
          />

          <Route
            path="/onboarding-company/form"
            element={
              <ProtectRoute>
                <Sidebar>
                  <CompanyOnboardingForm />
                </Sidebar>
              </ProtectRoute>
            }
          />

          <Route path="/" element={<WelcomePage />} />

          <Route
            path="/onboarding-candidates"
            element={
              <ProtectRoute>
                <Sidebar>
                  <Candidates />
                </Sidebar>
              </ProtectRoute>
            }
          />

          <Route
            path="/master-locality"
            element={
              <ProtectRoute>
                <Sidebar>
                  <Locality />
                </Sidebar>
              </ProtectRoute>
            }
          />

          <Route
            path="/master-courses"
            element={
              <ProtectRoute>
                <Sidebar>
                  <LearningCourses />
                </Sidebar>
              </ProtectRoute>
            }
          />

          <Route
            path="/master-subcourses"
            element={
              <ProtectRoute>
                <Sidebar>
                  <SubLearningCourses />
                </Sidebar>
              </ProtectRoute>
            }
          />

          <Route
            path="masters-jobCategory"
            element={
              <ProtectRoute>
                <Sidebar>
                  <JobCategory />
                </Sidebar>
              </ProtectRoute>
            }
          />

          <Route
            path="masters-jobRank"
            element={
              <ProtectRoute>
                <Sidebar>
                  <JobRank />
                </Sidebar>
              </ProtectRoute>
            }
          />
          <Route
            path="master-subjects"
            element={
              <ProtectRoute>
                <Sidebar>
                  <CourseSubject />
                </Sidebar>
              </ProtectRoute>
            }
          />
          <Route
            path="master-shiptype"
            element={
              <ProtectRoute>
                <Sidebar>
                  <Shiptype />
                </Sidebar>
              </ProtectRoute>
            }
          />

          <Route
            path="master-certificates"
            element={
              <ProtectRoute>
                <Sidebar>
                  <Certificates />
                </Sidebar>
              </ProtectRoute>
            }
          />

          <Route
            path="masters-createCertificate"
            element={
              <ProtectRoute>
                <Sidebar>
                  <CreateCertificate />
                </Sidebar>
              </ProtectRoute>
            }
          />

          <Route
            path="master-otherQuestions"
            element={
              <ProtectRoute>
                <Sidebar>
                  <OtherQuestions />
                </Sidebar>
              </ProtectRoute>
            }
          />

          <Route
            path="master-uploadContent"
            element={
              <ProtectRoute>
                <Sidebar>
                  <UploadContent />
                </Sidebar>
              </ProtectRoute>
            }
          />

          <Route
            path="master-newsAnnouncement"
            element={
              <ProtectRoute>
                <Sidebar>
                  <NewsAndAnnouncement />
                </Sidebar>
              </ProtectRoute>
            }
          />

          <Route
            path="master-questions"
            element={
              <ProtectRoute>
                <Sidebar>
                  <AddQuestions />
                </Sidebar>
              </ProtectRoute>
            }
          />

          <Route
            path="master-ads"
            element={
              <ProtectRoute>
                <Sidebar>
                  <Advertisement />
                </Sidebar>
              </ProtectRoute>
            }
          />

          <Route
            path="master-course"
            element={
              <ProtectRoute>
                <Sidebar>
                  <CourseBatch />
                </Sidebar>
              </ProtectRoute>
            }
          />

          <Route
            path="master-jobpost"
            element={
              <ProtectRoute>
                <Sidebar>
                  <JobPost />
                </Sidebar>
              </ProtectRoute>
            }
          />

          <Route
            path="master-report"
            element={
              <ProtectRoute>
                <Sidebar>
                  <AdminReport />
                </Sidebar>
              </ProtectRoute>
            }
          />

          {/* Institute Routes */}

          <Route
            path="institute"
            element={
              <ProtectRoute>
                <Sidebar>
                  <InstituteDashboard />
                </Sidebar>
              </ProtectRoute>
            }
          />

          <Route
            path="institute-courses"
            element={
              <ProtectRoute>
                <Sidebar>
                  <AvailableCourses />
                </Sidebar>
              </ProtectRoute>
            }
          />

          <Route
            path="institute-details"
            element={
              <ProtectRoute>
                <Sidebar>
                  <InstituteDetails />
                </Sidebar>
              </ProtectRoute>
            }
          />

          <Route
            path="institute-course-schedule"
            element={
              <ProtectRoute>
                <Sidebar>
                  <ScheduleCourse />
                </Sidebar>
              </ProtectRoute>
            }
          />

          <Route
            path="institute-reports"
            element={
              <ProtectRoute>
                <Sidebar>
                  <Reports />
                </Sidebar>
              </ProtectRoute>
            }
          />

          <Route
            path="institute-courseRequest"
            element={
              <ProtectRoute>
                <Sidebar>
                  <CourseRequest />
                </Sidebar>
              </ProtectRoute>
            }
          />

          {/* Company Routes */}

          <Route
            path="company/dashboard"
            element={
              <ProtectRoute>
                <Sidebar>
                  <CompanyDashBoard />
                </Sidebar>
              </ProtectRoute>
            }
          />

          <Route
            path="company/job-management/jobpost"
            element={
              <ProtectRoute>
                <Sidebar>
                  <JobpostCompany />
                </Sidebar>
              </ProtectRoute>
            }
          />

          <Route
            path="company/details"
            element={
              <ProtectRoute>
                <Sidebar>
                  <CompanyDetails />
                </Sidebar>
              </ProtectRoute>
            }
          />

          <Route
            path="company/job-management/viewesume"
            element={
              <ProtectRoute>
                <Sidebar>
                  <CandidateResume />
                </Sidebar>
              </ProtectRoute>
            }
          />

          <Route
            path="master-department"
            element={
              <ProtectRoute>
                <Sidebar>
                  <CompanyDepartment />
                </Sidebar>
              </ProtectRoute>
            }
          ></Route>

          <Route
            path="company/otherinfo"
            element={
              <ProtectRoute>
                <Sidebar>
                  <CompanyOtherInfo />
                </Sidebar>
              </ProtectRoute>
            }
          />

          <Route
            path="company/reports"
            element={
              <ProtectRoute>
                <Sidebar>
                  <CompanyReport />
                </Sidebar>
              </ProtectRoute>
            }
          />

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default AppRoutes;
