import React, { useEffect, useState } from "react";
import { FaCaretDown, FaPen } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BallTriangle } from "react-loader-spinner";
import { getCountries, getSubjects } from "../../../../Apis/getApis";
import { saveSubject } from "../../../../Apis/postApis";
import { updateSubject } from "../../../../Apis/updateApis";
import { deleteUserSubject } from "../../../../Apis/deleteApis";
import Swal from "sweetalert2";
import {
  deleteToast,
  errorToast,
} from "../../../../utils/helperFunctions/HelperFunctions";

const CourseSubject = () => {
  const [country, setCountry] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [subject, setSubject] = useState("");
  const [subjectList, setSubjectList] = useState([]);
  const [subjectCode, setSubjectCode] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const [search, setSearch] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [updateDataId, setUpdateDataId] = useState();
  const [subjectSearch, setSubjectSearch] = useState("");
  const [subjectSearchData, setSubjectSearchData] = useState("");
  const handleCancel = () => {
    setIsEdit(false);
    setSubjectCode("");
    setSubject("");
    setSelectedCountries([]);
  };
  const deleteSubject = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete Subject ?",
      icon: "error",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete Subject!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUserSubject(id)
          .then((res) => {
            getAllsubjects();
            deleteToast("Subject is deleted.");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  useEffect(() => {
    setTimeout(() => {
      if (search !== "") {
        let arr = [];
        for (let i = 0; i < countryList.length; i++) {
          if (
            countryList[i].name.toLowerCase().includes(search.toLowerCase())
          ) {
            const isThere = (data) => {
              for (let j = 0; j < arr.length; j++) {
                if (arr[j].id === data.id) {
                  return true;
                }
              }
              return false;
            };
            if (!isThere(countryList[i])) {
              arr.push(countryList[i]);
            }
          }
        }
        setSearchData([...new Set(arr)]);
      } else {
        setSearchData([]);
      }
    }, 1000);
  }, [search]);

  useEffect(() => {
    setTimeout(() => {
      if (subjectSearch !== "") {
        let arr = [];
        for (let i = 0; i < subjectList.length; i++) {
          if (
            subjectList[i].name
              .toLowerCase()
              .includes(subjectSearch.toLowerCase())
          ) {
            const isThere = (data) => {
              for (let j = 0; j < arr.length; j++) {
                if (arr[j].id === data.id) {
                  return true;
                }
              }
              return false;
            };
            if (!isThere(subjectList[i])) {
              arr.push(subjectList[i]);
            }
          }
        }
        setSubjectSearchData([...new Set(arr)]);
      } else {
        setSubjectSearchData([]);
      }
    }, 1000);
  }, [subjectSearch]);

  const editSubject = (item) => {
    setIsEdit(true);
    setSelectedCountries(item.countries);
    setSubject(item.name);
    setSubjectCode(item.code);
    setUpdateDataId(item?.id);
  };

  const addCountry = (countryData) => {
    const isThere = selectedCountries.some((item) =>
      isEdit ? item.id == countryData.id : item == countryData.id
    );
    if (!isThere) {
      if (isEdit) {
        setSelectedCountries([...selectedCountries, countryData.id]);
      } else {
        setSelectedCountries([...selectedCountries, countryData.id]);
      }
    }
  };

  const findById = (id) => {
    for (let i = 0; i < countryList.length; i++) {
      if (countryList[i].id === id) {
        return countryList[i];
      }
    }
  };

  const removeCountry = (data) => {
    const countries = [...selectedCountries];
    const newCountry = countries.filter((item) => item !== data);
    setSelectedCountries(newCountry);
  };

  const submitSubject = () => {
    if (subject == "") {
      errorToast("Please give Subject");
      return;
    }
    if (subjectCode == "") {
      errorToast("Please give Subject Code");
      return;
    }
    if (!isEdit) {
      saveSubject({
        name: subject,
        code: subjectCode,
        countries: selectedCountries,
      })
        .then((res) => {
          handleCancel();
          getAllsubjects();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      updateSubject(updateDataId, {
        name: subject,
        code: subjectCode,
        countries: selectedCountries,
      })
        .then((res) => {
          handleCancel();
          getAllsubjects();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  function getAllsubjects() {
    getSubjects()
      .then((res) => {
        console.log(res, "pavan");
        setSubjectList(res?.data?.data?.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getCountries()
      .then((res) => {
        console.log(res, "india");
        setCountryList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });

    getAllsubjects();
  }, []);

  return (
    <div className="institute-container animate__animated animate__fadeIn">
      <div className="instituteOnboarding-list-view">
        <div className="headerPart">
          <h4>Add Subject</h4>
        </div>
      </div>
      <div className="table-container position-relative">
        <span
          className="mustStar"
          style={{
            textAlign: "end",
            fontSize: "15px",
            position: "absolute",
            top: "-20px",
            left: "-16px",
          }}
        >
          Fields marked (*) are mandatory.
        </span>
        <div className="allDropDown allField-learning-courses">
          <span
            className="d-flex add-course-container add-course-country-container justify-content-center"
            style={{ maxWidth: "100%", padding: "20px" }}
          >
            <div className="dropdown dropDown-locality">
              <button
                type="button"
                className={
                  width < 500
                    ? "btn drdpbtn sailors-Input-field col"
                    : "btn drdpbtn sailors-Input-field row"
                }
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ backgroundColor: "white", maxWidth: "200px" }}
              >
                Country
                <FaCaretDown style={{ width: "15px" }} />
              </button>
              <div
                className="dropdown-menu"
                style={{
                  boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)",
                  width: "300px",
                  height: "200px",
                  overflowY: "scroll",
                }}
              >
                <div
                  className="searchBar-Container"
                  style={{ maxHeight: "50px" }}
                >
                  <div
                    className="input-group position-sticky"
                    style={{ marginBottom: "-100px", paddingLeft: "10px" }}
                  >
                    <input
                      type="text"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      className="form-control position-static"
                      placeholder="Search ......"
                      aria-label="Recipient's username"
                    />
                  </div>
                </div>
                {search === "" && (
                  <React.Fragment>
                    {countryList.length > 0 ? (
                      countryList?.map((item, index) => {
                        return (
                          <div
                            className="dropdown-item"
                            style={{ height: "50px", cursor: "pointer" }}
                            onClick={() => addCountry(item)}
                          >
                            {item?.name}
                          </div>
                        );
                      })
                    ) : (
                      <center style={{ marginTop: "10%" }}>
                        !Opps check the Internet
                      </center>
                    )}
                  </React.Fragment>
                )}

                {search !== "" && (
                  <>
                    {searchData.length > 0 ? (
                      searchData.reverse()?.map((item, index) => {
                        return (
                          <div
                            className="dropdown-item"
                            style={{ height: "50px", cursor: "pointer" }}
                            onClick={() => addCountry(item)}
                          >
                            {item.name}
                          </div>
                        );
                      })
                    ) : (
                      <center style={{ marginTop: "10%" }}>No Match</center>
                    )}
                  </>
                )}
              </div>
            </div>
            <div
              className={
                selectedCountries.length > 0
                  ? "selectedCountries-container"
                  : "selectedCountries-container noDataContainer"
              }
            >
              {selectedCountries.length > 0 ? (
                <>
                  {selectedCountries.map((item) => {
                    return (
                      <div className="selectedCountry">
                        <p style={{ marginTop: "37px" }}>
                          {findById(item)?.name}
                        </p>
                        <button
                          onClick={() => removeCountry(item)}
                          type="button"
                          className="close"
                          style={{ width: "15px", height: "15px" }}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    );
                  })}
                </>
              ) : (
                <center style={{ marginTop: "15px" }}>All Countries</center>
              )}
            </div>
          </span>

          <span
            className="d-flex add-course-container justify-content-center"
            style={{ maxWidth: "100%", padding: "10px" }}
          >
            <p className="d-flex text-base" style={{ maxWidth: "231px" }}>
              Subject Name <p style={{ color: "red", maxWidth: "3px" }}>*</p>
            </p>

            <input
              type="text"
              value={subject}
              onChange={(e) => {
                setSubject(e.target.value);
              }}
              className="sailors-Input-field"
              style={{ maxWidth: "1024px" }}
            />
          </span>

          <span
            className="d-flex add-course-container justify-content-center"
            style={{ maxWidth: "100%", padding: "10px" }}
          >
            <p className="d-flex" style={{ maxWidth: "231px" }}>
              Subject Code <p style={{ color: "red", maxWidth: "3px" }}>*</p>
            </p>

            <input
              type="text"
              value={subjectCode}
              onChange={(e) => {
                setSubjectCode(e.target.value);
              }}
              className=" sailors-Input-field"
              style={{ maxWidth: "1024px" }}
            />
          </span>
        </div>
        <div className="form-btns" style={{ marginBottom: "10px" }}>
          <button type="submit" onClick={submitSubject} className="btn">
            {isEdit ? "Update" : "Save"}
          </button>
          <button onClick={handleCancel} className="btn btn-OnboardForm">
            Cancel
          </button>
        </div>
      </div>
      <div
        className="instituteOnboarding-list-view"
        style={{ marginTop: "40px", marginBottom: "20px" }}
      >
        <div className="headerPart">
          <h4>Subjects List</h4>
        </div>
      </div>
      <div
        className="input-group position-sticky"
        style={{ marginBottom: "20px", paddingLeft: "10px" }}
      >
        <input
          type="text"
          value={subjectSearch}
          onChange={(e) => setSubjectSearch(e.target.value)}
          className="form-control position-static"
          placeholder="Search subject ......🔍"
          aria-label="Recipient's username"
        />
      </div>
      {loading ? (
        <div style={{ maxWidth: "100px", maxHeight: "100px" }}>
          <BallTriangle
            height={20}
            width={20}
            radius={5}
            color="red"
            ariaLabel="ball-triangle-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
          <span>Loading...</span>
        </div>
      ) : (
        <div className="table-container">
          <div className="table-Data">
            <table className="table">
              <thead>
                <tr
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    borderBottom: "1px solid #2A737A66",
                  }}
                >
                  <th style={{ fontWeight: "700", maxWidth: "50px" }}>
                    Sr No.
                  </th>
                  <th style={{ fontWeight: "700", textAlign: "start" }}>
                    Subject Name
                  </th>
                  <th style={{ fontWeight: "700", textAlign: "start" }}>
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {subjectSearch === "" && (
                  <React.Fragment>
                    {subjectList.length > 0 ? (
                      subjectList.map((item, index) => {
                        return (
                          <tr
                            className="px-6"
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              borderBottom: "1px solid #2A737A66",
                            }}
                          >
                            <th scope="row">{index + 1}</th>
                            <td
                              style={{ textWrap: "wrap", textAlign: "start" }}
                            >
                              {item.name}
                            </td>

                            <td>
                              <div
                                style={{
                                  cursor: "pointer",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "start",
                                }}
                              >
                                <FaPen
                                  onClick={() => editSubject(item)}
                                  title="Details"
                                  style={{
                                    width: "40px",
                                    height: "30px",
                                    color: "#0CB1C0",
                                    border: "1px solid gray",
                                    padding: "5px",
                                    borderTopLeftRadius: "10px",
                                    borderBottomLeftRadius: "10px",
                                  }}
                                />
                                <RiDeleteBin6Line
                                  onClick={() => deleteSubject(item.id)}
                                  title="Delete"
                                  style={{
                                    width: "40px",
                                    height: "30px",
                                    color: "#F84F31",
                                    border: "1px solid gray",
                                    padding: "5px",
                                    borderTopRightRadius: "10px",
                                    borderBottomRightRadius: "10px",
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <center>No data found</center>
                    )}
                  </React.Fragment>
                )}
                {subjectSearch !== "" && (
                  <React.Fragment>
                    {subjectSearchData.length > 0 ? (
                      subjectSearchData.map((item, index) => {
                        return (
                          <tr
                            className=""
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <th scope="row">{index + 1}</th>
                            <td
                              style={{ textWrap: "wrap", textAlign: "center" }}
                            >
                              {item.name}
                            </td>

                            <td>
                              <div
                                style={{
                                  cursor: "pointer",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                }}
                              >
                                <FaPen
                                  onClick={() => editSubject(item)}
                                  title="Details"
                                  style={{
                                    width: "40px",
                                    height: "30px",
                                    color: "#0CB1C0",
                                    border: "1px solid gray",
                                    padding: "5px",
                                    borderTopLeftRadius: "10px",
                                    borderBottomLeftRadius: "10px",
                                  }}
                                />
                                <RiDeleteBin6Line
                                  onClick={() => deleteSubject(item.id)}
                                  title="Delete"
                                  style={{
                                    width: "40px",
                                    height: "30px",
                                    color: "#F84F31",
                                    border: "1px solid gray",
                                    padding: "5px",
                                    borderTopRightRadius: "10px",
                                    borderBottomRightRadius: "10px",
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <center>No data found</center>
                    )}
                  </React.Fragment>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default CourseSubject;
