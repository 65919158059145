// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subCheckDiv{
    display: flex;
    flex-direction: row;
    width: 70px;
    height: 100%;
    align-items: center;
    /* border: 1px solid #005A80; */
    border-radius: 10px;
    margin: 0 10px 0 10px;
    text-align: center;
}
.subCheckDivMain{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.subCheckDiv label{
    align-items: center;
    padding: 2px;
    margin-top: 8px;
}
.feeCheckDiv{
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    border-radius: 10px;
    margin: 0 10px 0 10px;
    text-align: center;
}
.feeCheckDiv label{
    padding: 2px;
    margin-top: 8px;
    margin-left: 10px;
}
.feeCheckDiv input{
    width: 20px;
    height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/Admin/pages/courseBatch/courseBatch.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,+BAA+B;IAC/B,mBAAmB;IACnB,qBAAqB;IACrB,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;AACA;IACI,mBAAmB;IACnB,YAAY;IACZ,eAAe;AACnB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,mBAAmB;IACnB,mBAAmB;IACnB,qBAAqB;IACrB,kBAAkB;AACtB;AACA;IACI,YAAY;IACZ,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".subCheckDiv{\n    display: flex;\n    flex-direction: row;\n    width: 70px;\n    height: 100%;\n    align-items: center;\n    /* border: 1px solid #005A80; */\n    border-radius: 10px;\n    margin: 0 10px 0 10px;\n    text-align: center;\n}\n.subCheckDivMain{\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n}\n.subCheckDiv label{\n    align-items: center;\n    padding: 2px;\n    margin-top: 8px;\n}\n.feeCheckDiv{\n    display: flex;\n    flex-direction: row;\n    height: 100%;\n    align-items: center;\n    border-radius: 10px;\n    margin: 0 10px 0 10px;\n    text-align: center;\n}\n.feeCheckDiv label{\n    padding: 2px;\n    margin-top: 8px;\n    margin-left: 10px;\n}\n.feeCheckDiv input{\n    width: 20px;\n    height: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
