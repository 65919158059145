import React from "react";
import { Doughnut } from "react-chartjs-2";
import "bootstrap/dist/css/bootstrap.min.css";
import "./sailorchart.css"; // Custom styles if needed
import "chart.js/auto";

const InstituteChartAdmin = React.memo(({ dashboardsAllData }) => {
  const {
    total_institutes_count,
    verified_institutes_count,
    unverified_institutes_count,
  } = dashboardsAllData;
  const data = {
    labels: ["Verified Institutes", "Unverified Institutes"],
    datasets: [
      {
        data: [verified_institutes_count, 80],
        backgroundColor: ["#3FBDC9", "#AC6700"],
        hoverBackgroundColor: ["#50b2bb", "#FFD54F"],
        borderWidth: 2,
      },
    ],
  };

  const options = {
    cutout: "78%", // This will make it a doughnut chart
    plugins: {
      legend: {
        display: false, // Disable legend to match your design
      },
    },
  };

  return (
    <div className="w-full h-100">
      <div style={{ borderTop: "5px solid #5C95AD", borderRadius: "10px" }}>
        <div
          className="card p-3 w-100 h-100"
          style={{
            borderTop: "none",
            boxShadow: "-1px 3px 20px 0px #0000001A",
          }}
        >
          <div className="d-flex justify-content-center mb-3">
            <h5 className="admin-report-card-title">Total Institutes</h5>
          </div>
          <div className="doughnut-chart-container d-flex justify-content-center m-auto">
            <Doughnut data={data} options={options} />
            <div className="chart-overlay d-flex align-items-center justify-content-center">
              <img
                src="assets/commonAssets/InstituteCardAdmin.png"
                alt="captain"
                className=""
              ></img>{" "}
              {/* You can use a relevant icon */}
            </div>
          </div>
          <ul className="list-group list-group-flush text-start ps-5 mb-5">
            <li className="list-group-item">
              Total Institutes:{" "}
              <span className="value">{total_institutes_count}</span>
            </li>
            <li className="list-group-item">
              <span className="dot aqua"></span> Verified Institutes:{" "}
              <span className="value">{verified_institutes_count}</span>
            </li>
            <li className="list-group-item">
              <span className="dot yellow"></span> Unverified Sailors:{" "}
              <span className="value text-end">
                {unverified_institutes_count}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
});

export default InstituteChartAdmin;
