import React from "react";
import { Doughnut } from "react-chartjs-2";
import "bootstrap/dist/css/bootstrap.min.css";
import "./sailorchart.css"; // Custom styles if needed
import "chart.js/auto";

const CompanyChartAdmin = React.memo(({ dashboardsAllData }) => {
  const {
    total_companies_count,
    verified_companies_count,
    unverified_companies_count,
  } = dashboardsAllData;
  const data = {
    labels: ["Verified Companies", "Unverified Companies"],
    datasets: [
      {
        data: [verified_companies_count, unverified_companies_count],
        backgroundColor: ["#2A737A", "#FFDA9F"],
        hoverBackgroundColor: ["#77bec4", "#d0b487"],
        borderWidth: 2,
      },
    ],
  };

  const options = {
    cutout: "78%", // This will make it a doughnut chart
    plugins: {
      legend: {
        display: false, // Disable legend to match your design
      },
    },
  };

  return (
    <div className="w-100 h-100">
      <div style={{ borderTop: "5px solid #5C95AD", borderRadius: "10px" }}>
        <div
          className="card p-3 w-100 h-100"
          style={{
            borderTop: "none",
            boxShadow: "-1px 3px 20px 0px #0000001A",
          }}
        >
          <div className="d-flex justify-content-center mb-3">
            <h5 className="admin-report-card-title">Company</h5>
          </div>
          <div className="doughnut-chart-container d-flex justify-content-center m-auto">
            <Doughnut data={data} options={options} />
            <div className="chart-overlay d-flex align-items-center justify-content-center">
              <img
                src="assets/commonAssets/companyAdminCard.png"
                alt="captain"
                className=""
              ></img>{" "}
              {/* You can use a relevant icon */}
            </div>
          </div>
          <ul className="list-group list-group-flush text-start ps-5 mb-5">
            <li className="list-group-item">
              Total Institutes:{" "}
              <span className="value">{total_companies_count}</span>
            </li>
            <li className="list-group-item">
              <span className="dot dark-aqua"></span> Verified Companies:
              <span className="value">{verified_companies_count}</span>
            </li>
            <li className="list-group-item">
              <span className="dot lite-yellow"></span> Unverified Companies:{" "}
              <span className="value">{unverified_companies_count}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
});

export default CompanyChartAdmin;
