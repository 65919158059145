import React from 'react'

const SpecialReport = ({studentList}) => {
  return (
    <div>
      <div className='table-container' >
          
          {studentList?.length > 0 ?
            <div className='table-Data' id="divToPrint" style={{display:'flex',justifyContent:'center',flexDirection:"column"}}>
              {/* <img src="/assets/authAssets/Sailors-Hub-logo.gif" alt="SailorsHub" className='navBarLogo' style={{margin:"auto",marginTop:"15px",marginBottom:"10px"}}/> */}
              <center style={{fontSize:"18px"}}>Report</center>
              <table className='table'>
                <thead>
                  <tr style={{ display: 'flex', justifyContent: "center",paddingBottom:"20px" }}>
                    <th scope="col" style={{maxWidth:'70px',textAlign:'start'}}>Sr No.</th>
                    <th scope="col" style={{width:'calc(100%/5)',maxWidth:"300px",textAlign:'start'}}>Candidate Name</th>
                    <th scope="col" style={{width:'calc(100%/5)',maxWidth:"300px",textAlign:'start'}}>Course Name</th>
                    <th scope="col" style={{width:'calc(100%/5)',maxWidth:"300px",textAlign:'start'}}>Contact</th>
                    <th scope="col" style={{width:'calc(100%/5)',maxWidth:"300px",textAlign:'start'}}>Course Status</th>
                  </tr>
                </thead>
                <tbody>
                  {studentList.map((item, index) => {
                    return (
                      <tr className='' style={{ display: 'flex', justifyContent: "center",paddingBottom:"20px" }}>
                        <th style={{maxWidth:'70px',textAlign:'start'}} scope="row">{index + 1}.</th>
                        <td style={{width:'calc(100%/5)',maxWidth:"100%", textAlign:'start'}}>{item.candidate_name}</td>
                        <td style={{width:'calc(100%/5)',maxWidth:"100%", textAlign:'start'}}>{item.course_name}</td>
                        <td style={{width:'calc(100%/5)',maxWidth:"100%", textAlign:'start'}}>{item.mobile_number1}</td>
                        <td style={{width:'calc(100%/5)',maxWidth:"100%", textAlign:'start'}}>{item.status}</td>
                        
                      </tr>
                    )
                  })}
                </tbody>


              </table>
            </div>
            :
            <center>No Data found</center>}
        </div>
    </div>
  )
}

export default SpecialReport;
