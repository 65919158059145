import React, { useEffect, useState } from 'react'
import { Recruitmentfunnel } from '../../../Apis/getApis'

const FunnelChart = React.memo(() => {
    const [data,setData] = useState({
        unviewed:20,
        viewed:10,
        shortlisted:5,
        offerd:5,
        joined:3
    });
    const [totalCount,setTotalCount] = useState(Object.values(data).reduce((a,c)=>a+c));
    

    useEffect(()=>{
        Recruitmentfunnel()
        .then((res)=>{
            console.log(res?.data?.data?.recruitment_funnel,"funnelData")
            let totalData = res?.data?.data?.recruitment_funnel.reduce((sum,curr)=>sum+curr?.count);
            
            setTotalCount(totalData);

        })
    },[])

    return (
        <div className="mt-2" style={{color:"red"}}>
            <div className=" text-white text-center p-2 m-auto" style={{  maxHeight:"10%",backgroundColor: " #56CDFF", maxWidth: `${(data['unviewed']/totalCount)*100}%`}}>Unviewed - {data['unviewed']}</div>
            <div className=" text-white text-center p-2 mt-2 m-auto " style={{ maxHeight:"10%", backgroundColor: "#56CDFF",  maxWidth: `${(data['viewed']/totalCount)*100}%` }}>Viewed - {data['viewed']}</div>
            <div className=" text-white text-center p-2 mt-2 m-auto " style={{ maxHeight:"10%", backgroundColor: "#56CDFF" , maxWidth: `${(data['shortlisted']/totalCount)*100}%`}}>Shortlisted - {data['shortlisted']}</div>
            <div className=" text-white text-center p-2 mt-2 m-auto " style={{ maxHeight:"10%", backgroundColor: "#56CDFF" , maxWidth: `${(data['offerd']/totalCount)*100}%`}}>Offered - {data['offerd']}</div>
            <div className=" text-white text-center p-2 mt-2 m-auto " style={{ maxHeight:"10%", backgroundColor: "#56CDFF" , maxWidth: `${(data['joined']/totalCount)*100}%`}}>Joined - {data['joined']}</div>
        </div>
    )
})

export default FunnelChart;
