import React, { useEffect, useRef, useState } from 'react';
import { getAllJobsByCompany, getCompanyDashBoardData, loadMoreData, Recruitmentfunnel } from '../../../Apis/getApis';
import CompanyChart from '../companyChart/CompanyChart';
import ChartComponent from './chartComponent';
import DataCards from '../../components/DashboardDataCards/DataCards';
import HorizontalBarChart from '../companyChart/barcharts/horizontalBarChart';
import AgeBar from '../companyChart/barcharts/ageBar';
import FunnelChart from '../../components/funnelBar/funnelChart';
import { errorToast } from '../../../utils/helperFunctions/HelperFunctions';
import { base_url } from '../../../utils/endpoints/endpoints';

const CompanyDashBoard = () => {

    const [lableData, setLabelData] = useState(["Deck Officer", "Engine Head", "Chief Officer"]);
    const [userData, setUserData] = useState([10, 25, 10]);
    const [desRange, setDesRange] = useState('');
    const [funRange, setFunRange] = useState('')
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [funnFromDate, setFunnFromDate] = useState('');
    const [funnToDate, setFunnToDate] = useState('');
    const instituteOnboardingRef = useRef();

    const [ageGroupData, setAgeGroupData] = useState([]);
    const [employeeByGender, setEmployeeByGender] = useState();
    const [employeeByDepartment, setEmployeeByDepartment] = useState([
        { department: 'deck', count: 0 },
        { department: 'engine', count: 0 },
        { department: 'catering', count: 0 }
    ])
    const [empByDes, setEmpByDes] = useState([]);
    const [offerAcceptance, setOfferAcceptance] = useState(0);
    const [kpi, setKpi] = useState(0);
    const [selectedDesignations, setSelectedDesignations] = useState([]);
    const [profileVisitors, setProfileVisitors] = useState({
        today_viewed_visitors: 0,
        total_viewed_visitors: 0,
        yesterday_viewed_visitors: 0
    });
    const [jobs, setJobs] = useState([]);
    const [selectedJob, setSelectedJob] = useState('');



    const addRemoveFromSelected = (target) => {

        if (selectedDesignations.includes(target)) {
            let newarr = [...selectedDesignations];
            newarr = newarr.filter((item) => item !== target);
            setSelectedDesignations(newarr);
        } else {
            if (selectedDesignations.length >= 6) {
                errorToast('Max can select 6 Designations');
                return;
            } else {
                setSelectedDesignations([...selectedDesignations, target])
            }
        }
    }


    async function jobsData() {

        async function loadData(resNext) {
            if (resNext == null) {
                return;
            }
            await loadMoreData(resNext)
                .then((res) => {
                    setJobs(prev => [...prev, ...res?.data?.data?.results]);
                    loadData(res?.data?.data?.next)
                })
                .cath((err) => {
                    throw err;
                })
        }

        await getAllJobsByCompany()
            .then((res) => {
                console.log(res);
                setJobs(res?.data?.data?.results);
                loadData(res?.data?.data?.next)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        jobsData()
    }, [])

    useEffect(() => {
        if (fromDate !== "" && toDate !== "") {
            if (fromDate > toDate) {
                errorToast("From Date Should be smaller than To Date");
                setFromDate('');
                setToDate('')
            } else {
                setDesRange('')
                let url = `${base_url}/api/v1/web/users/company_dashboard/?start_date=${fromDate}&end_date=${toDate}`
                loadMoreData(url)
                    .then((res) => {
                        console.log(res, "newLoaddata")
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        }

    }, [fromDate, toDate])

    useEffect(() => {
        if (desRange !== "") {

            setFromDate('')
            setToDate('');

            let url = `${base_url}/api/v1/web/users/company_dashboard/?range=${desRange}`
            loadMoreData(url)
                .then((res) => {
                    console.log(res, "newLoaddata")
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [desRange])

    useEffect(() => {
        getCompanyDashBoardData()
            .then((res) => {
                console.log(res, "dashboardData")
                setAgeGroupData(res?.data?.data?.age_groups);
                setEmployeeByGender(res?.data?.data?.employees_count);
                setOfferAcceptance(res?.data?.data?.offer_acceptance_rate);
                setKpi(res?.data?.data?.key_performance_indicator);
                setProfileVisitors(res?.data?.data?.viewed_visitors_data)
                setEmployeeByDepartment(res?.data?.data?.joined_employee_department);
                setEmpByDes(res?.data?.data?.joined_employee_designation);
                let newarr = [];
                for (let i = 0; i < 6; i++) {
                    newarr.push(res?.data?.data?.joined_employee_designation[i].job_role)
                }
                setSelectedDesignations(newarr)
            })
            .catch((err) => {
                console.log(err)
            });


        Recruitmentfunnel()
            .then((res) => {
                console.log(res, "recruit")
            })
            .catch((err) => {
                console.log(err)
            })

    }, [])

    return (
        <div className='dashBoard-container' >
            <div className='instituteOnboarding-list-view' tabIndex={-1} ref={instituteOnboardingRef} >
                <div className='headerPart'>
                    <h4>DashBoard</h4>
                </div>

                <div className='dashboard-data-card InstituteMainContainer' style={{ height: "auto", display: 'flex', border: "none", background: '#F7FBFE', justifyContent: 'start', flexWrap: 'wrap', flexDirection: 'column', minHeight: "800px", borderRadius: "30px", width: "100%", borderTopColor: 'ActiveBorder' }}>
                    <div>
                        <DataCards
                            offerAcceptance={offerAcceptance}
                            kpi={kpi}
                            profileVisitors={profileVisitors}
                        />
                    </div>

                    <div className='carousal-container company-dashboard-middle-container' style={{ marginTop: "20px", background: "#F7FBFE", width: "100%", height: 'auto' }}>
                            <div className='h-100' style={{  borderRadius: "20px", borderTop: "4px solid #63c3ec", boxShadow: "-1px 3px 20px 0px #0000001A" }}>
                                <p className='ms-4' style={{ fontWeight: "500", fontSize: "18px", color: "#337B99",maxHeight:"10px" }}>Employees By Department</p>
                                <ChartComponent
                                    employeeByDepartment={employeeByDepartment}
                                />
                            </div>

                            <div className='h-100' style={{ borderRadius: "20px", borderTop: "4px solid #63c3ec", boxShadow: "-1px 3px 20px 0px #0000001A" }}>
                                <div className='d-flex flex-row flex-wrap h-25'>
                                    <p className='ms-4' style={{ fontWeight: "500", fontSize: "18px", color: "#337B99",maxHeight:"20px" }}>Employee Designation</p>
                                    <input type='text' onFocus={(e) => e.target.type = 'date'} onBlur={(e) => {
                                        if (fromDate == "") {
                                            e.target.type = 'text'
                                        }
                                    }} value={fromDate} onChange={(e) => setFromDate(e.target.value)} className='w-50 h-25 dateBox' placeholder='From Date' />
                                    <input type='text' onFocus={(e) => e.target.type = 'date'} onBlur={(e) => {
                                        if (toDate == "") {
                                            e.target.type = 'text'
                                        }
                                    }} value={toDate} onChange={(e) => setToDate(e.target.value)} className='w-50 h-25 dateBox' placeholder='TO Date' />
                                
                                <select value="" className='m-1 dateBox h-25' style={{ maxWidth: "15em", color: '#005A80' }} onChange={(e) => { addRemoveFromSelected(e.target.value) }}>
                                        <option >Select Designations</option>
                                        {empByDes.length > 0 && empByDes.map((item) => {
                                            return (
                                                <option value={item?.job_role} onClick={() => addRemoveFromSelected(item?.job_role)}>{item?.job_role}  {selectedDesignations.includes(item?.job_role) && '✔️'}</option>
                                            )
                                        })}

                                    </select>
                                    <select value={desRange} onChange={(e) => setDesRange(e.target.value)} className='m-1 dateBox h-25' style={{ maxWidth: "10em", color: '#005A80' }}>
                                        <option>Select Range</option>
                                        <option value="one_week">1W</option>
                                        <option value="one_month">1M</option>
                                        <option value="three_month">3M</option>
                                        <option value="six_month">6M</option>
                                        <option value="one_year">1Y</option>
                                        <option value="three_year">3Y</option>
                                        <option value="five_year">5Y</option>
                                    </select>
                                </div>
                                <div className='mt-5'>
                                    <HorizontalBarChart selectedDesignations={selectedDesignations} empByDes={empByDes} />
                                </div>
                            </div>

                            <div className='h-100' style={{  borderRadius: "20px", borderTop: "4px solid #63c3ec", background: "#FFFFFF", boxShadow: "-1px 3px 20px 0px #0000001A" }}>
                                <p className='ms-4 mb-3' style={{ fontWeight: "500", fontSize: "18px", color: "#337B99",maxHeight:"35px"}}>Employees By Department</p>
                                <div className='h-25 d-flex flex-column'>
                                    <div className='w-10 text-center mb-4 m-auto d-flex flex-column justify-content-center align-items-center'>
                                        <img src='/assets/adminAssets/dashboardAssets/female.png' className='w-25' alt='female' />
                                        <p className='w-100'>Female Employees - {employeeByGender?.female_employees}</p>
                                        <hr className='w-50 h-25 m-auto' />
                                    </div>
                                    <div className='w-10 text-center m-auto d-flex mt-4 flex-column justify-content-center align-items-center'>
                                        <img src='/assets/adminAssets/dashboardAssets/male.png' className='w-25' alt='female' />
                                        <p className='w-100'>Male Employees - {employeeByGender?.male_employees}</p>
                                        <hr className='w-75 h-25 m-auto' />
                                    </div>
                                    <div className='w-10 text-center m-auto d-flex mt-4 flex-column justify-content-center align-items-center' style={{maxHeight:"80px"}}>
                                        <p className='w-100 h5'>Total Joined Employees</p>
                                        <p className='w-100 h5'>{employeeByGender?.total_joined_employees}</p>
                                    </div>
                                </div>
                            </div>
                    </div>

                    <div className=' w-100 company-dashboard-last-row mt-4 h-100' style={{ background: "#fff" }}>
                        <div className='h-100' style={{ borderRadius: "20px", borderTop: "4px solid #63c3ec",backgroundColor:"#fff"}}>
                            <p className='ms-4' style={{ fontWeight: "500", fontSize: "18px", color: "#337B99",maxHeight:"20px" }}>Department Wise Attrition</p>
                            <CompanyChart userData={userData} lableData={lableData} />
                        </div>

                        <div className='h-100 mt-2' style={{  borderRadius: "20px", borderTop: "4px solid #63c3ec", background: "#FFFFFF", boxShadow: "-1px 3px 20px 0px #0000001A" }}>
                            <p className='ms-4' style={{ fontWeight: "500", fontSize: "18px", color: "#337B99", maxHeight:"20px" }}>Attrition By Age Group</p>
                            <AgeBar ageGroupData={ageGroupData} />
                        </div>

                        <div className='h-100' style={{ borderRadius: "20px", borderTop: "4px solid #63c3ec", background: "#FFFFFF", boxShadow: "-1px 3px 20px 0px #0000001A" }}>
                            <div className='d-flex flex-row h-25' style={{maxHeight:"50px"}}>
                                <p className='ms-4 h-25' style={{ fontWeight: "500", fontSize: "18px", color: "#337B99" }}>Recruitment Funnel</p>
                                <input type='text' onFocus={(e) => e.target.type = 'date'} onBlur={(e) => {
                                    if (funnFromDate == "") {
                                        e.target.type = 'text'
                                    }
                                }} value={funnFromDate} onChange={(e) => setFunnFromDate(e.target.value)} className='w-50 dateBox h-50' placeholder='From Date' />
                                <input type='text' onFocus={(e) => e.target.type = 'date'} onBlur={(e) => {
                                    if (funnToDate == "") {
                                        e.target.type = 'text'
                                    }
                                }} value={funnToDate} onChange={(e) => setFunnToDate(e.target.value)} className='w-50 dateBox h-50' placeholder='To Date' />
                            </div>
                            <div className='d-flex flex-row justify-content-between mb-0 h-25' style={{maxHeight:"50px"}}>
                                <select className='m-1 dateBox h-25' value={selectedJob} onChange={(e) => setSelectedJob(e.target.value)} style={{ maxWidth: "15em", color: '#005A80' }}>
                                    <option value=''>Select Job</option>
                                    {jobs.length > 0 && jobs.map((item) => {
                                        return (
                                            <option value={item?.id} >{item?.job_title}</option>
                                        )
                                    })}

                                </select>
                                <select className='m-1 dateBox h-25' value={funRange} onChange={(e) => { setFunRange(e.target.value) }} style={{ maxWidth: "10em", color: '#005A80',maxHeight:"50px" }}>
                                    <option>Select Range</option>
                                    <option value="one_week">1W</option>
                                    <option value="one_month">1M</option>
                                    <option value="three_month">3M</option>
                                    <option value="six_month">6M</option>
                                    <option value="one_year">1Y</option>
                                    <option value="three_year">3Y</option>
                                    <option value="five_year">5Y</option>
                                </select>
                            </div>
                            <div className='h-auto'>
                                <FunnelChart />
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default CompanyDashBoard;
