// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-type-box{
    width: 72px;
    height: 35px;
    border: 2px solid #767676;
    padding: 5px;
    margin: 5px;
    border-radius: 5px;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/Admin/pages/masters/Upload_content/contentUpload.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,kBAAkB;AACtB","sourcesContent":[".file-type-box{\n    width: 72px;\n    height: 35px;\n    border: 2px solid #767676;\n    padding: 5px;\n    margin: 5px;\n    border-radius: 5px;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
