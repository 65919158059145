import React, { useState, useEffect } from "react";
import { FaCaretDown, FaPen } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BallTriangle } from "react-loader-spinner";
import { getEnrollData } from "../../../../Apis/getApis";
import AdminCompanyReport from "./reportEntities/AdminCompanyReport";
import AdminInstituteReport from "./reportEntities/AdminInstituteReport";
const AdminReport = () => {
  const [name, setName] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [studentList, setStudentList] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [status, setStatus] = useState("");
  const [instituteCount, setInstituteCount] = useState(0);
  const [companyCount, setCompanyCount] = useState(0);
  const [entity, setEntity] = useState("institute");
  const ManualSearch = () => {
    getStudentData();
  };

  function updateCount(data, entity) {
    if (entity == "company") {
      setCompanyCount(data);
    } else {
      setInstituteCount(data);
    }
  }

  function getStudentData() {
    getEnrollData(name, status, fromDate, toDate)
      .then((res) => {
        setStudentList(res?.data?.data?.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getStudentData();
  }, []);

  return (
    <div className="institute-container animate__animated animate__fadeIn container">
      <div className="instituteOnboarding-list-view">
        <div className="headerPart">
          <h4 className="w-25">Reports</h4>
          <div></div>
          <div
            className="input-group positon-sticky d-flex flex-row justify-content-around w-100"
            style={{
              boxShadow: "-2px 2px 9px 0px #00000033",
              minWidth: "346px",
              maxWidth: "346px",
              minHeight: "48px",
            }}
          >
            <button
              className={
                entity === "institute"
                  ? "candidate-sort-button candidate-sort-button-active w-auto m-2 p-1"
                  : "m-2 p-1 w-auto candidate-sort-button border-0"
              }
              onClick={() => setEntity("institute")}
              style={{
                cursor: "pointer",
                margin: "3px",
                border: "1px solid #005A80",
                borderRadius: "5px",
                color: entity == "institute" ? "white" : "#005A80",
                background: entity == "institute" ? "#005A80" : "white",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "600",
                textAlign: "left",
              }}
            >
              Institute Report
            </button>
            <button
              className={
                entity === "company"
                  ? "candidate-sort-button candidate-sort-button-active w-auto m-2 p-1"
                  : "m-2 p-1 w-auto candidate-sort-button border-0"
              }
              onClick={() => setEntity("company")}
              style={{
                cursor: "pointer",
                margin: "3px",
                border: "1px solid #005A80",
                borderRadius: "5px",
                color: entity == "company" ? "white" : "#005A80",
                background: entity == "company" ? "#005A80" : "white",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "600",
                textAlign: "left",
              }}
            >
              Company Report
            </button>
          </div>
        </div>
        {entity == "company" && (
          <div>
            <AdminCompanyReport updateCount={updateCount} />
          </div>
        )}

        {entity == "institute" && (
          <div>
            <AdminInstituteReport />
          </div>
        )}
      </div>

      {/* <div className='table-container'>
        <div className='allDropDown allField-learning-courses'>

          <span className='d-flex add-course-container justify-content-center' style={{ maxWidth: "100%", padding: "10px" }}>
          <div className={width < 500 ? " col" : " row"} style={{ width: "100%" }}>
                    <div className={width < 500 ? 'form-group selectedField-row row' : 'form-group selectedField col'} style={{ paddingTop: "7px" }}>                        
                        <button type="button" className={width < 500 ? "btn drdpbtn sailors-Input-field col" : "btn drdpbtn sailors-Input-field row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white" }}>
                        {buttonName}<FaCaretDown style={{ width: "15px" }} />
                        </button>
                        <div className="dropdown-menu" style={{ width: "95%", height: "100px" }}>
                            <span onClick={(e) => { setButtonName((prev) => prev = "Institute") }} className="dropdown-item" style={{ height: "50px" }}>Institute</span>
                            <span onClick={(e) => { setButtonName((prev) => prev = "Company") }} className="dropdown-item" >Company</span>
                        </div>
                    </div>
                </div> 
          </span>

          <span className='d-flex add-course-container justify-content-center' style={{ maxWidth: "100%", padding: "10px" }}>
            <p style={{ maxWidth: "231px" }}>Period</p>
            <div className='form-control sailors-Input-field' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <input type='text' style={{ maxWidth: "180px" }} placeholder='From date' className='dateInput' onFocus={(e) => (e.target.type = 'date')} value={fromDate} onBlur={(e) => {
                if (fromDate == "") {
                  e.target.type = 'text'
                }
              }} onChange={(e) => setFromDate(e.target.value)} />
              <span style={{ maxWidth: "100px", textAlign: 'center' }}>To</span>
              <input type='text' style={{ maxWidth: "180px" }} className='dateInput' placeholder='To Date' value={toDate} onChange={(e) => { setToDate(e.target.value) }} onFocus={(e) => (e.target.type = 'date')} onBlur={(e) => {
                if (toDate == "") {
                  e.target.type = 'text'
                }
              }} />
            </div>
          </span>

          <span className='d-flex add-course-container justify-content-center' style={{ maxWidth: "100%", padding: "10px" }}>
            <p style={{ maxWidth: "231px" }}>Course Status</p>
            <div className='subCheckDivMain sailors-Input-field'>

              <div className='subCheckDiv' style={{ width: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <label style={{ marginTop: '26px', marginRight: '5px' }}>Admitted</label>
                <input type='radio' name='status' value='admitted' onChange={() => setStatus('admitted')} checked={status === 'admitted' ? true : false} style={{ width: "30px", height: '30px' }} />
              </div>

              <div className='subCheckDiv' style={{ width: '170px' }}>
                <label style={{ marginTop: '26px' }}>Non Admitted</label>
                <input type="radio" name='status' value='non-admitted' checked={status === 'non-admitted' ? true : false} onChange={() => setStatus('non-admitted')} style={{ width: "24px", height: '30px' }} />
              </div>
            </div>
          </span>
        
          <div className='form-btns'>
            <button  type="submit" onClick={ManualSearch} className="btn">Search</button>
            <button type='button' className="btn btn-OnboardForm">Clear</button>
          </div>
        </div>
        

      </div> */}
      {/* <div className='instituteOnboarding-list-view' style={{ marginTop: "20px" }}>
        <div className='headerPart'>
          <h4>Enrolled Status List</h4>
        </div>
      </div>
      {loading ?
        <div style={{ maxWidth: "100px", maxHeight: "100px" }}>

          <BallTriangle
            height={20}
            width={20}
            radius={5}
            color="red"
            ariaLabel="ball-triangle-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
          <span>Loading...</span>
        </div>

        : <div className='table-container'>

          {studentList?.length > 0 ?
            <div className='table-Data'>
              <table className='table'>
                <thead>
                  <tr style={{ display: 'flex', justifyContent: "center" }}>
                    <th scope="col" style={{maxWidth:'70px'}}>Sr No.</th>
                    <th scope="col" style={{width:'calc(100%/6)',maxWidth:"100%",textAlign:'center'}}>Candidate Name</th>
                    <th scope="col" style={{width:'calc(100%/6)',maxWidth:"100%",textAlign:'center'}}>Course Name</th>
                    <th scope="col" style={{width:'calc(100%/6)',maxWidth:"100%",textAlign:'center'}}>Contact</th>
                    <th scope="col" style={{width:'calc(100%/6)',maxWidth:"100%",textAlign:'center'}}>Course Status</th>
                    <th scope="col" style={{width:'calc(100%/6)',maxWidth:"100%",textAlign:'center'}}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {studentList.map((item, index) => {
                    return (
                      <tr className='' style={{ display: 'flex', justifyContent: "center" }}>
                        <th style={{maxWidth:'70px'}} scope="row">{index + 1}</th>
                        <td style={{width:'calc(100%/6)',maxWidth:"100%", textAlign:'center'}}>{item.candidate_name}</td>
                        <td style={{width:'calc(100%/6)',maxWidth:"100%", textAlign:'center'}}>{item.course_name}</td>
                        <td style={{width:'calc(100%/6)',maxWidth:"100%", textAlign:'center'}}>{item.mobile_number1}</td>
                        <td style={{width:'calc(100%/6)',maxWidth:"100%", textAlign:'center'}}>{item.status}</td>
                        <td style={{width:'calc(100%/6)',maxWidth:"100%", textAlign:'center'}}>
                          <div style={{ cursor: "pointer", display: 'flex', flexDirection: "row",justifyContent:'center' }}>
                            <FaPen data-toggle="modal" data-target="#instituteDetailsModal" title='Edit' style={{ width: "30px", color: "#0CB1C0", border: "1px solid gray", padding: "5px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px" }} /> <RiDeleteBin6Line title='Delete' style={{ width: "30px", color: "#F84F31", border: "1px solid gray", padding: "5px", borderTopRightRadius: "10px", borderBottomRightRadius: "10px" }} />
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>


              </table>
            </div>
            :
            <center>No Data found</center>}
        </div>} */}
    </div>
  );
};

export default AdminReport;
