// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.company-registration-form-container{
    background: #2A737A;
        background: linear-gradient(117.25deg, #0A7388 -112.4%, #FFFFFF 99%);
}
.company-registration-form-container ::-webkit-scrollbar{
    display: none;
}
.list{
    position: absolute !important;
}
.companyFormDiv{
    height: 8%;
}

@media only screen and (max-width:768px) {
    .companyFormDiv{
        height: 17%;
    }
    .companyFormDiv div{
        margin-bottom: 5px;
    }
    
}

.nbt2 div #canv{
    margin-left: -29px;
}
.nbt2 div div #reload_href{
    margin-left: -29px;
    text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/commonAuth/register/Company Register/CompanyRegister.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;QACf,oEAAoE;AAC5E;AACA;IACI,aAAa;AACjB;AACA;IACI,6BAA6B;AACjC;AACA;IACI,UAAU;AACd;;AAEA;IACI;QACI,WAAW;IACf;IACA;QACI,kBAAkB;IACtB;;AAEJ;;AAEA;IACI,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,kBAAkB;AACtB","sourcesContent":[".company-registration-form-container{\n    background: #2A737A;\n        background: linear-gradient(117.25deg, #0A7388 -112.4%, #FFFFFF 99%);\n}\n.company-registration-form-container ::-webkit-scrollbar{\n    display: none;\n}\n.list{\n    position: absolute !important;\n}\n.companyFormDiv{\n    height: 8%;\n}\n\n@media only screen and (max-width:768px) {\n    .companyFormDiv{\n        height: 17%;\n    }\n    .companyFormDiv div{\n        margin-bottom: 5px;\n    }\n    \n}\n\n.nbt2 div #canv{\n    margin-left: -29px;\n}\n.nbt2 div div #reload_href{\n    margin-left: -29px;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
