// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
 
.button-88 {
  display: flex;
  align-items: center;
  font-family: inherit;
  font-weight: 500;
  font-size: 16px;
  padding: 0.7em 1.4em 0.7em 1.1em;
  color: white;
  background: #ad5389;
  background: linear-gradient(0deg, #005A80 0%, #005A80 100%);
  border: none;
  box-shadow: 0 0.7em 1.1em -0.8em #005A80;
  letter-spacing: 0.05em;
  border-radius: 20em;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  outline: none;
}

.button-88:hover {
  box-shadow: 0 0.5em 1.5em -0.5em #14a73e98;
}

.button-88:active {
  box-shadow: 0 0.3em 1em -0.5em #14a73e98;
  outline: none;
}
.verifyEmailPopUp{
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  padding: 20%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/commonAuth/register/Institute Register/InstituteRegister.css"],"names":[],"mappings":";;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,oBAAoB;EACpB,gBAAgB;EAChB,eAAe;EACf,gCAAgC;EAChC,YAAY;EACZ,mBAAmB;EACnB,2DAA2D;EAC3D,YAAY;EACZ,wCAAwC;EACxC,sBAAsB;EACtB,mBAAmB;EACnB,eAAe;EACf,iBAAiB;EACjB,yBAAyB;EACzB,0BAA0B;EAC1B,aAAa;AACf;;AAEA;EACE,0CAA0C;AAC5C;;AAEA;EACE,wCAAwC;EACxC,aAAa;AACf;AACA;EACE,mCAA2B;UAA3B,2BAA2B;EAC3B,YAAY;EACZ,YAAY;AACd","sourcesContent":["\n \n.button-88 {\n  display: flex;\n  align-items: center;\n  font-family: inherit;\n  font-weight: 500;\n  font-size: 16px;\n  padding: 0.7em 1.4em 0.7em 1.1em;\n  color: white;\n  background: #ad5389;\n  background: linear-gradient(0deg, #005A80 0%, #005A80 100%);\n  border: none;\n  box-shadow: 0 0.7em 1.1em -0.8em #005A80;\n  letter-spacing: 0.05em;\n  border-radius: 20em;\n  cursor: pointer;\n  user-select: none;\n  -webkit-user-select: none;\n  touch-action: manipulation;\n  outline: none;\n}\n\n.button-88:hover {\n  box-shadow: 0 0.5em 1.5em -0.5em #14a73e98;\n}\n\n.button-88:active {\n  box-shadow: 0 0.3em 1em -0.5em #14a73e98;\n  outline: none;\n}\n.verifyEmailPopUp{\n  backdrop-filter: blur(10px);\n  padding: 20%;\n  height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
