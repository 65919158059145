
import React, { useEffect } from 'react'
import Something from './QuillEditor/Editor';
import NewsThing from "./QuillEditorNews/Editor";
import AddThings from "./QuillEditoradd/Editor";
import { useSelector } from 'react-redux';
const MainEditorfile = () => {
  const page = useSelector((state)=>state?.commonslice?.page);

  return (
    <div>
      {
        page=="content" ? <Something/> : page=="news" ? <NewsThing/> :  <AddThings/>
      }
      
    </div>
  )
}

export default MainEditorfile;

