import React, { useEffect, useState } from "react";
// import { useNavigate } from 'react-router-dom';
import "./adminDashboard.css";
import { useSelector } from "react-redux";
import DashboardCard from "./dashboardComponents/dashboardCards";
import AdminDashboardCards from "./dashboardComponents/AdminDashboardCards/AdminDashBoardCards";
import { GetAdminDashboard } from "../../Apis/getApis";

const AdminDashboardpage = () => {
  // const handleLogout = ()=>{
  //   localStorage.removeItem('userData');
  //   navigate("/login");
  // }

  const [dashboardsAllData, setDashboardAllData] = useState({
    total_sailors_count: 0,
    verified_sailors_count: 0,
    unverified_sailors_count: 0,
    rejected_sailors_count: 0,
    total_institutes_count: 0,
    verified_institutes_count: 0,
    unverified_institutes_count: 0,
    rejected_institutes_count: 0,
    total_companies_count: 0,
    verified_companies_count: 0,
    unverified_companies_count: 0,
    rejected_companies_count: 0,
    total_jobs_count: 0,
    job_application_data: {
      total_job_application_count: 0,
      pending_job_application_count: 0,
      rejected_job_application_count: 0,
      viewed_job_application_count: 0,
      hired_job_application_count: 0,
      shortlisted_job_application_count: 0,
      joined_job_application_count: 0,
    },
  });
  useEffect(() => {
    GetAdminDashboard()
      .then((res) => {
        console.log(res);
        setDashboardAllData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const sidebar = useSelector((state) => state?.commonslice?.sidebar);
  useEffect(() => {
    localStorage.removeItem("reloader");
  }, []);

  return (
    <div
      className="w-full h-full"
      style={{ marginLeft: sidebar ? "30px" : "0px" }}
    >
      <div className="instituteOnboarding-list-view">
        <div className="headerPart">
          <h4>DashBoard</h4>
        </div>
        <div
          className="dashboard-data-card InstituteMainContainer"
          style={{
            height: "auto",
            display: "flex",
            justifyContent: "start",
            flexWrap: "wrap",
            flexDirection: "column",
            minHeight: "auto",
            width: "100%",
            borderTopColor: "ActiveBorder",
          }}
        >
          <div className="w-100">
            <DashboardCard dashboardsAllData={dashboardsAllData} />
          </div>
          <div>
            <AdminDashboardCards dashboardsAllData={dashboardsAllData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardpage;
