import React, { useEffect, useState } from 'react';
import "./welcomePage.css"
import { useNavigate } from 'react-router-dom';
const WelcomePage = () => {
    const [mute,setMute] = useState(true);
    const navigate = useNavigate();
    useEffect(() => {
        setTimeout(()=>{
            setMute(false)
        },80)

                   let  userData =  JSON.parse(localStorage.getItem('userData'))
                    
                        
                    setTimeout(() => {
                        if(userData?.user_type=="super_admin"){
                            navigate('/admin')
                        }else if(userData?.user_type=="institute"){
                            navigate("/institute")
                        }else{
                            navigate("/company/dashboard");
                        }
                    },0)
    }, [])
    return (
        <div className='welcomeContainer'>
                <audio autoplay muted={mute} className='entryAudio'>
                    <source src="/assets/commonAssets/entryAudio.mp3" type="audio/mp3" />
                </audio>
            <div>
                {/* <h1 className='entryText'>Sailors Hub</h1> */}
                
                {/* <span className='welcomePage-Title'>Welcome To</span> */}
                {/* <img  src='/assets/authAssets/Cargo ship on its way.gif'className='gifImage' alt='logo'/> */}
                {/* <img className='animate__animated animate__lightSpeedInRight welcomeImg' src='/assets/authAssets/Sailors-Hub-logo.png' style={{height:"50px"}} alt='logo'/>*/}
            </div>
             {/* <img className='shipGifImage' style={{background:"transprent"}} src='/assets/commonAssets/output-onlinegiftools.gif' alt='logo'/>  */}
        </div>
    )
}

export default WelcomePage;
