// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shipType{
    max-width: 300px;
    display: flex;
    flex-direction: row;
    margin: 12px;
}
.shipType input{
    box-shadow: 0px 0px 5px 0px #ffffff inset;
    margin-right: 5px;
    width: 36px;
    height: 36px;
    top: 778px;
    left: 334px;
    gap: 0px;
    border-radius: 5px 0px 0px 0px;
    opacity: 0px;
    background: #FFFFFF;
    outline: none;
    border: none;    
}

.shipType label{
    width: 100%;
height: 24px;
top: 784px;
left: 400px;
gap: 0px;
opacity: 0px;
font-family: "Poppins", sans-serif;
font-size: 20px;
font-weight: 500;
line-height: 24px;
text-align: left;
color: #767676;
}
.col-form-label{
    font-family: Poppins !important;
    font-size: 20px !important;
    font-weight: 500 !important;
    color:#767676 !important;
}`, "",{"version":3,"sources":["webpack://./src/company/pages/otherInfo/otherInfo.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,YAAY;AAChB;AACA;IACI,yCAAyC;IACzC,iBAAiB;IACjB,WAAW;IACX,YAAY;IACZ,UAAU;IACV,WAAW;IACX,QAAQ;IACR,8BAA8B;IAC9B,YAAY;IACZ,mBAAmB;IACnB,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,WAAW;AACf,YAAY;AACZ,UAAU;AACV,WAAW;AACX,QAAQ;AACR,YAAY;AACZ,kCAAkC;AAClC,eAAe;AACf,gBAAgB;AAChB,iBAAiB;AACjB,gBAAgB;AAChB,cAAc;AACd;AACA;IACI,+BAA+B;IAC/B,0BAA0B;IAC1B,2BAA2B;IAC3B,wBAAwB;AAC5B","sourcesContent":[".shipType{\n    max-width: 300px;\n    display: flex;\n    flex-direction: row;\n    margin: 12px;\n}\n.shipType input{\n    box-shadow: 0px 0px 5px 0px #ffffff inset;\n    margin-right: 5px;\n    width: 36px;\n    height: 36px;\n    top: 778px;\n    left: 334px;\n    gap: 0px;\n    border-radius: 5px 0px 0px 0px;\n    opacity: 0px;\n    background: #FFFFFF;\n    outline: none;\n    border: none;    \n}\n\n.shipType label{\n    width: 100%;\nheight: 24px;\ntop: 784px;\nleft: 400px;\ngap: 0px;\nopacity: 0px;\nfont-family: \"Poppins\", sans-serif;\nfont-size: 20px;\nfont-weight: 500;\nline-height: 24px;\ntext-align: left;\ncolor: #767676;\n}\n.col-form-label{\n    font-family: Poppins !important;\n    font-size: 20px !important;\n    font-weight: 500 !important;\n    color:#767676 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
