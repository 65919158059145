import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

const AgeBar = ({ageGroupData}) => {
    let labels = Object.keys(ageGroupData);
    let values = Object.values(ageGroupData);
    console.log(ageGroupData,labels,values,"ageGroupData")

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Attrition via Age',
                data: values,
                backgroundColor: ['#5C95AD', '#5C95AD', '#5C95AD', '#5C95AD'],
                barPercentage: 0.3,
                stack: 'Stack 1',
            },
        ],
    };

    const options = {
        responsive: true,
        aspectRatio: 1.1,
        plugins: {
            datalabels: {
                display: true,
                color: "black",
                formatter: Math.round,
                anchor: "end",
                offset: -20,
                align: "start"
              },
            legend: {
                display: true
            },
            tooltip: {
                mode: 'index',
                intersect: true,
            },
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
    };
  return (
    <div>
      <Bar data={data} options={options} />
    </div>
  )
}

export default AgeBar;
