import React, { useEffect, useState } from 'react'
import { FaCloudUploadAlt } from 'react-icons/fa';
import {  verifiedEmailCheck, verifyEmail } from '../../../Apis/postApis';
import { getCities, getCountries, getOneInstituteData, getStates } from '../../../Apis/getApis';
import { useNavigate } from 'react-router-dom';
import validator from 'validator';
import { v4 as uuidV4 } from "uuid";
import { updateInstituteDetails } from '../../../Apis/updateApis';
import { allowedTypesImages } from '../../../utils/variables/folderStructureFiles';
import { errorToast, isValidEmail, successToast, uploadFile } from '../../../utils/helperFunctions/HelperFunctions';
const CompanyDetails = () => {
    const [countriesList, setCountriesList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cities, setCitiesList] = useState([]);
    // form fields
    const [companyData, setCompanyData] = useState('');
    const [contactPerson, setContactPerson] = useState('');
    const [entityName, setEntityName] = useState('');
    const [email, setEmail] = useState('');
    const [websiteName, setWebsiteName] = useState('');
    const [countryName, setCountryName] = useState("Country");
    const [city, setCity] = useState("City");
    const [state, setState] = useState("State");
    const [address, setAddress] = useState('');
    const [otherAddress, setOtherAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [otherPhone, setOtherPhone] = useState('');
    const [image, setImage] = useState('');
    const [countryId, setCountryId] = useState();
    const [stateId, setStateId] = useState();
    const [cityId, setCityId] = useState();
    const [file, setFile] = useState(null);
    const [pincode, setPincode] = useState('');
    const navigate = useNavigate();
    const instituteInfo = JSON.parse(localStorage.getItem('userData'));
    const [phoneCode, setPhoneCode] = useState('');

    const checkEmail = () => {
        if (email !== "") {
            if (!isValidEmail(email)) {
                errorToast("Invalid Email id");
                setEmail('');
            }
        }
    }

    const getCountryById = (id) => {
        for (let i = 0; i < countriesList.length; i++) {
            if (countriesList[i]?.id == id) {
                setPhoneCode(countriesList[i].phone_code);
                return countriesList[i];
            }
        }
    }

    const getStateById = (id) => {
        for (let i = 0; i < stateList.length; i++) {
            if (stateList[i]?.id == id) {
                return stateList[i];
            }
        }
    }

    const getCityById = (id) => {
        for (let i = 0; i < cities.length; i++) {
            if (cities[i]?.id == id) {
                return cities[i];
            }
        }
    }

    function validUrl() {
        if (websiteName !== '') {
            if (!validator.isURL(websiteName)) {
                errorToast("Please enter valid url");
                setWebsiteName('');
                return;
            }
        }
    }

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (allowedTypesImages.includes(selectedFile?.type)) {
            setFile(selectedFile);
            let res = uploadFile(selectedFile);
            res.then((data) => {
                console.log(data, "uploadedImage")
                const userData = JSON.parse(localStorage.getItem('userData'));
                userData.profile_pic = data;
                localStorage.setItem('userData',JSON.stringify(userData))
                setImage(data);
            })
        } else {
            errorToast('Invalid file type. Only images are allowed.');
        }
    };

    async function getInstituteDetails(countryData) {
        await getOneInstituteData(instituteInfo?.id)
            .then((res) => {
                console.log(res);
                const data = res?.data?.data;
                setCompanyData(data);

                setEmail(data?.email);
                setContactPerson(data?.contact_person || "NA");
                setAddress(data?.address1);
                setEntityName(data?.first_name);
                setCountryId(data?.country)
                setCity(async (prev) => {
                    await getCities(data?.state)
                        .then((res) => {
                            setCitiesList(res?.data?.data)
                            let getOneCity = res?.data?.data.filter((city) => city.id === companyData?.city);

                            makeCity(...getOneCity);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                });
                setPincode(data?.pin_code)
                setWebsiteName(data?.website);
                setPhone(data?.mobile_number1);
                setOtherPhone(data?.mobile_number2);
                setOtherAddress(data?.address2);
                setStateList([]);
                setStateId(data?.state);
                setCityId(data?.city)
                setCountryName(async (prev) => {
                    for (let i = 0; i < countryData.length; i++) {
                        if (countryData[i].id === data.country) {
                            makeCountry(countryData[i])
                            setPhoneCode(countryData[i].phone_code)
                            return prev = countryData[i].name;
                        }
                    }
                })
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const saveForm = (e) => {
        e.preventDefault();
        let object = {
            first_name: entityName,
            contact_person: contactPerson,
            website: websiteName,
            mobile_number1: phone,
            mobile_number2: otherPhone,
            address1: address,
            address2: otherAddress,
            state: stateId,
            city: cityId,
            country: countryId,
            pin_code: pincode,
            // password: password,
            profile_pic: image,
        }

        if (!validator.isEmail(email)) {
            errorToast("Please enter valid Email");
            return;
        }

        if (!validator.isURL(websiteName)) {
            errorToast("Please enter valid url");
            return;
        } else {

            updateInstituteDetails(instituteInfo?.id, object)
                .then((res) => {
                    successToast("Company Details Updated successfully")
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    const makeCountry = (item) => {
        setCountryName((prev) => prev = item.name);
        setCountryId(item?.id);
        getStates(item.id)
            .then((res) => {
                setStateList(res?.data?.data);
                let getOneState = res?.data?.data?.filter((state) => state.id === companyData?.state);
                makeState(...getOneState);
            })
            .catch((err) => {
                console.log(err)
            })
    }
    const makeState = (item) => {
        setState(item.name);
        setStateId(item?.id)
        setState((prev) => prev = item.name)
        getCities(item.id)
            .then((res) => {
                setCitiesList(res?.data?.data)
                let getOneCity = res?.data?.data.filter((city) => city.id === companyData?.city);
                makeCity(...getOneCity);
            })
            .catch((err) => {
                console.log(err)
            })

    }
    const makeCity = (item) => {
        setCity((prev) => prev = item?.name)
    }

    useEffect(() => {
        getCountries()
            .then((res) => {
                setCountriesList(res?.data?.data)
                getInstituteDetails(res?.data?.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    return (
        <div className='container boarding-form' style={{ marginBottom: "20px" }}>
            <div className='instituteOnboarding-list-view'>
                <div className='headerPart'>
                    <h4>Company Details</h4>
                </div>
            </div>
            {/* <form className='animate__animated animate__fadeIn main-form' style={{ maxWidth: "100%", margin: "auto" }}>
                <div className={width < 500 ? " col" : " row"} style={{ width: "100%", gap: '8px' }}>
                    <div className={width < 500 ? 'form-group selectedField-row row' : 'form-group selectedField col'} style={{ paddingTop: "7px" }}>
                        <input type="text" className="form-control sailors-Input-field" value={entityName} onChange={(e) => setEntityName(e.target.value)} placeholder="Company Name" style={{ marginLeft: '-9px' }} />
                    </div>
                    <div className={width < 500 ? 'form-group row' : 'form-group col'}>
                        <input type="text" value={contactPerson} onChange={(e) => setContactPerson(e.target.value)} className="form-control sailors-Input-field" placeholder="Contact Person" style={{ marginTop: "5px" }} />
                    </div>
                </div>

                <div className={width < 500 ? "form-group col" : "form-group row"} style={{ marginRight: "20px", gap: "25px" }}>
                    <div className={width < 500 ? 'form-group row' : 'form-group col'}>
                        <input type="text" value={websiteName} onChange={(e) => setWebsiteName(e.target.value)} className="form-control sailors-Input-field" placeholder="Website Name" />
                    </div>
                    <div className={width < 500 ? 'form-group row' : 'form-group col'}>
                        <div className="form-control sailors-Input-field" >{email}</div>
                    </div>
                </div>
                <div className={width < 500 ? "form-group col" : "form-group row"} style={{ marginRight: "-41px", gap: "35px" }}>
                    <div className={width < 500 ? 'form-group row' : 'form-group col'}>
                        <input type="number" value={pincode || ""} onChange={(e) => setPincode(e.target.value)} className="form-control sailors-Input-field" placeholder='Pincode' />
                    </div>
                    <div className={width < 500 ? 'form-group row' : 'form-group col'} style={{ marginRight: '-18px' }}>
                        <button type="button" className={width < 500 ? "btn drdpbtn col sailors-Input-field" : "btn sailors-Input-field drdpbtn row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white" }}>
                            {countryName}<FaCaretDown style={{ width: "15px" }} />
                        </button>
                        <div className="dropdown-menu" style={{ width: "300px", height: "200px", overflowY: 'scroll' }}>
                            {countriesList?.map((item, index) => {
                                return (
                                    <div className="dropdown-item" style={{ height: "50px" }} onClick={() => makeCountry(item)}>{item?.name}</div>
                                )
                            })}
                        </div>
                    </div>
                </div>

                <div className={width < 600 ? "form-group col" : "form-group row"} >
                    <div className={width < 600 ? 'form-group selectedField-row row' : 'form-group selectedField col'} style={{ marginLeft: "14px", maxWidth: "691px" }}>
                        <button type="button" className={width < 500 ? "btn drdpbtn col sailors-Input-field" : "btn sailors-Input-field drdpbtn row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white" }}>
                            {state}<FaCaretDown style={{ width: "15px" }} />
                        </button>
                        <div className="dropdown-menu" style={{ width: "500px", height: "200px", overflowY: 'scroll' }}>
                            {stateList?.map((item, index) => {
                                return (
                                    <div className="dropdown-item" style={{ height: "50px" }} onClick={() => makeState(item)}>{item?.name}</div>
                                )
                            })}
                        </div>
                    </div>
                    <div className={width < 600 ? 'form-group row' : 'form-group col'} style={{ marginRight: "-16px", marginLeft: "25px" }}>
                        <button type="button" className={width < 500 ? "btn drdpbtn col sailors-Input-field" : "btn sailors-Input-field drdpbtn row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white" }}>
                            {city}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<FaCaretDown style={{ width: "15px" }} />
                        </button>
                        <div className="dropdown-menu" style={{ width: "500px", height: "200px", overflowY: 'scroll' }}>
                            {cities?.map((item, index) => {
                                return (
                                    <div className="dropdown-item" style={{ height: "50px" }} onClick={() => makeCity(item)}>{item?.name}</div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className={width < 600 ? "form-group col" : "form-group row"} >


                    <div className={width < 600 ? "form-group col" : "form-group row"} style={{ gap: "1px" }}>
                        <div className={width < 600 ? 'form-group selectedField-row  row' : 'form-group selectedField ntb col'}>
                            <input type="text" className="form-control sailors-Input-field" value={address} onChange={(e) => setAddress(e.target.value)} placeholder="Address" style={{ padding: "0px 10px 0px 10px", margin: "0px -12px 0px -14px" }} />
                        </div>
                        <div className={width < 600 ? 'form-group row' : 'form-group col'} >
                            <input type="text" className="form-control sailors-Input-field" value={otherAddress} onChange={(e) => setOtherAddress(e.target.value)} placeholder="Other Address" style={{ marginLeft: "10px" }} />
                        </div>
                    </div>

                    <div className={width < 600 ? "form-group col" : "form-group row"}>
                        <div className={width < 600 ? 'form-group selectedField-row row' : 'form-group selectedField col'}>
                            <form className="form-inline">
                                <div className="input-group">
                                    <div className="input-group-prepend" style={{ maxWidth: "35px", height: "46px" }}>
                                        <div className="input-group-text">📞</div>
                                    </div>
                                    <input type="number" value={phone} onChange={(e) => setPhone(e.target.value)} className="form-control numberField sailors-Input-field" id="inlineFormInputGroupUsername2" placeholder="Contact Number" />
                                </div>
                            </form>
                        </div>
                        <div className={width < 600 ? 'form-group row' : 'form-group col'}>
                            <form className="form-inline">
                                <div className="input-group">
                                    <div className="input-group-prepend" style={{ maxWidth: "35px", height: "46px" }}>
                                        <div className="input-group-text">📞</div>
                                    </div>
                                    <input type="number" value={otherPhone} onChange={(e) => setOtherPhone(e.target.value)} className="form-control sailors-Input-field numberField" id="inlineFormInputGroupUsername2" placeholder="Other Contact Number" />
                                </div>
                            </form>
                        </div>
                        <div className={width < 600 ? 'form-group row' : 'form-group col'}>
                            <form className="form-inline">
                                <div className="input-group">
                                    <input type="file" className='uploadLogo-Input' onChange={handleFileChange} placeholder='Images' />
                                </div>

                            </form>
                        </div>
                    </div>
                </div>


                <div className='form-btns'>
                    <button type="submit" onClick={saveForm} className="btn">Save</button>
                    <button className="btn btn-OnboardForm">Cancel</button>
                </div>

            </form> */}

            <form className="container mt-5 p-4 bg-white shadow rounded-top position-relative">
                {/* <span className='mustStar h-25' style={{ textAlign: 'end', fontSize: "15px", position: 'absolute', top: "-20px", left: "-16px" }}>Fields marked (*) are mandatory.</span> */}
                <div className="row mb-4 d-flex flex-row justify-content-around">
                    <div className="col-md-5">
                        {/* <label className="form-label" htmlFor="instituteName">
                            Company Name<span className='mustStar'>*</span>
                        </label> */}
                        <input placeholder='Company Name' required id="instituteName" value={entityName} onChange={(e) => setEntityName(e.target.value)} type="text" className="form-control sailors-Input-field" />
                    </div>
                    <div className="col-md-5">
                        {/* <label className="form-label" htmlFor="contactPerson">
                            Contact Person<span className='mustStar'>*</span>
                        </label> */}
                        <input data-title="Contact Person" placeholder='Contact Person' required id="contactPerson" type="text" value={contactPerson} onChange={(e) => setContactPerson(e.target.value)} className="form-control sailors-Input-field" />
                    </div>
                </div>

                <div className="row mb-4 d-flex flex-row justify-content-around">
                    <div className="col-md-5">
                        {/* <label className="form-label" htmlFor="instituteName">
                            Pin Code
                        </label> */}
                        <input id="instituteName" placeholder='Pin Code' value={pincode} onChange={(e) => setPincode(e.target.value)} type="number" className="form-control sailors-Input-field" />
                    </div>
                    <div className="col-md-5">
                        {/* <label className="form-label" htmlFor="email">
                            Email<span className='mustStar'>*</span>
                        </label> */}
                        <input placeholder='email' required id="email" value={email} onBlur={checkEmail} onChange={(e) => setEmail(e.target.value)} type="email" className="form-control sailors-Input-field" />
                    </div>
                </div>

                <div className="row mb-4 d-flex flex-row justify-content-around">
                    <div className="col-md-5">
                        {/* <label className="form-label" htmlFor="websiteName">
                            Website URL<span className='mustStar'>*</span>
                        </label> */}
                        <input placeholder='Website URL' id="websiteName" value={websiteName} onBlur={validUrl} onChange={(e) => setWebsiteName(e.target.value)} type="text" className="form-control sailors-Input-field" />
                    </div>
                    <div className="col-md-5">
                        {/* <label className="form-label" htmlFor="country">
                            Country<span className='mustStar'>*</span>
                        </label> */}
                        <select id="country" style={{ minHeight: "40px" }} value={countryId} onChange={(e) => makeCountry(getCountryById(e.target.value))} className="form-select sailors-Input-field">
                            <option value=''>Select Country</option>
                            {countriesList?.map((item) => {
                                return (
                                    <option value={item?.id} key={uuidV4()} className="dropdown-item" style={{ height: "50px" }}>{item?.name}</option>
                                )
                            })}
                        </select>
                    </div>
                </div>

                <div className="row mb-4 d-flex flex-row justify-content-around">
                    <div className="col-md-5">
                        {/* <label className="form-label" htmlFor="state">
                            State
                        </label> */}
                        <select id="state" style={{ minHeight: "40px" }} value={stateId} onChange={(e) => makeState(getStateById(e.target.value))} className="form-select sailors-Input-field">
                            <option>Select State</option>
                            {stateList?.map((item) => {
                                return (
                                    <option value={item.id} key={uuidV4()} className="dropdown-item" style={{ height: "50px" }} >{item?.name}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="col-md-5">
                        {/* <label className="form-label" htmlFor="city">
                            City
                        </label> */}
                        <select id="city" value={cityId} style={{ minHeight: "40px" }} onChange={(e) => setCityId(e.target.value)} className="form-select sailors-Input-field">
                            <option>Select City</option>
                            {cities?.map((item) => {
                                return (
                                    <option value={item?.id} key={uuidV4()} className="dropdown-item" style={{ height: "50px" }}>{item?.name}</option>
                                )
                            })}
                        </select>
                    </div>
                </div>

                <div className="row mb-4 d-flex flex-row justify-content-around">
                    <div className="col-md-5">
                        {/* <label className="form-label" htmlFor="address">
                            Address<span className='mustStar'>*</span>
                        </label> */}
                        <textarea rows="3" id="address" placeholder='Address' value={address} onChange={(e) => setAddress(e.target.value)} type="text" className="form-control sailors-Input-field" />
                    </div>
                    <div className="col-md-5">
                        {/* <label className="form-label" htmlFor="otherAddress">
                            Other Address
                        </label> */}
                        <textarea rows="3" id="otherAddress" placeholder='Other Address' value={otherAddress} onChange={(e) => { setOtherAddress(e.target.value) }} type="text" className="form-control sailors-Input-field" />
                    </div>
                </div>

                <div className="row mb-3 d-flex flex-row justify-content-around">
                    <div className="col-md-5">
                        {/* <label className="form-label" htmlFor="contactNumber">
                            Contact Number<span className='mustStar'>*</span>
                        </label> */}
                        <div className="input-group">
                            <div className="input-group-text sailors-Input-field" style={{ maxWidth: "70px", textAlign: 'center' }}> {phoneCode !== "" ? phoneCode : "📞"}</div>
                            <input id="contactNumber" placeholder='Contact Number' type="number" value={phone} onChange={(e) => setPhone(e.target.value)} className="form-control sailors-Input-field" />
                        </div>
                    </div>
                    <div className="col-md-5">
                        {/* <label className="form-label" htmlFor="otherContactNumber">
                            Other Contact Number
                        </label> */}
                        <div className="input-group">
                            <div className="input-group-text sailors-Input-field" style={{ maxWidth: "70px", textAlign: 'center' }}> {phoneCode !== "" ? phoneCode : "📞"}</div>
                            <input value={otherPhone} placeholder='Other Contact Number' onChange={(e) => setOtherPhone(e.target.value)} id="otherContactNumber" type="number" className="form-control sailors-Input-field" />
                        </div>
                    </div>
                </div>

                <div className="mb-3 d-flex flex-row justify-content-around sailors-Input-field">
                    <label className="form-label w-25" htmlFor="uploadLogo">
                        Upload Logo <FaCloudUploadAlt style={{ width: "30px", marginLeft: "10px" }} />
                    </label>
                    <input id="uploadLogo" type="file" onChange={(e) => handleFileChange(e)} className="col-md-5 w-50 p-2 px-5" />
                </div>

                <div className='form-btns' style={{ marginBottom: "20px", marginTop: "50px" }}>
                    <button type="submit" onClick={(e) => saveForm(e)} className="btn">Save</button>
                    {/* <button type='button' onClick={handleCancel} className="btn btn-OnboardForm">Cancel</button> */}
                </div>
            </form>
        </div>
    )
}

export default CompanyDetails;
