import React, { useEffect, useState } from 'react'
import { BallTriangle } from 'react-loader-spinner';
import { FaEye, FaBox, FaCaretDown, FaPen } from 'react-icons/fa';
import { getCountries, getJobCategoryList, getRolesAndRank } from '../../../../Apis/getApis';
import { saveJobRank } from '../../../../Apis/postApis';
import { updateRoleRank } from "../../../../Apis/updateApis";
import { RiDeleteBin6Line } from 'react-icons/ri';
import { deleteJobRank } from '../../../../Apis/deleteApis';
import Swal from 'sweetalert2';
import { deleteToast } from '../../../../utils/helperFunctions/HelperFunctions';
import "../../../../StyleHub/main.css"

const JobRank = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [jobList, setJobList] = useState([]);
  const [jobRank, setJobRank] = useState('')
  const [country, setCountry] = useState('country');
  const [countryList, setCountryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [searchData, setSearchData] = useState([])
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedUser, setSelectedUser] = useState();

  const editRank = (item) => {
    console.log(item, "invoked")
    setIsEdit(true);
    setSelectedUser(item);
    setJobRank(item.name);
    setSelectedCountries(item?.countries)
  }
  const deleteRank = (id) => {

    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete Rank",
      icon: "error",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete Rank!"
    }).then((result) => {
      if (result.isConfirmed) {

        deleteJobRank(id)
          .then((res) => {
            console.log(res)
            getAllJobRanks();
          })
          .catch(err => {
            console.log(err)
          })

        deleteToast("Rank is deleted.");
      }
    })

  }

  function getAllJobRanks() {
    getRolesAndRank()
      .then((res) => {
        console.log(res, "job Category");
        setJobList(res?.data?.data?.results)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {

    getAllJobRanks();

    getCountries()
      .then((res) => {
        setLoading(false);
        console.log(res?.data?.data, "countries");
        setCountryList(res?.data?.data)
      })
      .catch((err) => {
        setLoading(false);
        console.log(err)
      })
  }, [])

  const addCountry = (countryData) => {
    const isThere = selectedCountries.some((item) => isEdit ? item.id == countryData.id : item == countryData.id);
    if (!isThere) {
      if (isEdit) {
        setSelectedCountries([...selectedCountries, countryData.id])
      } else {
        setSelectedCountries([...selectedCountries, countryData.id])
      }
    }
  }
  const handleCancle = () => {
    setIsEdit(false);
    setJobRank('');
    setCountryList([]);
  }

  const findById = (id) => {

    for (let i = 0; i < countryList.length; i++) {
      if (countryList[i].id === id) {
        return countryList[i]
      }
    }
  }
  const removeCountry = (data) => {
    const countries = [...selectedCountries]
    const newCountry = countries.filter((item) => item !== data);
    setSelectedCountries(newCountry);
  }

  const handleSubmit = () => {
    if (!isEdit) {
      saveJobRank({ name: jobRank, countries: selectedCountries })
        .then((res) => {
          getAllJobRanks();
          setJobRank('');
          setSelectedCountries([]);
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      updateRoleRank(selectedUser.id, { name: jobRank, countries: selectedCountries })
        .then((res) => {
          console.log(res, "india");
          setIsEdit(false);
          setJobRank('');
          setSelectedCountries([]);
          getAllJobRanks();
        })
        .catch((err) => {
          console.log(err);
        })
    }
  }


  return (
    <div className='institute-container animate__animated animate__fadeIn'>
      <div className='instituteOnboarding-list-view'>
        <div className='headerPart'>
          <h4>Job Role / Rank</h4>
        </div>
      </div>
      <div className='table-container position-relative'>
        <span className='mustStar' style={{ textAlign: 'end', fontSize: "15px", position: 'absolute', top: "-20px", left: "-16px" }}>Fields marked (*) are mandatory.</span>
        <div className='allDropDown d-flex flex-column' style={{ width: "100%" }}>

          <span className='d-flex add-course-container add-course-country-container justify-content-center align-items-start' style={{ maxWidth: "100%", padding: "20px" }}>
            <div className="dropdown dropDown-locality">
              <button type="button" className={width < 500 ? "btn drdpbtn sailors-Input-field col" : "btn drdpbtn sailors-Input-field row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white", maxWidth: "200px" }}>
                Country<FaCaretDown style={{ width: "15px" }} />
              </button>
              <div className="dropdown-menu" style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "300px", height: "200px", overflowY: 'scroll' }}>
                <div className='searchBar-Container' style={{ maxHeight: "50px" }}>

                  <div className="input-group position-sticky" style={{ marginBottom: "-100px", paddingLeft: "10px" }}>
                    <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} className="form-control position-static" placeholder="Search ......" aria-label="Recipient's username" />

                  </div>

                </div>
                {search == '' && <React.Fragment>{countryList.length > 0 ? countryList?.map((item, index) => {
                  return (
                    <div className="dropdown-item" style={{ height: "50px", cursor: "pointer" }} onClick={() => addCountry(item)}>{item?.name}</div>
                  )
                }) : <center style={{ marginTop: "10%" }}>!Opps check the Internet</center>}</React.Fragment>}

                {search !== '' && <>{searchData.length > 0 ? searchData.reverse()?.map((item, index) => {
                  return (
                    <div className="dropdown-item" style={{ height: "50px", cursor: "pointer" }} onClick={() => addCountry(item)}>{item.name}</div>
                  )
                }) : <center style={{ marginTop: "10%" }}>No Match</center>}</>}

              </div>
            </div>
            <div className={selectedCountries.length > 0 ? 'selectedCountries-container' : 'selectedCountries-container noDataContainer'}>

              {selectedCountries.length > 0 ? <>{selectedCountries.map((item) => {
                return (
                  <div className='selectedCountry' >
                    <p style={{ marginTop: "37px", }}>{findById(item).name}</p>
                    <button onClick={() => removeCountry(item)} type="button" className="close" style={{ width: "15px", height: "15px" }}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                )
              })}</> : <center style={{ marginTop: "15px" }}>All Countries</center>}
            </div>
          </span>

          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">Requirements<span className='mustStar'>*</span></label>
            <div className="col-sm-9">
              <input maxLength={50} value={jobRank} onChange={(e) => { setJobRank(e.target.value) }} type="text" rows="3" className="form-control sailors-Input-field" />
            </div>
          </div>

          {/* <span className='d-flex add-course-container' style={{ maxWidth: "1214px" }}>
            <p style={{ width: "auto", maxWidth: "231px" }}>Job Role/Rank Name<span className='mustStar'>*</span></p>
            
            <input type='text' maxLength={50} value={jobRank} onChange={(e)=>setJobRank(e.target.value)} className='form-control sailors-Input-field' style={{ maxWidth: "80%" }} />
          </span> */}
        </div>
        <div className='form-btns' style={{ marginBottom: "10px" }}>
          <button type="submit" onClick={handleSubmit} className="btn">{isEdit ? "Update" : "Save"}</button>
          <button onClick={handleCancle} className="btn btn-OnboardForm">Cancel</button>
        </div>
      </div>
      <div className='instituteOnboarding-list-view' style={{ marginTop: "20px" }}>
        <div className='headerPart'>
          <h4>Job Role/Rank List</h4>
        </div>
      </div>
      {loading ?
        <div style={{ maxWidth: "100px", maxHeight: "100px" }}>

          <BallTriangle
            height={20}
            width={20}
            radius={5}
            color="red"
            ariaLabel="ball-triangle-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
          <span>Loading...</span>
        </div>

        : <div className='table-container'>

          {jobList?.length > 0 ?
            <div className='table-Data'>
              <table className='table'>
                <thead>
                  <tr style={{ display: 'flex', justifyContent: "flex-start",color:"#005A80" , borderBottom:"1px solid #2A737A66"}}>
                    <th style={{ fontWeight: "700", maxWidth: "150px" ,color:"#005A80"}}>Sr No.</th>
                    <th style={{ fontWeight: "700", textAlign: 'start',color:"#005A80" }}>Subject Name</th>
                    <th style={{ fontWeight: "700",textAlign:'center' ,color:"#005A80" }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {jobList.map((item, index) => {
                    return (
                      <tr className='' style={{ display: 'flex', justifyContent: "space-around", borderBottom:"1px solid #2A737A66" }}>
                        <th scope="row" style={{ maxWidth: "150px" }}>{index + 1}</th>
                        <td style={{ textWrap: 'wrap', textAlign: 'start' }}>{item.name}</td>

                        <td>
                          <div style={{ cursor: "pointer", display: 'flex', flexDirection: "row", justifyContent: 'center' }}>
                            <FaPen onClick={() => editRank(item)} title='Details' style={{ width: "40px", height: "30px", color: "#0CB1C0", border: "1px solid gray", padding: "5px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px" }} /><RiDeleteBin6Line onClick={() => deleteRank(item.id)} title='Delete' style={{ width: "40px", height: "30px", color: "#F84F31", border: "1px solid gray", padding: "5px", borderTopRightRadius: "10px", borderBottomRightRadius: "10px" }} />
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>


              </table>
            </div>
            :
            <center>No Data found</center>}
        </div>}

    </div>
  )
}

export default JobRank;
