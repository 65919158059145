import axios from 'axios';
import { base_url } from '../utils/endpoints/endpoints';

const token = JSON.parse(localStorage.getItem('token'));
let access = token?.access;

function getIt(count){

    const token = JSON.parse(localStorage.getItem('token'));
    if(token?.access==undefined && count<1){
        
        getIt(count++);
    }else{
        if(token?.access){
            access = token?.access;
        }
        return;
    }
}


export const login = ( body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            // "Access-Control-Allow-Origin": "*",
            // Accept: 'application/json',
            // "Cache-Control":"no-cache"
        }
    }
    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/auth/login/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err);
        });
    });
}

export const verifyEmail = (body)=>{
    const option = {
        headers:{
            // 'Content-type':"application/json",
            // "Access-Control-Allow-Origin": "*",
            // Accept: 'application/json',
            // "Cache-Control":"no-cache"
        }
    }
    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/auth/forgot_password/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err);
        })
    })
}

export const verifyOtp = (body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            // "Access-Control-Allow-Origin": "*",
            // Accept: 'application/json',
            // "Cache-Control":"no-cache"
        }
    }
    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/auth/email-verification/otp/verify/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const saveCourse = (body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }
    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/web/course/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch(err=>{
            reject(err)
        })
    })
}

export const resetPassword = (body)=>{
    if(access==undefined){
        getIt(0);
    }
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }
    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/auth/password_reset/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch(err=>{
            reject(err)
        })
    })
}

export const saveUserShip = (body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }
    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/web/users/ship_types/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch(err=>{
            reject(err)
        })
    })
}

export const saveJobCategory = (body)=>{
    
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }
    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/web/job/job_category/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch(err=>{
            reject(err)
        })
    })
}

export const saveSubject = (body)=>{
    
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }
    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/web/course/subjects/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch(err=>{
            reject(err)
        })
    })
}

export const saveInstituteOnBoarding = (body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }
    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/web/users/register/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const saveCourseModule = (body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }
    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/web/course/course_modules/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const saveJobRank = (body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/web/job/job_roles/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const saveCreatedCertificate = (body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/web/users/certificate_types/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const saveCertificateExpiry = (body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/web/utilities/todo_notification_schedule/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const saveRankQuestions = (body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/web/utilities/questions/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const saveModuleContentData = (body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/web/course/modules/course_module_docs/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const saveNewsAndAnnouncement = (body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/web/utilities/news_announcements/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const saveAd = (body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/web/utilities/ads/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const saveInstituteCourse = (body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/web/course/course_details/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const RegisterNewUser = (body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/auth/register/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const verifiedEmailCheck =(body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
        }
    }

    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/auth/check_email/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
} 

export const sendUserOtpEmail = (body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
        }
    }

    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/auth/email-onboarding/otp/send/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const verifyUserOtpEmail = (body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
        }
    }

    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/auth/email-onboarding/otp/verify/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
    
}

export const postJob = (body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/web/job/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const saveAdminScheduleCourse = (body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/web/course/course_details/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const saveShipCompany = (body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/web/course/course_details/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const saveShip = (body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/web/users/ship/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const courseRequest = (body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/web/users/ship/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

export const saveFollowup = (body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.post(`${base_url}/api/v1/web/users/follow_up/`,body,option)
        .then((res)=>{
            resolve(res);
        })
        .catch((err)=>{
            reject(err);
        })
    })
}