// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.otpField{
    margin-top: 20px;
    width: 100%;
}
.resetPasswordBtn{
    width: 160px;
        height: 40px;
        background-color: #2A737A;
        border: none;
        border-radius: 5px;
        gap: 10px;
        color: white;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        margin-right: 170px;
        margin-top: 60px;
        cursor: pointer;
        transition: ease-in-out 0.5s;
}.resetPasswordBtn:focus{
    border:none;
    outline:none;
}
.resetPasswordBtn img{
    margin-left: 10px;
}
.resetPasswordBtn h6{
    margin-top: 25px;
    transition: ease-in-out 0.5s
}

`, "",{"version":3,"sources":["webpack://./src/commonAuth/forgotPassword/forgetPass.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,WAAW;AACf;AACA;IACI,YAAY;QACR,YAAY;QACZ,yBAAyB;QACzB,YAAY;QACZ,kBAAkB;QAClB,SAAS;QACT,YAAY;QACZ,aAAa;QACb,mBAAmB;QACnB,6BAA6B;QAC7B,mBAAmB;QACnB,mBAAmB;QACnB,gBAAgB;QAChB,eAAe;QACf,4BAA4B;AACpC,CAAC;IAOG,WAAW;IACX,YAAY;AALhB;AAOA;IACI,iBAAiB;AACrB;AACA;IACI,gBAAgB;IAChB;AACJ","sourcesContent":[".otpField{\n    margin-top: 20px;\n    width: 100%;\n}\n.resetPasswordBtn{\n    width: 160px;\n        height: 40px;\n        background-color: #2A737A;\n        border: none;\n        border-radius: 5px;\n        gap: 10px;\n        color: white;\n        display: flex;\n        flex-direction: row;\n        justify-content: space-around;\n        align-items: center;\n        margin-right: 170px;\n        margin-top: 60px;\n        cursor: pointer;\n        transition: ease-in-out 0.5s;\n}.resetPasswordBtn:focus{\n        border:none;\n        outline:none;\n}\n\n\n.resetPasswordBtn:focus{\n    border:none;\n    outline:none;\n}\n.resetPasswordBtn img{\n    margin-left: 10px;\n}\n.resetPasswordBtn h6{\n    margin-top: 25px;\n    transition: ease-in-out 0.5s\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
