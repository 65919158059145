import React, { useEffect, useState } from 'react'
import { FaCaretDown, FaCloudUploadAlt } from 'react-icons/fa';
import { RxReload } from 'react-icons/rx';
import { RegisterNewUser, saveInstituteOnBoarding, sendUserOtpEmail, verifiedEmailCheck, verifyEmail, verifyUserOtpEmail } from '../../../Apis/postApis';
import { getAllOtherCompanies, getCities, getCountries, getStates } from '../../../Apis/getApis';
import { Link, useNavigate } from 'react-router-dom';
import "./CompanyRegister.css";
import validator from 'validator';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from "react-simple-captcha";
import { errorToast, isValidEmail, successToast, uploadFile, validatePassword } from '../../../utils/helperFunctions/HelperFunctions';
import OTPInput from 'react-otp-input';
import { v4 as uuidV4 } from "uuid";
import "../../../StyleHub//main.css"

import { allowedTypesImages } from '../../../utils/variables/folderStructureFiles';
const CompanyRegister = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [countriesList, setCountriesList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cities, setCitiesList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [terms, setTerms] = useState(false);
    // form fields
    const [buttonName, setButtonName] = useState("Company");
    const [contactPerson, setContactPerson] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [entityName, setEntityName] = useState('');
    const [email, setEmail] = useState('');
    const [websiteName, setWebsiteName] = useState('');
    const [countryName, setCountryName] = useState("Country");
    const [city, setCity] = useState("City");
    const [state, setState] = useState("State");
    const [address, setAddress] = useState('');
    const [otherAddress, setOtherAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [otherPhone, setOtherPhone] = useState('');
    const [image, setImage] = useState('');
    const [countryId, setCountryId] = useState();
    const [stateId, setStateId] = useState();
    const [cityId, setCityId] = useState();
    const [file, setFile] = useState(null);
    const [pincode, setPincode] = useState('');
    const [isPopUp, setIsPopUp] = useState(false);
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const [otp, setOtp] = useState('');
    const navigate = useNavigate();
    const [validCaptcha, setValidCaptch] = useState(false);
    const [captchaValue, setCaptchaValue] = useState('');
    const [companiesData, setCompaniesData] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState();
    const [phoneCode, setPhoneCode] = useState('');

    const [uploading, setUploading] = useState(false);

    const passwordMatch = () => {
        if(password!=='' && !validatePassword(password)){
            errorToast('Password with 1 digit, 1 upper and 1 special');
            setPassword('');
            return;
        }

        if(confirmPassword!=='' && !validatePassword(confirmPassword)){
            errorToast('Password with 1 digit, 1 upper and 1 special');
            setConfirmPassword('');
            return;
        }

        if (password !== "" && password.length <= 3 || password.length >= 16) {
            setPassword("");
            errorToast("Password Must be more than 3 characters and less than 15 characters");
            return;
        }
        if (confirmPassword !== "" && confirmPassword.length <= 3 || confirmPassword.length >= 16) {
            setConfirmPassword('')
            errorToast("ConfirmPassword Must be more than 3 characters and less than 15 characters");
            return;
        }

        if (password !== "" && confirmPassword !== "" && password !== confirmPassword) {
            errorToast("Password and confirm Password not matching");
            setPassword('');
            setConfirmPassword("");
            return;
        }
    }

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (allowedTypesImages.includes(selectedFile?.type)) {
            setFile(selectedFile);
            let res = uploadFile(selectedFile);
            res.then((data) => {
                console.log(data, "uploadedImage")
                setImage(data);
            })
        } else {
            errorToast('Invalid file type. Only images are allowed.');
        }
    };



    const verifyUserEmail = () => {
        console.log(otp, "otp")
        if (otp.length == 6) {
            verifyUserOtpEmail({ email: email, otp: otp })
                .then((res) => {
                    console.log(res)
                    setIsEmailVerified(true);
                    setIsPopUp(false);
                    successToast("Email Verified SuccessFully")
                })
                .catch((err) => {
                    console.log(err);
                    errorToast("Incorrect OTP")
                    setOtp('')
                })
        }

        setOtp('')

    }

    useEffect(() => {
        setWidth(window.innerWidth);
        console.log(window.innerWidth)
    }, [window.innerWidth])

    useEffect(() => {
        if (captchaValue.length == 4) {
            if (validateCaptcha(captchaValue)) {
                successToast("captcha Validated")
                setValidCaptch(true);
            }
            else {
                errorToast("Opps Entered Invalid Captch")
                setCaptchaValue('')
            }
        }
    }, [captchaValue])

    const saveForm = (e) => {
        e.preventDefault();

        if (entityName == '') {
            errorToast(`Please Enter Company Name`);
            return;
        }
        if (contactPerson == '') {
            errorToast("Please Enter Contact person name");
            return;
        }
        if (password == '') {
            errorToast("Please Enter password");
            return;
        }
        if (confirmPassword == '') {
            errorToast("Please Select Confirm the password");
            return;
        }
        if (email == '') {
            errorToast("Please enter email Id");
            return;
        }
        if (websiteName == '') {
            errorToast("Please Enter Website URL");
            return;
        }
        if (countryId == '') {
            errorToast("Please Select the country");
            return;
        }
        if (address == '') {
            errorToast("Please Give address");
            return;
        }
        if (phone == '') {
            errorToast("Please Enter the phone number");
            return;
        }
        if(!validCaptcha){
            errorToast("Please validate the captcha")
        }


        let object = {
            email: email,
            first_name: entityName,
            contact_person: contactPerson,
            website: websiteName,
            mobile_number1: phone,
            mobile_number2: otherPhone,
            address1: address,
            address2: otherAddress,
            state: stateId,
            city: cityId,
            country: countryId,
            pin_code: pincode,
            status: "pending",
            user_type: "company",
            password: password,
            profile_pic: image,
            terms_and_conditions: terms
        }

        if (!validator.isEmail(email)) {
            errorToast("Please enter valid Email");
            return;
        }


        if (!validator.isURL(websiteName)) {
            errorToast("Please enter valid url");
            return;
        } else {

            RegisterNewUser(object)
                .then((res) => {
                    successToast("Company Added successfully")
                    navigate('/onboarding-company')
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    const getCountryById = (id) => {
        for (let i = 0; i < countriesList.length; i++) {
            if (countriesList[i]?.id == id) {
                return countriesList[i];
            }
        }
    }

    const getStateById = (id) => {
        for (let i = 0; i < stateList.length; i++) {
            if (stateList[i]?.id == id) {
                return stateList[i];
            }
        }
    }

    const getCityById = (id) => {
        for (let i = 0; i < cities.length; i++) {
            if (cities[i]?.id == id) {
                return cities[i];
            }
        }
    }


    function validUrl() {
        if (websiteName !== '') {
            if (!validator.isURL(websiteName)) {
                errorToast("Please enter valid url");
                setWebsiteName('');
                return;
            }
        }
    }

    const sendEmail = (e) => {
        e.preventDefault();
        if (email !== "") {
            if (isValidEmail(email)) {
                verifiedEmailCheck({ email: email })
                    .then((res) => {
                        console.log(res)
                        if (res?.data?.data == 1) {
                            setIsPopUp(!isPopUp)
                            sendUserOtpEmail({ email: email })
                                .then((res) => {
                                    console.log(res)
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        } else {
                            errorToast("Email Already Exist")
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } else {
                errorToast("Email is Invalid")
            }
        } else {
            errorToast("Please enter the email")
        }

    }

    const makeCountry = (item) => {
        if (!item) {
          setCountryName("");
          setPhoneCode("");
          setCountryId("");
          return;
        }
        setCountryName((prev) => prev = item.name);
        setPhoneCode(item?.phone_code);
        setCountryId(item?.id);
        getStates(item.id)
            .then((res) => {
                setStateList(res?.data?.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    const makeState = (item) => {
        if(!item){
            setState("State");
            setStateId();
            return;
        }
        setState(item.name);
        setStateId(item?.id)
        setState((prev) => prev = item.name)
        getCities(item.id)
            .then((res) => {
                setCitiesList(res?.data?.data)

            })
            .catch((err) => {
                console.log(err)
            })

    }
    const makeCity = (item) => {
        setCityId(item?.id);
        setCity((prev) => prev = item?.name)
    }

    useEffect(() => {
        setLoading(true);
        loadCaptchaEnginge(4)
        getCountries()
            .then((res) => {
                setLoading(false);
                setCountriesList(res?.data?.data)
            })
            .catch((err) => {
                setLoading(false);
                console.log(err)
            })
    }, [])

    useEffect(() => {
        let timer;
        if (entityName !== '') {
            timer = setTimeout(() => {

                getAllOtherCompanies(entityName)
                    .then((res) => {
                        let data = res?.data?.data?.results;
                        data = data.filter((item) => { return item?.name.toLocaleLowerCase().includes(entityName.toLocaleLowerCase()) })
                        console.log(res, data, 'otherCompanies')
                        setCompaniesData(data)
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }, 400)
        } else {
            setCompaniesData([])
        }

        return (() => {
            clearTimeout(timer)
        })

    }, [entityName])

    const handleCompanySelect = (item) => {
        setEntityName(item?.name);
        setSelectedCompany(item);
        setCompaniesData([]);
    };

    return (
        <div
        className="boarding-form company-registration-form-container md:px-6 px-0 pb-8"
        style={{
          marginBottom: "20px",
          display: "flex",
          flexDirection: "column",
          overflow: "scroll",
          position: "fixed",
        }}
      >
        <div
          className="instituteOnboarding-list-view p-10"
          style={{ maxHeight: "150px", marginTop: "0px" }}
        >
          <div
            className="headerPart"
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "2px",
            }}
          >
            <center className="flex flex-column justify-center gap-y-2 h-14 my-8 w-auto">
              <Link to="https://sailorshub.us.tempcloudsite.com/">
                <img
                  src="/assets/authAssets/Sailors-Hub-logo.gif"
                  alt="logo"
                  className="w-60 h-14 object-contain  mx-auto"
                  style={{ filter: "drop-shadow(10px 10px 5px #999)" }}
                />
              </Link>
              <span>Register As Institute</span>
            </center>
          </div>

          <form className="container mt-5 p-4 bg-white shadow rounded-2xl h-auto mb-5">
            {/* <span className='mustStar h-25' style={{ textAlign: 'end', fontSize: "15px", position: 'absolute', top: "-20px", left: "-16px" }}>Fields marked (*) are mandatory.</span> */}
            <div className="row mb-3 d-flex flex-row justify-content-around companyFormDiv space-y-2 md:space-y-0">
              <div className="col-md-5 col-lg-5 col-sm-9 col-xs-12 h-50">
                {/* <label className="form-label" htmlFor="instituteName">
                            Company Name<span className='mustStar'>*</span>
                        </label> */}
                <input
                  placeholder="Company Name"
                  required
                  id="instituteName"
                  value={entityName}
                  onChange={(e) => setEntityName(e.target.value)}
                  type="text"
                  className=" sailors-Input-field"
                />
              </div>
              <div className="col-md-5 col-lg-5 col-sm-9 col-xs-12 h-50">
                {/* <label className="form-label" htmlFor="contactPerson">
                            Contact Person<span className='mustStar'>*</span>
                        </label> */}
                <input
                  placeholder="Contact Person"
                  required
                  id="contactPerson"
                  type="text"
                  value={contactPerson}
                  onChange={(e) => setContactPerson(e.target.value)}
                  className=" sailors-Input-field"
                />
              </div>
            </div>

            <div className="row mb-1 d-flex flex-row justify-content-around companyFormDiv">
              <div className="col-md-5 col-lg-5 col-sm-9 col-xs-12 h-50">
                {/* <label className="form-label" htmlFor="password">
                            Password<span className='mustStar'>*</span>
                        </label> */}
                <input
                  placeholder="Password"
                  required
                  id="password"
                  value={password}
                  onBlur={() => passwordMatch()}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  className=" sailors-Input-field"
                />
                <p
                  className="mt-0 ml-1"
                  style={{ fontSize: "10px", color: "#76767680" }}
                >
                  Password with 1 digit, 1 upper and 1 special
                </p>
              </div>
              <div className="col-md-5 col-lg-5 col-sm-9 col-xs-12 h-50">
                {/* <label className="form-label" htmlFor="confirmPassword">
                            Confirm Password<span className='mustStar'>*</span>
                        </label> */}
                <input
                  placeholder="Confirm Password"
                  required
                  id="confirmPassword"
                  value={confirmPassword}
                  onBlur={() => passwordMatch()}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  type="password"
                  className=" sailors-Input-field"
                />
                <p
                  className="mt-0 ml-1"
                  style={{ fontSize: "10px", color: "#76767680" }}
                >
                  Password with 1 digit, 1 upper and 1 special
                </p>
              </div>
            </div>

            <div className="row mb-3 d-flex flex-row justify-content-around companyFormDiv space-y-2 md:space-y-0">
              <div className="col-md-5 col-lg-5 col-sm-9 col-xs-12 h-50">
                {/* <label className="form-label" htmlFor="instituteName">
                            Pin Code
                        </label> */}
                <input
                  id="instituteName"
                  placeholder="Pin Code"
                  value={pincode}
                  onChange={(e) => setPincode(e.target.value)}
                  type="number"
                  className=" sailors-Input-field"
                />
              </div>
              <div className="col-md-5 col-lg-5 col-sm-9 col-xs-12 h-50">
                {/* <label className="form-label" htmlFor="email">
                            Email<span className='mustStar'>*</span>
                        </label> */}
                <input
                  placeholder="email"
                  required
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  className="sailors-Input-field"
                />
                <button
                className="btn button-88 text-sm top-1"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                type="button"
                disabled={isEmailVerified ? true : false}
                onClick={(e) => {
                  sendEmail(e);
                }}
                style={{
                  borderRadius: "5px",
                  width: "auto",
                  maxHeight: "35px",
                  position: "absolute",
                  right: "25px",
                }}
              >
                {isEmailVerified ? <img src='/assets/authAssets/check-mark.gif' className='size-12' alt='verified'/> : "Verify"}
              </button>
              </div>
            </div>

            <div className="row mb-3 d-flex flex-row justify-content-around companyFormDiv space-y-2 md:space-y-0">
              <div className="col-md-5 col-lg-5 col-sm-9 col-xs-12 h-50">
                {/* <label className="form-label" htmlFor="websiteName">
                            Website URL<span className='mustStar'>*</span>
                        </label> */}
                <input
                  placeholder="Website URL"
                  id="websiteName"
                  value={websiteName}
                  onBlur={validUrl}
                  onChange={(e) => setWebsiteName(e.target.value)}
                  type="text"
                  className="sailors-Input-field"
                />
              </div>
              <div className="col-md-5 col-lg-5 col-sm-9 col-xs-12 h-50">
                {/* <label className="form-label" htmlFor="country">
                            Country<span className='mustStar'>*</span>
                        </label> */}
                <select
                  id="country"
                  style={{ minHeight: "40px" }}
                  value={countryId}
                  onChange={(e) => makeCountry(getCountryById(e.target.value))}
                  className="form-select sailors-Input-field"
                >
                  <option value="">Select Country</option>
                  {countriesList?.map((item) => {
                    return (
                      <option
                        value={item?.id}
                        key={uuidV4()}
                        className="dropdown-item"
                        style={{ height: "50px" }}
                      >
                        {item?.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="row mb-3 d-flex flex-row justify-content-around companyFormDiv space-y-2 md:space-y-0">
              <div className="col-md-5 col-lg-5 col-sm-9 col-xs-12 h-50">
                {/* <label className="form-label" htmlFor="state">
                            State
                        </label> */}
                <select
                  id="state"
                  style={{ minHeight: "40px" }}
                  value={stateId}
                  onChange={(e) => makeState(getStateById(e.target.value))}
                  className="form-select sailors-Input-field"
                >
                  <option>Select State</option>
                  {stateList?.map((item) => {
                    return (
                      <option
                        value={item.id}
                        key={uuidV4()}
                        className="dropdown-item"
                        style={{ height: "50px" }}
                      >
                        {item?.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-5 col-lg-5 col-sm-9 col-xs-12 h-50">
                {/* <label className="form-label" htmlFor="city">
                            City
                        </label> */}
                <select
                  id="city"
                  value={cityId}
                  style={{ minHeight: "40px" }}
                  onChange={(e) => makeCity(getCityById(e.target.value))}
                  className="form-select sailors-Input-field"
                >
                  <option>Select City</option>
                  {cities?.map((item) => {
                    return (
                      <option
                        value={item?.id}
                        key={uuidV4()}
                        className="dropdown-item"
                        style={{ height: "50px" }}
                      >
                        {item?.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="row mb-3 d-flex flex-row justify-content-around companyFormDiv space-y-2 md:space-y-0">
              <div className="col-md-5 col-lg-5 col-sm-9 col-xs-12 h-50">
                {/* <label className="form-label" htmlFor="address">
                            Address<span className='mustStar'>*</span>
                        </label> */}
                <textarea
                  rows="3"
                  id="address"
                  placeholder="Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  type="text"
                  className="form-control sailors-Input-field"
                />
              </div>
              <div className="col-md-5 col-lg-5 col-sm-9 col-xs-12 h-50">
                {/* <label className="form-label" htmlFor="otherAddress">
                            Other Address
                        </label> */}
                <textarea
                  rows="3"
                  id="otherAddress"
                  placeholder="Other Address"
                  value={otherAddress}
                  onChange={(e) => {
                    setOtherAddress(e.target.value);
                  }}
                  type="text"
                  className="form-control sailors-Input-field"
                />
              </div>
            </div>

            <div className="row mb-3 d-flex flex-row justify-content-around companyFormDiv space-y-2 md:space-y-0">
            <div className="col-md-5 col-lg-5 col-sm-9 col-xs-12 h-50">
              {/* <label className="form-label" htmlFor="contactNumber">
                            Contact Number<span className='mustStar'>*</span>
                        </label> */}
              <div className="relative flex flex-row items-center">
                <div
                  className={`mx-auto w-12 bg-slate-200 h-auto text-xs absolute left-2 py-2 ${phoneCode!=="" ? "px-1" : "px-2"} rounded-md`}
                  style={{ width: "auto", textAlign: "center" }}
                >
                  {" "}
                  {phoneCode !== "" ? phoneCode : "📞"}
                </div>
                <input
                  id="contactNumber"
                  placeholder="Contact Number"
                  type="number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="sailors-Input-field rounded-s-none focus:outline-none placeholder:text-base pl-5 ml-1"
                  style={{borderTopLeftRadius:"none"}}
                />
              </div>
            </div>

            <div className="col-md-5 col-lg-5 col-sm-9 col-xs-12 h-50">
              {/* <label className="form-label" htmlFor="otherContactNumber">
                            Other Contact Number
                        </label> */}
              <div className="relative flex flex-row items-center">
              <div
                  className={`mx-auto w-12 bg-slate-200 h-auto text-xs absolute left-2 py-2 ${phoneCode!=="" ? "px-1" : "px-2"} rounded-md`}
                  style={{ width: "auto", textAlign: "center" }}
                >
                  {" "}
                  {phoneCode !== "" ? phoneCode : "📞"}
                </div>
                <input
                  value={otherPhone}
                  placeholder="Other Contact Number"
                  onChange={(e) => setOtherPhone(e.target.value)}
                  id="otherContactNumber"
                  type="number"
                  className="sailors-Input-field text-sm rounded-s-none focus:outline-none placeholder:text-base pl-5 ml-1"
                />
              </div>
            </div>
          </div>

            <div className="row mb-3 d-flex flex-row justify-content-around items-center companyFormDiv">
                        <div className="col-md-5 col-lg-5 col-sm-9 col-xs-12 h-50">
                          <label
                            className="form-label h-50  flex flex-row"
                            htmlFor="uploadLogo"
                          >
                            <span>
                              Upload Logo{" "}
                            </span>
                            <FaCloudUploadAlt
                              style={{ width: "30px", marginLeft: "10px" }}
                            />
                          </label>
                        </div>
                        <div className="col-md-5 col-lg-5 col-sm-9 col-xs-12 h-50">
                           <input
                              id="uploadLogo"
                              type="file"
                              onChange={(e) => handleFileChange(e)}
                              className="sailors-Input-field flex items-center py-2 px-2"
                            />
                        </div>
                      </div>

            <div className="row mb-3 d-flex flex-row justify-content-around companyFormDiv space-y-2 md:space-y-0">
              <div className="col-md-5 col-lg-5 col-sm-9 col-xs-12 h-50">
                <div className=" h-25 selectedField ntb2" htmlFor="uploadLogo">
                  <LoadCanvasTemplate
                    reloadText="Reload captcha"
                    reloadColor="red"
                  />
                </div>
              </div>
              <div className="col-md-5 col-lg-5 col-sm-9 col-xs-12 h-50">
                <div className="input-group">
                  <input
                    type="text"
                    value={captchaValue}
                    onChange={(e) => setCaptchaValue(e.target.value)}
                    className=" sailors-Input-field"
                    maxLength={4}
                    placeholder="Enter Captcha"
                  />
                </div>
              </div>
            </div>

            <div
            className="flex md:flex-row flex-col justify-center items-center gap-x-2 gap-y-2 md:gap-y-2"
            style={{
              marginBottom: "20px",
              marginTop: "50px",
              maxHeight: "50px",
            }}
          >
            <button
              type="submit"
              onClick={(e) => saveForm(e)}
              className="py-1 rounded-md w-10 bg-[#005A80]"
              style={{border:'1px solid #005A80'}}
            >
              Save
            </button>
            <button
              type="button"
              onClick={() => {
                navigate("/login");
              }}
              style={{border:'1px solid #005A80'}}
              className=" py-1 w-auto border-[#005A80] border-2 px-1 rounded-md"
            >
              Go TO Login
            </button>
          </div>
          </form>
        </div>
        {/* <form className='animate__animated animate__fadeIn main-form' style={{ maxWidth: "50%", paddingLeft: "30px", margin: "auto", marginBottom: "100px", paddingTop: "20px", background: '#ffffff' }}>
                <div className={width < 500 ? " col" : " row"} style={{ width: "100%", gap: '8px', maxHeight: "60px",marginBottom:"19px" }}>
                    <div className={width < 500 ? 'form-group selectedField-row row' : 'form-group selectedField col'} style={{ paddingTop: "7px",display:'flex',flexDirection:'column' }}>
                        <input type="text" className="form-control sailors-Input-field" onBlur={()=>{entityName==""&&setCompaniesData([])}} value={entityName} onChange={(e) => setEntityName(e.target.value)} placeholder="Company Name" style={{ marginLeft: '-9px' }} />
                        
                        {companiesData.length > 0 && (
                            <div className='list dropdown-menu show' style={{ width: "100%", maxHeight: "400px", overflowY: 'scroll', height: 'auto' }}>
                                {companiesData.length > 0 && companiesData.map((item) => {
                                    return (
                                        <div key={item?.id} className="dropdown-item" style={{ height: "50px", cursor: "pointer" }} onClick={() => handleCompanySelect(item)}>{item?.name}</div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                    
                    <div className={width < 500 ? 'form-group row' : 'form-group col'}>
                        <input type="text" value={contactPerson} onChange={(e) => setContactPerson(e.target.value)} className="form-control sailors-Input-field" placeholder="Contact Person" style={{ marginTop: "5px" }} />
                    </div>
                </div>

                <div className={width < 500 ? "form-group col" : "form-group row"} style={{ marginRight: "20px", gap: "25px", maxHeight: "60px" }}>
                    <div className={width < 500 ? 'form-group row' : 'form-group col'}>
                        <input type="password" value={password} onChange={(e) => { setPassword(e.target.value) }} className="form-control sailors-Input-field" placeholder="Password" />
                    </div>
                    <div className={width < 500 ? 'form-group row' : 'form-group col'}>
                        <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} className="form-control sailors-Input-field" placeholder="Confirm Password" />
                    </div>
                </div>

                <div className={width < 500 ? "form-group col" : "form-group row"} style={{ marginRight: "20px", gap: "25px", maxHeight: "60px" }}>
                    <div className={width < 500 ? 'form-group row' : 'form-group col'}>
                        <input type="text" value={websiteName} onChange={(e) => setWebsiteName(e.target.value)} className="form-control sailors-Input-field" placeholder="Website Name" />
                    </div>
                    <div className={width < 500 ? 'form-group row' : 'form-group col'} style={{display:'flex', flexDirection:'row'}}>
                        <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control sailors-Input-field" placeholder="Email" />
                        <button className='btn button-88' data-bs-toggle="modal" data-bs-target="#exampleModal" type="button" onClick={(e) => { sendEmail(e) }} style={{ borderRadius: '5px', width: "150px", marginLeft: "5px", maxHeight: "45px" }}>Verify Email</button>
                    </div>
                </div>

                <div className={width < 500 ? "form-group col" : "form-group row"} style={{ marginRight: "-41px", gap: "35px", maxHeight: "60px" }}>
                    <div className={width < 500 ? 'form-group row' : 'form-group col'}>
                        <input type="text" value={pincode} onChange={(e) => setPincode(e.target.value)} className="form-control sailors-Input-field" placeholder='Pincode' />
                    </div>
                    <div className={width < 500 ? 'form-group row' : 'form-group col'} style={{ marginRight: '-18px' }}>
                        <button type="button" className={width < 500 ? "btn drdpbtn col sailors-Input-field" : "btn sailors-Input-field drdpbtn row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white" }}>
                            {countryName}<FaCaretDown style={{ width: "15px" }} />
                        </button>
                        <div className="dropdown-menu" style={{ width: "300px", height: "200px", overflowY: 'scroll' }}>
                            {countriesList?.map((item, index) => {
                                return (
                                    <div className="dropdown-item" style={{ height: "50px" }} onClick={() => makeCountry(item)}>{item?.name}</div>
                                )
                            })}
                        </div>
                    </div>
                </div>

                <div className={width < 600 ? "form-group col" : "form-group row"} style={{ maxHeight: "60px" }}>
                    <div className={width < 600 ? 'form-group selectedField-row row' : 'form-group selectedField col'} style={{ marginLeft: "14px", maxWidth: "812px" }}>
                        <button type="button" className={width < 500 ? "btn drdpbtn col sailors-Input-field" : "btn sailors-Input-field drdpbtn row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white" }}>
                            {state}<FaCaretDown style={{ width: "15px" }} />
                        </button>
                        <div className="dropdown-menu" style={{ width: "500px", height: "200px", overflowY: 'scroll' }}>
                            {stateList?.map((item, index) => {
                                return (
                                    <div className="dropdown-item" style={{ height: "50px" }} onClick={() => makeState(item)}>{item?.name}</div>
                                )
                            })}
                        </div>
                    </div>
                    <div className={width < 600 ? 'form-group row' : 'form-group col'} style={{ marginRight: "-16px", marginLeft: "25px", maxHeight: "60px" }}>
                        <button type="button" className={width < 500 ? "btn drdpbtn col sailors-Input-field" : "btn sailors-Input-field drdpbtn row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white" }}>
                            {city}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<FaCaretDown style={{ width: "15px" }} />
                        </button>
                        <div className="dropdown-menu" style={{ width: "500px", height: "200px", overflowY: 'scroll' }}>
                            {cities?.map((item, index) => {
                                return (
                                    <div className="dropdown-item" style={{ height: "50px" }} onClick={() => makeCity(item)}>{item?.name}</div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className={width < 600 ? "form-group col" : "form-group row"} style={{ maxHeight: "190px" }}>


                    <div className={width < 600 ? "form-group col" : "form-group row"} style={{ gap: "1px", maxHeight: "60px" }}>
                        <div className={width < 600 ? 'form-group selectedField-row  row' : 'form-group selectedField ntb col'}>
                            <input type="text" className="form-control sailors-Input-field" value={address} onChange={(e) => setAddress(e.target.value)} placeholder="Address" style={{ padding: "0px 10px 0px 10px", margin: "0px -12px 0px -14px" }} />
                        </div>
                        <div className={width < 600 ? 'form-group row' : 'form-group col'} style={{ maxHeight: "50px" }}>
                            <input type="text" className="form-control sailors-Input-field" value={otherAddress} onChange={(e) => setOtherAddress(e.target.value)} placeholder="Other Address" style={{ marginLeft: "10px" }} />
                        </div>
                    </div>

                    <div className={width < 600 ? "form-group col" : "form-group row"} style={{maxHeight:"60px"}}>
                        <div className={width < 600 ? 'form-group selectedField-row row' : 'form-group selectedField col'}>
                            <form className="form-inline" style={{maxHeight:"60px"}}>
                                <div className="input-group">
                                    <div className="input-group-prepend" style={{ maxWidth: "35px", height: "46px", maxHeight: "50px" }}>
                                        <div className="input-group-text">📞</div>
                                    </div>
                                    <input type="number" value={phone} onChange={(e) => setPhone(e.target.value)} className="form-control numberField sailors-Input-field" id="inlineFormInputGroupUsername2" placeholder="Contact Number" />
                                </div>
                            </form>
                        </div>
                        <div className={width < 600 ? 'form-group row' : 'form-group col'}>
                            <form className="form-inline" style={{maxHeight:"60px"}}>
                                <div className="input-group">
                                    <div className="input-group-prepend" style={{ maxWidth: "35px", height: "46px", maxHeight: "50px" }}>
                                        <div className="input-group-text">📞</div>
                                    </div>
                                    <input type="number" value={otherPhone} onChange={(e) => setOtherPhone(e.target.value)} className="form-control sailors-Input-field numberField" id="inlineFormInputGroupUsername2" placeholder="Other Contact Number" />
                                </div>
                            </form>
                        </div>
                        <div className={width < 600 ? 'form-group row' : 'form-group col'} style={{ maxHeight: "30px" }}>
                            <form className="form-inline">
                                <div className="input-group">
                                    <input type="file" className='uploadLogo-Input' onChange={handleFileChange} placeholder='Images' />
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
                <div className={width < 600 ? "form-group col" : "form-group row"} style={{ marginLeft: "-12px", maxHeight: "60px" }}>
                    <div className={width < 600 ? 'form-group selectedField-row  row' : 'form-group selectedField ntb2 col'}>
                        < LoadCanvasTemplate reloadText="Reload My Captcha" reloadColor="red" />
                    </div>
                    <div className={width < 600 ? 'form-group row' : 'form-group col'} style={{ maxHeight: "50px", marginRight: "10px", marginLeft: '14px' }}>
                        <input type="text" value={captchaValue} onChange={(e) => setCaptchaValue(e.target.value)} className="form-control sailors-Input-field" maxLength={4} placeholder="Enter Captcha" style={{ marginLeft: "10px" }} />
                    </div>
                </div>

                <div className={width < 600 ? "form-group col" : "form-group row"} style={{ marginLeft: "-12px", maxHeight: "20px", display:'flex', flexDirection:'row',width:"100%",justifyContent:'start',alignItems:'center' }}>
                        <input type='checkbox' value={terms} onChange={(e)=>setTerms(e.target.checked)} style={{width:'20px',cursor:'pointer' }}/>
                        <label style={{width:"300px",marginLeft:"10px"}}>Accept Terms and conditions</label>
                </div>


                <div className='form-btns' style={{ maxHeight: "50px", marginBottom: "30px" }}>
                    <button type="submit" disabled={validCaptcha==false && terms==true ? false : true} onClick={saveForm} className="btn">Register</button>
                    <button className="btn btn-OnboardForm" onClick={()=>{navigate("/login")}} style={{ maxWidth: "200px", width: 'auto' }}>Go To Login</button>
                </div>

            </form> */}

        {isPopUp && (
          <div className="verifyEmailPopUp" style={{ position: "absolute" }}>
            <div class="modal-dialog" style={{ height: "auto" }}>
              <div class="modal-content">
                <div class="modal-header" style={{ margin: "auto" }}>
                  <center>
                    <h5 class="modal-title" id="exampleModalLabel">
                      Verify Email 📧
                    </h5>
                  </center>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <center>
                    <h5>Your Email OTP is sent on Email</h5>
                  </center>
                  <div>
                    <OTPInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      renderSeparator={<b>&nbsp;&nbsp;&nbsp;&nbsp;</b>}
                      renderInput={(props) => <input {...props} />}
                      inputStyle={{
                        width: "33px",
                        height: "33px",
                        boxShadow:
                          "inset 1px 2px 4px rgba(0, 0, 0, 0.01), 0px 0px 8px rgba(0, 0, 0, 0.2)",
                        borderRadius: "5px",
                      }}
                      containerStyle={{ display: "flex", alignItems: "center" }}
                    />
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => setIsPopUp(!isPopUp)}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn button-88"
                    style={{
                      borderRadius: "10px",
                      textAlign: "center",
                      margin: "auto",
                    }}
                    onClick={verifyUserEmail}
                  >
                    Verify
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
}

export default CompanyRegister;
