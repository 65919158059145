// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-course-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.drdpbtn-JobCategory{
    max-width: 608px;
}`, "",{"version":3,"sources":["webpack://./src/Admin/pages/masters/Job_category/jobcategory.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;AACA;IACI,gBAAgB;AACpB","sourcesContent":[".add-course-container{\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n}\n.drdpbtn-JobCategory{\n    max-width: 608px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
