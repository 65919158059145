import axios from 'axios';
import { base_url } from '../utils/endpoints/endpoints';

const axiosInstance = axios.create({
    baseURL: base_url,
    headers: {
      'Content-type': 'application/json',
    },
  });

  axiosInstance.interceptors.request.use(config => {
    const token = JSON.parse(localStorage.getItem('token'));
    const access = token?.access;
    if (access) {
      config.headers['Authorization'] = `Bearer ${access}`;
    }
    return config;
  });

export const searchCourseApi = async (type)=>{
    try{
        const response = await(axiosInstance.get(`/api/v1/web/course/?search=${type}`))
        return response;
    }catch(error){
        throw error;
    }
}   