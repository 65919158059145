import React, { useEffect, useState } from 'react'
import { allowedTypes } from '../../../utils/variables/folderStructureFiles';
import "./otherInfo.css";
import { getAllCompanyShip, getAllOperationType, getShipTypeList } from '../../../Apis/getApis';
import {saveShip} from '../../../Apis/postApis'
import { deleteToast, errorToast, successToast, uploadFile } from '../../../utils/helperFunctions/HelperFunctions';
import { FaPen } from 'react-icons/fa';
import { RiDeleteBin6Line } from 'react-icons/ri';
import Swal from 'sweetalert2';
import { deleteShipOfCompany } from '../../../Apis/deleteApis';
import { BallTriangle } from 'react-loader-spinner';
import { updateShipOfCompany } from '../../../Apis/updateApis';
const CompanyOtherInfo = () => {
    const [shipName, setShipName] = useState('');
    const [shipType, setShipType] = useState('');
    const [otherShipType, setOtherShipType] = useState('');
    const [operationType,setOperationType] = useState('');
    const [operationTypeList,setOperationTypeList] = useState([]);
    const [shipList,setShipList] = useState([]);
    const [fromYear,setFromYear] = useState();
    const [shipCapacity,setShipCapaty] = useState('');
    const [image,setImage] = useState('');
    const [isEdit, setIsEdit] = useState();
    const [isOther,setIsOther] = useState(false);
    const [shipData,setShipData] = useState([]);
    const [selectedItem,setSelectedItem] = useState();
    const [loading,setLoading] = useState(false);

    useEffect(()=>{
        console.log(shipData,"shipData")
    },[shipData])

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (allowedTypes.includes(selectedFile?.type)) {
            
            let res = uploadFile(selectedFile);
            res.then((data) => {
                setImage(data);
            })
        } else {
            alert('Invalid file type. Only images, videos, audios and PDFs are allowed.');
        }
    };

    const editData = (item)=>{
        setIsEdit(true)
        setSelectedItem(item);
        setOperationType(item?.operation_type);
        setShipName(item?.name);
        setShipType(item?.ship_type);
        setFromYear(item?.established_year)
        setImage(item?.image)
        setShipCapaty(item?.capacity)

    }

    const handleCancel = () => {
        setIsEdit(false)
        setSelectedItem();
        setOperationType('');
        setShipName('');
        setShipType('');
        setFromYear('')
        setImage()
        setShipCapaty('');

    }

    const checkYear = ()=>{
        const date = new Date();
        const year = date.getFullYear();

        if(year<fromYear){
            errorToast("ESTD year Cannot be greater than current year ");
            setFromYear('')
            return;
        }
    }

    const deleteData = (id) => {

        Swal.fire({
            title: "Are you sure?",
            text: "Do you want to delete Ship?",
            icon: "error",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Delete Ship!"
        }).then((result) => {
            if (result.isConfirmed) {
                deleteShipOfCompany(id)
                    .then((res) => {
                        console.log(res);
                        getShipData();
                        deleteToast("Ship is deleted.");
                    })
                    .catch((err) => {
                        console.log(err);
                    })
            } else {
                setSelectedItem();
            }

        });

    }


    const handleSubmit = (e) => {
        e.preventDefault();
        if(shipName==""){
            errorToast("Ship Name Missing")
            return; 
        }
        if(shipType==""){
            errorToast("Ship Type Missing")
            return;
        }
        if(operationType==''){
            errorToast("Ship Operation Type Missing")
            return;
        }
        if(fromYear==''){
            errorToast("Please Enter the Establishment year")
            return;
        }
        if(shipCapacity==''){
            errorToast("Enter Ship Capacity")
            return;
        }
        if(!isEdit){
            saveShip({
                name:shipName,
                ship_type:shipType,
                operation_type:operationType,
                established_year:fromYear,
                capacity:shipCapacity,
                image:image,
            })
            .then((res)=>{
                console.log(res);
                getShipData();
                successToast("Ship Created");
                handleCancel()
            })
            .catch((err)=>{
                console.log(err)
            })
            
        }else{

            updateShipOfCompany(selectedItem.id,{
                    name:shipName,
                    ship_type:shipType,
                    operation_type:operationType,
                    capacity:shipCapacity,
                    established_year:fromYear,
                    image:image,
            })
            .then((res)=>{
                console.log(res);
                getShipData();
                handleCancel();
            })
            .catch((err)=>{
                console.log(err)
            })
        }


    }


    function getShipData(){
        getAllCompanyShip()
        .then((res)=>{
            setShipData(res?.data?.data?.results);
        })
        .catch((err)=>{
            console.log(err)
        })
        
      }

      useEffect(()=>{
        if(shipType=="other"){
            setIsOther(true);
         }else{
            setIsOther(false);
         }
      },[shipType])
      
      useEffect(()=>{
        getShipData();

        getShipTypeList()
        .then((res)=>{
          console.log(res,"shipList")
          setShipList(res?.data?.data?.results)
        })
        .catch((err)=>{
          console.log(err)
        })

        getAllOperationType()
            .then((res) => {
                console.log(res, "job Category");
                setOperationTypeList(res?.data?.data)
            })
            .catch((err) => {
                console.log(err)
            })
      },[])


    return (
        <div className='institute-container animate__animated animate__fadeIn' style={{ transition: 'ease-in-out 250ms' }}>
            <div className='instituteOnboarding-list-view'>
                <div className='headerPart'>
                    <h4>Ship Details</h4>
                </div>
            </div>
            <div className="p-4 bg-white shadow rounded-lg" style={{borderRadius:"20px"}}>
                <form className='gap-5'>
                    <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Ship Name</label>
                        <div className="col-sm-9">
                            <input value={shipName} onChange={(e)=>setShipName(e.target.value)} type="text" className="form-control sailors-Input-field" placeholder="Enter ship name" />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Ship Type</label>
                        <div className="col-sm-9">
                            <select className="form-select sailors-Input-field" value={shipType}
                                onChange={(e)=>setShipType(e.target.value)}
                                
                            >
                                <option>Select Ship Type</option>
                                {shipList?.map((item)=>{
                                    return(
                                        <option value={item?.id}>{item?.name}</option>
                                    ) 
                                })}
                                <option value='other'>
                                   Other
                                </option>
                                
                            </select>
                        </div>
                    </div>
                    {isOther && <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Other Ship Type</label>
                        <div className="col-sm-9">
                            <input type="text" value={otherShipType} onChange={(e)=>setOtherShipType(e.target.value)} className="form-control sailors-Input-field" placeholder="Enter Other Ship Type Title" />
                        </div>
                    </div>}
                    
                    <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Operation type</label>
                        <div className="col-sm-9">
                            <select className="form-select sailors-Input-field" value={operationType} onChange={(e)=>setOperationType(e.target.value)}>
                                <option>Select Operation Type</option>
                                {operationTypeList.map((item)=>{
                                    return(
                                        <option value={item.id}>{item?.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">ESTD year</label>
                        <div className="col-sm-9">
                            <input type="number"  onBlur={()=>checkYear()} value={fromYear} onChange={(e)=>setFromYear(e.target.value)} className="form-control sailors-Input-field" placeholder="Enter year" />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Ship Capacity</label>
                        <div className="col-sm-9">
                            <input type="number" value={shipCapacity} onChange={(e)=>setShipCapaty(e.target.value)} className="form-control sailors-Input-field" placeholder="Enter Capacity" />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Upload Image</label>
                        <div className="col-sm-9">
                            <input type="file" onChange={handleFileChange}/>
                        </div>
                    </div>
                    
                    
                    <div className='form-btns' style={{ marginBottom: "20px", marginTop: "50px" }}>
                    <button type="submit" onClick={handleSubmit} className="btn">{isEdit ? "Update" : "Save"}</button>
                    <button type='button' onClick={handleCancel} className="btn btn-OnboardForm" style={{border:"1px solid #005A80"}}>Cancel</button>
                </div>
                </form>
            </div>

            {loading ?
                    <div style={{ maxWidth: "100px", maxHeight: "100px" }}>

                        <BallTriangle
                            height={20}
                            width={20}
                            radius={5}
                            color="red"
                            ariaLabel="ball-triangle-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                        <span>Loading...</span>
                    </div>

                    : <div className='table-container'>

                        {
                            <React.Fragment>
                                {shipData.length > 0 ?
                                    <div className='table-Data'>
                                        <table className='table'>
                                            <thead>
                                                <tr style={{ display: 'flex', justifyContent: "flex-start" }}>
                                                    <th style={{ fontWeight: "700", maxWidth: "80px", color:"#005A80" }}>Sr No.</th>
                                                    <th style={{ fontWeight: "700", textAlign: 'start', color:"#005A80" }}>Ship Name</th>
                                                    <th style={{ fontWeight: "700", textAlign: 'start', color:"#005A80" }}>Ship Type</th>
                                                    <th style={{ fontWeight: "700", textAlign: 'start', color:"#005A80" }}>Operation</th>
                                                    <th style={{ fontWeight: "700", textAlign: 'start', color:"#005A80" }}>ESTD. year</th>
                                                    <th style={{ fontWeight: "700", color:"#005A80" }}>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {shipData.map((item, index) => {
                                                return (
                                                    <tr className='' style={{ display: 'flex', justifyContent: "space-around", borderBottom:'1px solid #00000099' }}>
                                                        <th scope="row">{index + 1}</th>
                                                        <td style={{ textAlign: 'start' }} >{item?.name}</td>
                                                        <td style={{ textAlign: 'start' }} >{item?.ship_type || item?.other_ship_type_name	}</td>
                                                        <td style={{ textAlign: 'start' }} >{item?.operation_type}</td>
                                                        <td style={{ textAlign: 'start' }} >{item?.established_year}</td>
                                                        
                                                        <td >
                                                            <div style={{ cursor: "pointer", display: 'flex', flexDirection: "row", justifyContent: 'start' }}>
                                                              
                                                                    <FaPen onClick={() => editData(item)} data-toggle="modal" data-target="#instituteDetailsModal" title='Details' style={{ width: "30px", color: "#0CB1C0", border: "1px solid gray", padding: "5px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px" }} /> <RiDeleteBin6Line onClick={() => deleteData(item.id)} title='Delete' style={{ width: "30px", color: "#F84F31", border: "1px solid gray", padding: "5px", borderTopRightRadius: "10px", borderBottomRightRadius: "10px" }} />
                                                             
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}                                                
                                            </tbody>
                                        </table>
                                    </div>
                                    :
                                    <center>No Data found</center>}
                            </React.Fragment>
                        }


                    </div>}

        </div>
    )
}

export default CompanyOtherInfo;
