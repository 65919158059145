import React, { useState } from "react";
import "./institutOnboarding.css";
import { useNavigate } from "react-router-dom";
import PendingInstitutes from "./InstitutteChild/InstitutePending";
import VerifiedInstitutes from "./InstitutteChild/verifiedInstitute";
import RejectedInstitute from "./InstitutteChild/RejectedInstitute";
const InstituteOnBoarding = () => {
  const navigate = useNavigate();

  const [candidatesStatusBar, setCandidatesStatusBar] = useState({
    new: true,
    verified: false,
    rejected: false,
  });
  const handleStatusBarData = (barValue) => {
    switch (barValue) {
      case "pending":
        setCandidatesStatusBar({ new: true, verified: false, rejected: false });
        break;
      case "verified":
        setCandidatesStatusBar({ new: false, verified: true, rejected: false });
        break;
      case "rejected":
        setCandidatesStatusBar({ new: false, verified: false, rejected: true });
        break;
      default:
        setCandidatesStatusBar({ new: true, verified: false, rejected: false });
    }
  };
  return (
    <div className="institute-container animate__animated animate__fadeIn">
      <div className="instituteOnboarding-list-view">
        <div className="headerPart">
          <h4>Institutes / College List</h4>
          <button
            onClick={() => navigate("/onboarding-institute/form")}
            className="addInstituteButton"
          ></button>
        </div>
      </div>
      <table className="table" style={{ border: "none", marginTop: "50px" }}>
        <thead>
          <tr
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              borderBottom: "1px solid #005A80",
              backgroundColor: "#f7fbfe",
            }}
            className="candidates-status-header"
          >
            <p
              onClick={() => handleStatusBarData("pending")}
              style={{ cursor: "pointer" }}
              className={candidatesStatusBar.new ? "barActive" : "barInactive"}
            >
              <center>New</center>
            </p>
            <p
              onClick={() => handleStatusBarData("verified")}
              style={{ cursor: "pointer" }}
              className={
                candidatesStatusBar.verified ? "barActive" : "barInactive"
              }
            >
              <center>Verified</center>
            </p>
            <p
              onClick={() => handleStatusBarData("rejected")}
              style={{ cursor: "pointer" }}
              className={
                candidatesStatusBar.rejected ? "barActive" : "barInactive"
              }
            >
              <center>Rejected</center>
            </p>
          </tr>
        </thead>
      </table>

      {candidatesStatusBar.rejected === true && <RejectedInstitute />}
      {candidatesStatusBar.verified === true && <VerifiedInstitutes />}
      {candidatesStatusBar.new === true && <PendingInstitutes />}
    </div>
  );
};

export default InstituteOnBoarding;
