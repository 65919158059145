import React, { useState } from "react";
import "../InstituteOnboarding/institutOnboarding.css";
import "./candidate.css";
import RejectedCandidates from "./subFolders/RejectedCandidates";
import VerifiedCandidates from "./subFolders/VerifiedCandidates";
import PendingCandidates from "./subFolders/PendingCandidates";
const Candidates = () => {
  const [candidatesStatusBar, setCandidatesStatusBar] = useState({
    new: true,
    verified: false,
    rejected: false,
  });
  const handleStatusBarData = (barValue) => {
    switch (barValue) {
      case "pending":
        setCandidatesStatusBar({ new: true, verified: false, rejected: false });
        break;
      case "verified":
        setCandidatesStatusBar({ new: false, verified: true, rejected: false });
        break;
      case "rejected":
        setCandidatesStatusBar({ new: false, verified: false, rejected: true });
        break;
      default:
        setCandidatesStatusBar({ new: true, verified: false, rejected: false });
    }
  };

  return (
    <div className="institute-container animate__animated animate__fadeIn">
      <div className="instituteOnboarding-list-view">
        <div className="headerPart">
          <h4>Candidate List</h4>
        </div>
      </div>
      <table className="table mt-4" style={{ border: "none" }}>
        <thead>
          <tr
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              borderBottom: "1px solid #005A80",
              backgroundColor: "#f7fbfe",
            }}
            className="candidates-status-header"
          >
            <p
              onClick={() => handleStatusBarData("pending")}
              style={{ cursor: "pointer", backgroundColor: "#f7fbfe" }}
              className={candidatesStatusBar.new ? "barActive" : "barInactive"}
            >
              <center>New</center>
            </p>
            <p
              onClick={() => handleStatusBarData("verified")}
              style={{ cursor: "pointer", backgroundColor: "#f7fbfe" }}
              className={
                candidatesStatusBar.verified ? "barActive" : "barInactive"
              }
            >
              <center>Verified</center>
            </p>
            <p
              onClick={() => handleStatusBarData("rejected")}
              style={{ cursor: "pointer", backgroundColor: "#f7fbfe" }}
              className={
                candidatesStatusBar.rejected ? "barActive" : "barInactive"
              }
            >
              <center>Rejected</center>
            </p>
          </tr>
        </thead>
      </table>

      {candidatesStatusBar.rejected === true && <RejectedCandidates />}
      {candidatesStatusBar.verified === true && <VerifiedCandidates />}
      {candidatesStatusBar.new === true && <PendingCandidates />}

      {/* Details Modal */}
    </div>
  );
};

export default Candidates;
