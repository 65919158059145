import axios from 'axios';
import { base_url } from '../utils/endpoints/endpoints';
const token = JSON.parse(localStorage.getItem('token'));
let access = token?.access;
export const UpdateLearningCourse = (body,id)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.patch(`${base_url}/api/v1/web/course/${id}/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch(err=>{
            reject(err)
        })
    })
}

export const updateCandidateStatus = (id,body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }
    return new Promise((resolve,reject)=>{
        axios.patch(`${base_url}/api/v1/web/users/${id}/status_update`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch(err=>{
            reject(err)
        })
    })
}

export const updateInstituteStatus = (id,body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.patch(`${base_url}/api/v1/web/users/${id}/status_update`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch(err=>{
            reject(err)
        })
    })
}

export const updateShip = (id,body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.patch(`${base_url}/api/v1/web/users/ship_type/${id}/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch(err=>{
            reject(err)
        })
    })
}


export const updateSubject = (id,body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.patch(`${base_url}/api/v1/web/course/subject/${id}/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch(err=>{
            reject(err)
        })
    })
}

export const updateBlockStatus = (id)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.patch(`${base_url}/api/v1/web/users/${id}/block/`,{},option)
        .then((res)=>{
            resolve(res)
        })
        .catch(err=>{
            reject(err)
        })
    })
}

export const updateCourseModule = (id,body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.patch(`${base_url}/api/v1/web/course/course_module/${id}/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch(err=>{
            reject(err)
        })
    })
}

export const updateRoleRank = (id,body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.patch(`${base_url}/api/v1/web/job/job_role/${id}/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch(err=>{
            reject(err)
        })
    })
}

export const updateJobCategory = (id,body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.patch(`${base_url}/api/v1/web/job/job_category/${id}/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch(err=>{
            reject(err)
        })
    })
}

export const updateCreatedcertificate = (id,body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.patch(`${base_url}/api/v1/web/users/certificate_type/${id}/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch(err=>{
            reject(err)
        })
    })
}

export const updateExpiryCertificate = (id,body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.patch(`${base_url}/api/v1/web/utilities/todo_notification_schedule/${id}/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch(err=>{
            reject(err)
        })
    })
}

export const updateRankQuestion=(id,body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.patch(`${base_url}/api/v1/web/utilities/question/${id}/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch(err=>{
            reject(err)
        })
    })
}

export const updateModuleDocument = (id,body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.patch(`${base_url}/api/v1/web/course/course_module_doc/${id}/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch(err=>{
            reject(err)
        })
    })
}

export const updateNewsAndAnnouncement = (id,body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.patch(`${base_url}/api/v1/web/utilities/news_announcements/${id}/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch(err=>{
            reject(err)
        })
    })
}

export const updateAd = (id,body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.patch(`${base_url}/api/v1/web/utilities/ad/${id}/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch(err=>{
            reject(err)
        })
    })
}

export const updateInstituteDetails = (id,body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.patch(`${base_url}/api/v1/web/users/${id}/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch(err=>{
            reject(err)
        })
    })
}

export const updateCourseData = (id,body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.patch(`${base_url}/api/v1/web/course/course_detail/${id}/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch(err=>{
            reject(err)
        })
    })
}

export const updatePostedJobcompany = (id, body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.patch(`${base_url}/api/v1/web/job/${id}/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch(err=>{
            reject(err)
        })
    })
}

export const jobApplicantStatusChange = (id,body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.patch(`${base_url}/api/v1/web/job/job_application/${id}/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch(err=>{
            reject(err)
        })
    })
}

export const updateShipOfCompany = (id,body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.patch(`${base_url}/api/v1/web/users/ship/${id}/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch(err=>{
            reject(err)
        })
    })
}

export const updateAddedQuestion = (id,body)=>{
    const option = {
        headers:{
            'Content-type':"application/json",
            'Authorization': "Bearer "+access,
        }
    }

    return new Promise((resolve,reject)=>{
        axios.patch(`${base_url}/api/v1/web/utilities/question/${id}/`,body,option)
        .then((res)=>{
            resolve(res)
        })
        .catch(err=>{
            reject(err)
        })
    })
}