import React from "react";
import  {FaChevronDown, FaChevronUp, FaClipboardList } from 'react-icons/fa';
import {HiMiniBuildingOffice2} from 'react-icons/hi2';
import {TbReportAnalytics} from "react-icons/tb";
import {LiaIdCardSolid, LiaShipSolid}  from "react-icons/lia";
import { MdOutlineDashboard } from "react-icons/md";

 // Admin Icons imported
import { ReactComponent as DashboardIcon} from "./sidebarAdminIcons/dashboardIcon.svg";
import { ReactComponent as OnboardingIcon} from "./sidebarAdminIcons/OnboardingIcon.svg";
import { ReactComponent as MastersIcon} from "./sidebarAdminIcons/MastersIcon.svg";
import { ReactComponent as UploadIcon} from "./sidebarAdminIcons/UploadIcon.svg";
import { ReactComponent as NewsIcon} from "./sidebarAdminIcons/NewsIcon.svg";
import { ReactComponent as AdvertisementIcon} from "./sidebarAdminIcons/AdvertisementIcon.svg";
import { ReactComponent as PostJobIcon} from "./sidebarAdminIcons/PostJobIcon.svg";
import { ReactComponent as ReportIcon} from "./sidebarAdminIcons/ReportIcon.svg";
// import { ReactComponent as ScheduleCourseIcon} from "./sidebarAdminIcons/ScheduleCourseIcon.svg";

// Institute Icons imported
import {ReactComponent as CourseAvailableIcon} from "./sidebarInstituteIcons/CourseAvailableIcon.svg";
import {ReactComponent as InstituteDetailsIcon} from "./sidebarInstituteIcons/InstituteDetailsIcon.svg";
import {ReactComponent as InstituteReports} from "./sidebarInstituteIcons/InstituteReports.svg";

// Institute Icons imported
import {ReactComponent as JobManagementIcon} from "./sidebarCompanyIcons/JobManagementIcon.svg";
import {ReactComponent as CompanyDetailsIcon} from "./sidebarCompanyIcons/CompanyDetailsIcon.svg";
import {ReactComponent as OtherDetailsIcon} from "./sidebarCompanyIcons/OtherDetailsIcon.svg";

export const sidebarDataAdmin = [
    {
        title:"Dashboard",
        path:"/admin",
        icon: <DashboardIcon style={{width:"20px",height:"20px"}}/>,
    },
    {
        title:"Onboarding",
        icon: <OnboardingIcon style={{width:"20px",height:"20px"}}/>,
        closedIcon:<FaChevronDown color="#126181" style={{width:"20px",marginLeft:"50px"}}/>,
        openIcon:<FaChevronUp color="#126181" style={{width:"20px",marginLeft:"50px"}}/>,
        subNav:[
            {
                title:"Institute",
                path:"/onboarding-institute",
            },
            {
                title:"Shipping Company",
                path:"/onboarding-company",
            },
            {
                title:"Candidate",
                path:"/onboarding-candidates",
            },
        ]
    },
    {
        title:"Masters",
        icon: <MastersIcon style={{width:"20px",height:"20px"}}/>,
        closedIcon:<FaChevronDown color="#126181" style={{width:"20px",marginLeft:"50px"}}/>,
        openIcon:<FaChevronUp color="#126181" style={{width:"20px",marginLeft:"50px"}}/>,
        subNav:[
            // {
            //     title:"Add Department",
            //     path:"/master-department",
            // },
            {
                title:"Course Subjects",
                path:"/master-subjects",
            },
            {
                title:"Learning Courses",
                path:"/master-courses",
            },
            {
                title:"SubCategory Of Courses",
                path:"/master-subcourses",
            },
            {
                title:"Add Questions",
                path:"/master-questions",
            },
            {
                title:"Job Category",
                path:"/masters-jobCategory",
            },
            {
                title:"Job Role/Rank",
                path:"/masters-jobRank",
            },
            {
                title:"Create Certificate",
                path:"/masters-createCertificate",
            },
            {
                title:"Document Expiry",
                path:"/master-certificates",
            },
            {
                title:"Ship Type",
                path:"/master-shiptype",
            },
            {
                title:"Other Questions",
                path:"/master-otherQuestions",
            },
        ]
    },
    {
        title:"Upload Content",
        path:"/master-uploadContent",
        icon: <UploadIcon style={{width:"20px",height:"20px"}}/>,
    },
    // {
    //     title:"Schedule Course",
    //     path:"/master-course",
    //     icon: <ScheduleCourseIcon style={{width:"20px",height:"20px"}}/>,
    // },
    {
        title:"News & Announcements",
        path:"/master-newsAnnouncement",
        icon: <NewsIcon style={{width:"20px",height:"20px"}}/>,
    },
    {
        title:"Advertisement",
        path:"/master-ads",
        icon: <AdvertisementIcon style={{width:"20px",height:"20px"}}/>,
    },
    {
        title:"Post Job",
        path:"/master-jobpost",
        icon: <PostJobIcon style={{width:"20px",height:"20px"}}/>,
    },
    {
        title:"Reports",
        path:"/master-report",
        icon: <ReportIcon style={{width:"20px",height:"20px"}}/>,
    }
];

export const sidebarDataInstitute = [
    {
        title:"Dashboard",
        path:"/institute",
        icon: <DashboardIcon style={{width:"20px",height:"20px"}}/>,
    },
    {
        title:"Courses Available",
        icon: <CourseAvailableIcon style={{width:"20px",height:"20px"}}/>,
        closedIcon:<FaChevronDown color="#126181" style={{width:"20px",marginLeft:"50px"}}/>,
        openIcon:<FaChevronUp color="#126181" style={{width:"20px",marginLeft:"50px"}}/>,
        subNav:[
            {
                title:"Request Course",
                path:"/institute-courserequest",
            },
            {
                title:"Schedule Of Courses",
                path:"/institute-course-schedule",
            }
        ]
    },
   
    {
        title:"Institute Details",
        path:"/institute-details",
        icon: <InstituteDetailsIcon style={{width:"20px",height:"20px"}}/>,
    },
    {
        title:"Reports",
        icon: <InstituteReports style={{width:"20px",height:"20px"}}/>,
        closedIcon:<FaChevronDown color="#126181" style={{width:"20px",marginLeft:"50px"}}/>,
        openIcon:<FaChevronUp color="#126181" style={{width:"20px",marginLeft:"50px"}}/>,
        subNav:[
            {
                title:"Enroll",
                path:"/institute-reports",
            },
        ]
    }
]

export const sidebarDataCompany = [
    {
        title:"Dashboard",
        path:"/company/dashboard",
        icon: <DashboardIcon style={{width:"20px",height:"20px"}}/>,
    },
    {
        title:"Job management",
        icon: <JobManagementIcon style={{width:"20px",height:"20px"}}/>,
        closedIcon:<FaChevronDown color="#126181" style={{width:"20px",marginLeft:"50px"}}/>,
        openIcon:<FaChevronUp color="#126181" style={{width:"20px",marginLeft:"50px"}}/>,
        subNav:[
            {
                title:"Post Job",
                path:"/company/job-management/jobpost",
            },
            {
                title:"Applicant Resumes",
                path:"/company/job-management/viewesume",
            },
        ]
    },
    {
        title:"Company Details",
        path:"/company/details",
        icon: <CompanyDetailsIcon style={{width:"20px",height:"20px"}}/>,
    },
    {
        title:"Other Details",
        path:"/company/otherinfo",
        icon: <OtherDetailsIcon style={{width:"20px",height:"20px"}}/>,
    },
    {
        title:"Reports",
        icon: <InstituteReports style={{width:"20px",height:"20px"}}/>,
        closedIcon:<FaChevronDown color="#126181" style={{width:"20px",marginLeft:"50px"}}/>,
        openIcon:<FaChevronUp color="#126181" style={{width:"20px",marginLeft:"50px"}}/>,
        subNav:[
            {
                title:"Job Report",
                path:"/company/reports",
            },
        ]
    }
    
]