import React, { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { BiSearch } from "react-icons/bi";
import { searchStudent } from "../../../../Apis/getApis";

const DashboardCardsInstitute = ({ profileVisits, totalCourses }) => {
  const [searchBy, setSearchBy] = useState("");
  const [search, setSearch] = useState("");
  const [searchData, setSearchData] = useState([]);

  useEffect(() => {
    let timer;
    if (search !== "") {
      setSearchData([]);
      timer = setTimeout(() => {
        searchStudent(searchBy, search)
          .then((res) => {
            setSearchData(res?.data?.data?.results);
          })
          .catch((err) => {
            console.log(err);
          });
      }, 1000);
    } else {
      setSearchData([]);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [search]);

  return (
    <div className="instituteDashboard-card" style={{ margin: "auto" }}>
      <div
        className="card mb-2"
        style={{
          width: "auto",
          height: "20rem",
          minWidth: "28rem",
          maxWidth: "100%",
          borderTop: "4px solid #63c3ec",
          boxShadow: "-1px 3px 20px 0px #0000001A",
        }}
      >
        <div className="card-body">
          <div className="d-flex flex-column h-50 mb-5">
            <div
              className="d-flex justify-content-center align-items-center m-auto mb-4"
              style={{
                width: "50px",
                height: "80px",
                background: "#2A737A14",
                borderRadius: "50%",
              }}
            >
              <img
                src="/assets/icon/mark.png"
                alt="img"
                style={{ width: "24px", height: "24px", margin: "8px 2px" }}
              />
            </div>
            <div className="w-auto w-50">
              <h5
                className="card-title ms-2"
                style={{
                  textDecoration: "underline 1px #2A737A66",
                  textUnderlineOffset: "10px",
                  color: "#337B99",
                }}
              >
                Courses
              </h5>
            </div>
            <h6
              className="card-subtitle mb-2 text-muted mt-2 ms-2 d-flex w-100 justify-content-between"
              style={{ fontSize: "14px", fontWeight: "400" }}
            >
              - Total Courses :
              <span className="w-25 font-weight-bold text-dark">
                {totalCourses}
              </span>{" "}
              {/*<FaEye style={{ width: "30px", height: "25px", color: "#3FBDC9", border: "1px solid #3FBDC9", padding: "2px", borderRadius: "5px", cursor: 'pointer' }} />*/}
            </h6>
          </div>
        </div>
      </div>

      <div
        className="card mb-2"
        style={{
          width: "auto",
          minWidth: "28rem",
          maxWidth: "100%",
          height: "20rem",
          borderTop: "4px solid #63c3ec",
          boxShadow: "-1px 3px 20px 0px #0000001A",
        }}
      >
        <div className="card-body">
          <div className="d-flex flex-column h-50 mb-5">
            <div
              className="d-flex justify-content-center align-items-center m-auto mb-4"
              style={{
                width: "50px",
                height: "80px",
                background: "#2A737A14",
                borderRadius: "50%",
              }}
            >
              <img
                src="/assets/icon/mark.png"
                alt="img"
                style={{ width: "24px", height: "24px", margin: "8px 2px" }}
              />
            </div>
            <div className="w-auto w-50">
              <h5
                className="card-title ms-2"
                style={{
                  textDecoration: "underline 1px #2A737A66",
                  textUnderlineOffset: "10px",
                  color: "#337B99",
                }}
              >
                Profile Visitors
              </h5>
            </div>
            <h6
              className="card-subtitle mb-2 text-muted mt-2 ms-2 d-flex w-100 justify-content-between"
              style={{ fontSize: "14px", fontWeight: "400" }}
            >
              - Total Profile Visitors:
              <span className="w-25 font-weight-bold text-dark">
                {profileVisits}
              </span>
            </h6>
          </div>
        </div>
      </div>

      <div
        className="card last-card mb-2"
        style={{
          width: "auto",
          minWidth: "28rem",
          maxWidth: "100%",
          height: "20rem",
          borderTop: "4px solid #63c3ec",
          boxShadow: "-1px 3px 20px 0px #0000001A",
        }}
      >
        <div className="card-body">
          <div className="d-flex flex-column h-50 mb-5">
            <div className="w-auto w-50">
              <h5
                className="card-title ms-2"
                style={{
                  textDecoration: "underline 1px #2A737A66",
                  textUnderlineOffset: "10px",
                  color: "#337B99",
                }}
              >
                Search Student / Seafarer
              </h5>
            </div>
            <div
              className="d-flex h-50 flex-row jusitify-content-center align-items-center rounded"
              style={{
                color: "#777777",
                border: "1px solid #005A80",
                borderRadius: "5px",
              }}
            >
              <BiSearch className="w-25 h-50" style={{ maxWidth: "45px" }} />
              <select
                className="h-50 outline-0 border-0"
                value={searchBy}
                onChange={(e) => setSearchBy(e.target.value)}
              >
                <option value="" style={{ color: "#777777" }}>
                  Search By
                </option>
                <option value="indos_number">Indos Number</option>
                <option value="cdc_number">CDC Number</option>
                <option value="name">Name</option>
              </select>
            </div>
            {searchBy !== "" && (
              <input
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className="form-control mt-1"
                placeholder="search..."
              />
            )}
            <div>
              <div
                style={{
                  width: "100%",
                  height: "150px",
                  maxHeight: "200px",
                  overflowY: "scroll",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: "5px",
                }}
              >
                {searchData.length > 0 ? (
                  searchData.map((item) => {
                    return (
                      <div
                        style={{
                          maxHeight: "100px",
                          height: "72px",
                          display: "flex",
                          paddingLeft: "5px",
                          flexDirection: "column",
                          borderBottom: "1px solid gray",
                        }}
                      >
                        <div>
                          <span>Name: </span>
                          <span>{" " + item?.first_name}</span>
                        </div>
                        <div>
                          <span>CDC Number</span>
                          <span>{item?.cdc_number}</span>
                        </div>
                        <div>
                          <span>Indos Number</span>
                          <span>{item?.indos_number}</span>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardCardsInstitute;
