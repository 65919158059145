import React from 'react'
import { BsEye } from 'react-icons/bs';
const DataCards = React.memo(({kpi,offerAcceptance,profileVisitors}) => {
    return (
        <div className=' fluid-container company-dashboard-cards'>
            <div className="card mb-2" style={{height: "15.625rem", borderTop: "4px solid #63c3ec" }}>
                <div className="card-body">
                    <div className='d-flex flex-column h-50 mb-5'>
                        <div className='d-flex justify-content-center align-items-center m-auto mb-4' style={{ width: "50px", height: "80px", background: "#2A737A14", borderRadius: "50%" }}>
                            <img src='/assets/icon/mark.png' alt='img' style={{ width: "24px", height: "24px", margin: "8px 2px", }} />
                        </div>
                        <div className='w-auto w-50' >
                            <h5 className="card-title ms-2" style={{ textDecoration: 'underline 1px #2A737A66', textUnderlineOffset: "10px", color: "#337B99" }}>Profile Visitors</h5>
                        </div>
                        <h6 className="card-subtitle mb-2 text-muted mt-2 ms-2" style={{ fontSize: "14px", fontWeight: '400' }}>- Total Profile Visitors: {profileVisitors?.total_viewed_visitors}</h6>
                        <h6 className="card-subtitle mb-2 text-muted mt-2 ms-2" style={{ fontSize: "14px", fontWeight: '400' }}>- Todays Visitors: {profileVisitors?.today_viewed_visitors}</h6>
                        <h6 className="card-subtitle mb-2 text-muted mt-2 ms-2" style={{ fontSize: "14px", fontWeight: '400' }}>- Till yesterday Profile Visitors: {profileVisitors?.yesterday_viewed_visitors}</h6>
                    </div>
                </div>
            </div>

            <div className="card mb-2" style={{ height: "15.625rem", borderTop: "4px solid #63c3ec" }}>
                <div className="card-body">
                    <div className='d-flex flex-column h-25'>
                        <div className='d-flex justify-content-center align-items-center m-auto mb-2'>
                            <p style={{ fontFamily: 'Poppins', fontSize: '16px', fontWeight: '500', lineHeight: '24px', letterSpacing: '0.01em', textAlign: 'center', color: '#337B99' }}>BlueSky Innovations, Inc.</p>
                        </div>
                        <div className='d-flex flex-column h-25 w-100'>
                            <p style={{ fontSize: "12px", fontWeight: "500", color: '#777777' }}>Member Since - <span style={{ fontSize: "12px", fontWeight: "400", color: '#A4A4A4' }}>04-06-2020</span></p>
                            <p style={{ fontSize: "12px", fontWeight: "500", color: '#777777' }}>Subscription Plan - <span style={{ fontSize: "12px", fontWeight: "400", color: '#A4A4A4' }}>xxxxx xxxxx xxxxxx</span></p>
                            <p style={{ fontSize: "12px", fontWeight: "500", color: '#777777' }}>Subscription Date - <span style={{ fontSize: "12px", fontWeight: "400", color: '#A4A4A4' }}>05-02-2021</span></p>
                            <p style={{ fontSize: "12px", fontWeight: "500", color: '#777777' }}>Expiry Date  - <span style={{ fontSize: "12px", fontWeight: "400", color: '#A4A4A4' }}> 05-03-2021</span></p>
                            <div className='m-auto w-100 d-flex justify-content-center align-items-center mt-4'>
                                <button style={{
                                            width: "194px",
                                            height: "34px",
                                            padding: "8px 30px 8px 30px",
                                            borderRadius: "6px 6px 6px 6px",
                                            color: 'white',
                                            background: '#005A80'
                                        }}>Upgrade Subscription</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="card" style={{ width: "20rem", height: "8rem" }}>
                <div className="card-body">
                    <div className='d-flex flex-row h-25 mb-4'>
                        <MdSupervisedUserCircle style={{ width: "25px" }} />
                        <h5 className="card-title ms-2">Total Employees</h5>
                    </div>
                    <h6 className="card-subtitle mb-2 text-muted ms-2">236</h6>
                </div>
            </div> */}
            <div className='' style={{ height: "15.625rem", gap:"10px",marginLeft:"-11px" }}>
                <div className="card" style={{height: "8rem",borderTop: "4px solid #63c3ec" }}>
                    <div className="card-body h-100">
                        <div className='d-flex flex-column align-items-center justify-content-center h-100 mb-4'>
                            <center className="card-title ms-2" style={{fontSize:"1em",fontWeight:"400",color:'#767676'}}>- Offer Acceptance:</center>
                            <center className="card-title ms-2" style={{fontWeight:"600",fontSize:"1.25em",color:"#005A80"}}>{offerAcceptance}</center>
                        </div>
                    </div>
                </div>

                <div className="card" style={{  height: "8rem",borderTop: "4px solid #63c3ec" }}>
                <div className="card-body h-100">
                        <div className='d-flex flex-column align-items-center justify-content-center h-100 mb-4'>
                            <center className="card-title ms-2" style={{fontSize:"1em",fontWeight:"400",color:'#767676'}}>- Key Performance Indicator:</center>
                            <center className="card-title ms-2" style={{fontWeight:"600",fontSize:"1.25em",color:"#005A80"}}>{kpi}</center>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card mb-2" style={{ height: "15.625rem", borderTop: "4px solid #63c3ec", marginLeft:"0px" }}>
                <div className="card-body">
                    <div className='d-flex flex-column h-50 mb-5'>
                        <div className='d-flex justify-content-center align-items-center m-auto mb-4' style={{ width: "50px", height: "80px", background: "#2A737A14", borderRadius: "50%" }}>
                            <img src='/assets/icon/mark.png' alt='img' style={{ width: "24px", height: "24px", margin: "8px 2px", }} />
                        </div>
                        <div className='w-auto w-50' >
                            <h5 className="card-title ms-2" style={{ textDecoration: 'underline 1px #2A737A66', textUnderlineOffset: "10px", color: "#337B99" }}>Job Posted</h5>
                        </div>
                        <h6 className="card-subtitle mb-2 text-muted mt-2 ms-2" style={{ fontSize: "14px", fontWeight: '400' }}>- Total Job Posted: 1,000</h6>
                        <h6 className="card-subtitle mb-2 text-muted mt-2 ms-2" style={{ fontSize: "14px", fontWeight: '400' }}>- Till Yesterday: 101 <BsEye style={{width:"25px", marginLeft:"50px"}}/></h6>
                        <h6 className="card-subtitle mb-2 text-muted mt-2 ms-2" style={{ fontSize: "14px", fontWeight: '400' }}>- Today: 10</h6>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default DataCards;
