import React, { useEffect, useState } from 'react'
import { getCities, getCountries, getStates } from '../../../../Apis/getApis';
import "./locality.css";
import { FaBox, FaCaretDown, FaEye } from 'react-icons/fa';
import { BallTriangle } from 'react-loader-spinner';

const Locality = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [country, setCountry] = useState('Country');
  const [countryList,setCountryList] = useState('');
  const [state, setState] = useState('State');
  const [stateList,setStateList] = useState('')
  const [city, setCity] = useState('City');
  const [cityList,setCityList] = useState('');
  const [loading,setLoading] = useState(false);
  const tableData = [];

  const makeCountry = (item)=>{
    setCountry((prev) => prev = item.name);
    getStates(item.id)
    .then((res)=>{
      setStateList(res?.data?.data)
    })
    .catch((err)=>{
      console.log(err)
    })
  }
  const makeState = (item)=>{
    setState((prev)=>prev=item.name)
      getCities(item.id)
    .then((res)=>{
    setCityList(res?.data?.data)
    
    })
    .catch((err)=>{
    console.log(err)
    })

  }
  const makeCity = (item)=>{
      setCity((prev)=> prev = item?.name)
  }

  useEffect(() => {
    setLoading(true);
    getCountries()
      .then((res) => {
        setLoading(false);
        setCountryList(res?.data?.data)
      })
      .catch((err) => {
        setLoading(false);
        console.log(err)
      })
  }, [])



  return (
    <div className='institute-container animate__animated animate__fadeIn'>
      <div className='instituteOnboarding-list-view'>
        <div className='headerPart'>
          <h4>Country / State / City</h4>
        </div>
      </div>
      <div className='table-container'>
        <div className='allDropDown'>
          <div className="dropdown dropDown-locality">
            <button type="button" className={width < 500 ? "btn drdpbtn sailors-Input-field col" : "btn drdpbtn sailors-Input-field row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white" }}>
              {country}<FaCaretDown style={{ width: "15px" }} />
            </button>
            <div className="dropdown-menu" style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "300px", height: "200px", overflowY: 'scroll' }}>
              {countryList.length>0 ?  countryList?.map((item, index) => {
                return (
                  <div className="dropdown-item" style={{ height: "50px",cursor:"pointer" }} onClick={()=>makeCountry(item)}>{item?.name}</div>
                )
              }) : <center style={{marginTop:"10%"}}>!Opps check the Internet</center>}
            </div>
          </div>

          <div className="dropdown dropDown-locality">
            <button type="button" className={width < 500 ? "btn drdpbtn sailors-Input-field col" : "btn drdpbtn sailors-Input-field row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white" }}>
              {state}<FaCaretDown style={{ width: "15px" }} />
            </button>
            <div className="dropdown-menu" style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "300px", height: "200px", overflowY: 'scroll' }}>
              {stateList.length>0 ?  stateList?.map((item, index) => {
                return (
                  <div className="dropdown-item" style={{ height: "50px",cursor:"pointer" }} onClick={() => makeState(item)}>{item?.name}</div>
                )
              }) : <center style={{marginTop:"10%"}}>Please Select the country</center>}
            </div>
          </div>

          <div className="dropdown dropDown-locality">
            <button type="button" className={width < 500 ? "btn drdpbtn sailors-Input-field col" : "btn drdpbtn sailors-Input-field row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white" }}>
              {city}<FaCaretDown style={{ width: "15px" }} />
            </button>
            <div className="dropdown-menu" style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "300px", height: "200px", overflowY: 'scroll' }}>
              {cityList.length>0 ?  cityList?.map((item, index) => {
                return (
                  <div className="dropdown-item" style={{ height: "50px",cursor:"pointer" }} onClick={() => makeCity(item)}>{item?.name}</div>
                )
              }) : <center style={{marginTop:"10%"}}>Please Select the state</center>}
            </div>
          </div>

        </div>
        <div className='form-btns' style={{ marginBottom: "10px" }}>
          <button type="submit" className="btn">Save</button>
          <button className="btn btn-OnboardForm">Cancel</button>
        </div>
      </div>
      <div className='instituteOnboarding-list-view' style={{marginTop:"20px"}}>
        <div className='headerPart'>
          <h4>Country / State / City - List</h4>
        </div>
      </div>
      {loading ?
        <div style={{maxWidth:"100px",maxHeight:"100px"}}>

          <BallTriangle
            height={20}
            width={20}
            radius={5}
            color="red"
            ariaLabel="ball-triangle-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
          <span>Loading...</span>
        </div>

        : <div className='table-container'>

          {tableData?.length > 0 ?
            <div className='table-Data'>
              <table className='table'>
                <thead>
                  <tr style={{ display: 'flex', justifyContent: "flex-start" }}>
                    <th scope="col">Sr No.</th>
                    <th scope="col">Institute/Company</th>
                    <th scope="col">Status</th>
                    <th scope="col">Details</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((item, index) => {
                    return (
                      <tr className='' style={{ display: 'flex', justifyContent: "space-around" }}>
                        <th scope="row">{index + 1}</th>
                        <td >{item?.first_name}</td>
                        <td>{item?.status}</td>
                        <td>
                          <div style={{ cursor: "pointer" }}>
                            <FaEye data-toggle="modal" data-target="#instituteDetailsModal" title='Details' style={{ width: "30px", color: "#0CB1C0", border: "1px solid gray", padding: "5px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px" }} /><FaBox title='Delete' style={{ width: "30px", color: "#F84F31", border: "1px solid gray", padding: "5px", borderTopRightRadius: "10px", borderBottomRightRadius: "10px" }} />
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>


              </table>
            </div>
            :
            <center>No Data found</center>}
        </div>}

    </div>
  )
}

export default Locality;
