import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
const PageNotFound = () => {
  const renderHeader = (
    <Box
      component="header"
      sx={{
        top: 0,
        left: 0,
        width: 1,
        lineHeight: 0,
        position: 'fixed',
      }}
    >
      <img src='/assets/authAssets/Sailors-Hub-logo.gif' alt='logo' style={{maxWidth:"200px",maxHeight:"50px"}}/>
    </Box>
  );
  return (
    // <div className='pageNotFound-Container'>
    //     <p >Page NotFound</p>
    //         <img src='/assets/commonAssets/light-house.gif' style={{width:"70%",height:"70%",position:"absolute"}} alt='logo'/>
    // </div>
    <>
      {renderHeader}

      <Container>
        <Box
          sx={{
            py: 12,
            maxWidth: 480,
            mx: 'auto',
            display: 'flex',
            minHeight: '100vh',
            textAlign: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h3" sx={{ mb: 3 }}>
            Sorry, page not found!
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be
            sure to check your spelling.
          </Typography>

          <Box
            component="img"
            src="/assets/illustrations/illustration_404.svg"
            sx={{
              mx: 'auto',
              height: 260,
              my: { xs: 5, sm: 10 },
            }}
          />

          <Button href="/" size="large" variant="contained">
            Go to Home
          </Button>
        </Box>
      </Container>
    </>
  )
}

export default PageNotFound;
