import React, { useEffect, useState } from 'react';
import { BallTriangle } from 'react-loader-spinner';
import { FaCaretDown, FaPen } from 'react-icons/fa';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { getAllJobsByCompany, getCities, getCountries, getJobCategoryList, getRolesAndRank, getShipTypeList, getStates } from '../../../Apis/getApis';
import { deleteJobPostByCompany } from '../../../Apis/deleteApis';
import Swal from 'sweetalert2';
import LazyLoad from 'react-lazy-load';
import { postJob } from '../../../Apis/postApis';
import { updatePostedJobcompany } from '../../../Apis/updateApis';
import { allowedTypes, jobTypeList } from '../../../utils/variables/folderStructureFiles';
import { deleteToast, errorToast, successToast, uploadFile } from '../../../utils/helperFunctions/HelperFunctions';
import "../../../css/bootstrap.css";

const JobpostCompany = () => {
    const [courseData, setCourseData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [country, setCountry] = useState('Country');
    const [countryList, setCountryList] = useState([]);
    const [jobRoleList, setJobRoleList] = useState([]);
    const [jobRole, setJobRole] = useState('');
    const [jobTitle, setJobTitle] = useState('')
    const [width, setWidth] = useState(window.innerWidth);
    const [moduleDescription, setModuleDescription] = useState('');
    const [jobtype, setJobType] = useState('Select Job type')
    const [isEdit, setIsEdit] = useState(false);
    const [selectedItem, setSelectedItem] = useState();
    const [search, setSearch] = useState('');
    const [searchData, setSearchData] = useState([]);
    const [image, setImage] = useState('');
    const [jobCategoryList, setJobCategoryList] = useState([]);
    const [jobCategory, setJobCategory] = useState('');
    const [skills, setSkills] = useState('');
    const [requirements, setRequirements] = useState('');
    const [responsibilities, setResponsibilities] = useState('');
    const [jobStatus, setJobStatus] = useState(true);
    const [from_date, setFromDate] = useState('');
    const [to_date, setToDate] = useState('');
    const [forSeafarer, setForSeafarer] = useState(false);
    const [forFresher, setForFresher] = useState(false);
    const [minValidityUnit, setMinValidityUnit] = useState('Select Min-Experience');
    const [minValidityValue, setMinValidityValue] = useState('');
    const [maxValidityValue, setMaxValidityValue] = useState('');
    const [maxValidityUnit, setMaxValidityUnit] = useState('Select Max-Experience');
    const [envType, setEnvType] = useState('');
    const [dataType, setDataType] = useState('');
    const userDetails = JSON.parse(localStorage.getItem('userData'));
    const [allPostedJobs, setAllPostedJobs] = useState([]);
    const [allShipsData, setShipsData] = useState([]);
    const [selectedShips, setSelectedShips] = useState('');
    const [state,setState] = useState('');
    const [stateList,setStateList] = useState([]);
    const [city,setCity] = useState('');
    const [cityList,setCityList] = useState([]);
    const [department,setDepartment] = useState();
    const [openings,setOpenings] = useState(0);


    const editData = (item) => {
        setIsEdit(true);
        setSelectedItem(item);
        console.log(item, )

        setJobTitle(item?.job_title);
        setModuleDescription(item?.description);
        setJobRole(item?.job_role);
        setJobCategory(item?.job_category);
        setJobType(item?.job_type);
        setSkills(item?.skills);
        setRequirements(item?.requirements);
        setResponsibilities(item?.responsibilities);
        setCountry(item?.country);
        setState(item?.state);
        setCity(item?.city)
        setImage(item?.file);
        setFromDate(item?.from_date);
        setSelectedShips(item?.ship_type)
        setToDate(item?.to_date);
        setJobStatus(item?.is_active);
        setForFresher(item?.for_fresher);
        setForSeafarer(item?.for_seafarer);
        setMinValidityUnit(item?.min_experience?.unit);
        setMinValidityValue(item?.min_experience?.value);
        setMaxValidityUnit(item?.max_experience?.unit);
        setMaxValidityValue(item?.max_experience?.value);
        setEnvType(item?.work_environment_type);
    }

    const getJobRoleById = (val) => {
        if (isNaN(val)) {
            return "Choose Job Role";
        } else {
            for (let i = 0; i < jobRoleList.length; i++) {
                if (val === jobRoleList[i].id) {
                    return jobRoleList[i].name
                }
            }
        }
        return "Choose Job Role";
    }

    const getCountyById = (id) => {
        for (let i = 0; i < countryList?.length; i++) {
            if (countryList[i].id === id) {
                return countryList[i].name
            }
        }
        return "Select Country";
    }

    const getJobCategoryById = (val) => {
        if (isNaN(val)) {
            return "Choose Job Category";
        } else {
            for (let i = 0; i < jobCategoryList?.length; i++) {
                if (val === jobCategoryList[i].id) {
                    return jobCategoryList[i].name
                }
            }
        }
        return "Choose Job Category";
    }
    function showJobType(type) {
        let typeArr = type.split("_");
        let str = typeArr.join(" ").toUpperCase();

        return str;

    }


    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);


    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (allowedTypes.includes(selectedFile?.type)) {
            setFile(selectedFile);
            let res = uploadFile(selectedFile);
            res.then((data) => {
                console.log(data, "uploadedImage")
                setImage(data);
            })
        } else {
            alert('Invalid file type. Only images, videos, audios and PDFs are allowed.');
        }
    };


    const getShipById = (id) => {
        for (let i = 0; i < allShipsData.length; i++) {
            if (allShipsData[i].id == id) {
                return allShipsData[i].name;
            }
        }
        return "Select Ship";
    }


    useEffect(() => {
        setSearchData([])
        setTimeout(() => {
            if (search !== '') {
                let arr = [];
                for (let i = 0; i < countryList.length; i++) {
                    if (countryList[i].name.toLowerCase().includes(search.toLowerCase())) {
                        const isThere = (data) => {
                            for (let j = 0; j < arr.length; j++) {
                                if (arr[j].id == data.id) {
                                    return true;
                                }
                            }
                            return false;
                        }
                        if (!isThere(countryList[i])) {
                            arr.push(countryList[i]);
                        }
                    }
                }
                setSearchData([...searchData, ...[...new Set(arr)]])
            } else {
                setSearchData([])
            }
        }, 1000)
    }, [search])

    const handleCancel = () => {
        setIsEdit(false);
        setJobTitle("");
        setModuleDescription("");
        setJobRole("");
        setJobCategory('');
        setJobType('Select Job type');
        setSkills('');
        setRequirements('');
        setResponsibilities('');
        setCountry('');
        setImage('');
        setFromDate('');
        setToDate('');
        setJobStatus(true);
        setForFresher(false);
        setForSeafarer(false);
        setMinValidityUnit('Select Min-Experience');
        setMinValidityValue('');
        setMaxValidityUnit("Select Max-Experience");
        setMaxValidityValue('');
        setDataType('');
        setEnvType('');

    }

    const deleteModule = (id) => {

        Swal.fire({
            title: "Are you sure?",
            text: "Do you want to delete Job?",
            icon: "error",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Delete Job!"
        }).then((result) => {
            if (result.isConfirmed) {
                deleteJobPostByCompany(id)
                    .then((res) => {
                        console.log(res);
                        getAlljobs();
                        handleCancel();
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                deleteToast("Job is deleted.");
            } else {
                setSelectedItem();
            }

        });

    }



    const submitCourse = (e) => {
        e.preventDefault();
        if (country === "Country") {
            errorToast("Please Choose the Country");
            return;
        }
        else if (jobTitle == "") {
            errorToast("Please give Job Title");
            return;
        }
        else if (jobRole == '') {
            errorToast("Please select Proper job Role");
            return;
        }
        else if (moduleDescription == "") {
            errorToast("Please Give The Job description")
            return;
        }
        else if (jobCategory == "") {
            errorToast("Please Choose the jobCategory");
            return;
        }
        else if (forSeafarer == false && forFresher == false) {
            errorToast("Please Select Who can Apply?")
        }
        else if (!isEdit) {
            postJob({
                // company: userDetails?.id,
                job_title: jobTitle,
                job_role: jobRole,
                job_category: jobCategory,
                job_type: jobtype,
                skills: skills,
                requirements: requirements,
                responsibilities: responsibilities,
                country: country,
                description: moduleDescription,
                file: image,
                state:state,
                city:city,
                from_date: from_date,
                to_date: to_date,
                is_active: jobStatus,
                for_seafarer: forSeafarer,
                for_fresher: forFresher,
                min_experience: {
                    value: minValidityValue,
                    unit: minValidityUnit
                },
                max_experience: {
                    value: maxValidityValue,
                    unit: maxValidityUnit
                },
                data_type: dataType,
                work_environment_type: envType,
                ship_type: selectedShips

            })
                .then((res) => {
                    handleCancel();
                    setJobTitle("");
                    setModuleDescription("");
                    getAlljobs();
                    handleCancel();
                    successToast("Job Posted Succefully")
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {

            updatePostedJobcompany(selectedItem.id, {
                company: userDetails?.id,
                job_title: jobTitle,
                job_role: jobRole,
                job_category: jobCategory,
                job_type: jobtype,
                skills: skills,
                requirements: requirements,
                responsibilities: responsibilities,
                country: country,
                state:state,
                city:city,
                description: moduleDescription,
                file: image,
                from_date: from_date,
                to_date: to_date,
                is_active: jobStatus,
                for_seafarer: forSeafarer,
                for_fresher: forFresher,
                min_experience: {
                    value: minValidityValue,
                    unit: minValidityUnit
                },
                max_experience: {
                    value: maxValidityValue,
                    unit: maxValidityUnit
                },
                data_type: dataType,
                work_environment_type: envType,
                ship_type: selectedShips
            })
                .then((res) => {
                    console.log(res);
                    successToast("Job Updated Succefully")
                    handleCancel();
                    getAlljobs();
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    let previousLength = 0;

const handleInput = (event) => {
  const bullet = "\u2022";
  const newLength = event.target.value.length;
  const characterCode = event.target.value.substr(-1).charCodeAt(0);

  if (newLength > previousLength) {
    if (characterCode === 10) {
      event.target.value = `${event.target.value}${bullet} `;
    } else if (newLength === 1) {
      event.target.value = `${bullet} ${event.target.value}`;
    }
  }
  
  previousLength = newLength;
}

    function getAlljobs() {
        getAllJobsByCompany()
            .then((res) => {
                console.log(res, "allJobs");
                setAllPostedJobs(res?.data?.data?.results);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        console.log(country, "states")
        setTimeout(() => {
            if(country!=="Country"){
                getStates(country)
                    .then((res) => {
                        console.log(res, "states")
                        setStateList(res?.data?.data)
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        }, 500);
    },[country])

    useEffect(() => {
        setTimeout(() => {
            if(state){
                getCities(state)
                .then((res) => {
                    console.log(res, "states")
                    setCityList(res?.data?.data)
                })
                .catch((err) => {
                    console.log(err)
                })
            }
        }, 500);
    },[state])

    



    useEffect(() => {
        setLoading(true);
        getAlljobs();

        getShipTypeList()
            .then((res) => {
                console.log(res);
                setShipsData(res?.data?.data?.results);
            })
            .catch((err) => {
                console.log(err)
            })

        getCountries()
            .then((res) => {
                setLoading(false);
                setCountryList(res?.data?.data)
            })
            .catch((err) => {
                setLoading(false);
                console.log(err)
            })

        getRolesAndRank()
            .then((res) => {
                setJobRoleList(res?.data?.data?.results)
            })
            .catch((err) => {
                console.log(err)
            })

        getJobCategoryList()
            .then((res) => {
                console.log(res, "job Category");
                setJobCategoryList(res?.data?.data?.results)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    return (
        <div className='institute-container animate__animated animate__fadeIn'>
            <div className='instituteOnboarding-list-view'>
                <div className='headerPart'>
                    <h4>Post Job</h4>
                </div>
            </div>
            <div className="fluid-container p-4 bg-white shadow rounded-lg position-relative" style={{borderRadius:"20px"}}>
            <span className='mustStar' style={{textAlign:'end', fontSize:"15px", position:'absolute',top:"-20px",left:"-16px"}}>Fields marked (*) are mandatory.</span>
                <form className='d-grid gap-3'>
                    <div className="row mb-3">
                        <label className="col-sm-3 col-form-label">Select Country<span className='mustStar'>*</span></label>
                        <div className="col-sm-9">
                            <select className="form-select sailors-Input-field bg-white" value={country} onChange={(e) => setCountry(e.target.value)}>
                                {countryList.length > 0 && <option>Select Country</option>}
                                {countryList.length > 0 ? countryList?.map((item, index) => {
                                    return (
                                        <option value={item?.id}>{item?.name}</option>
                                    )
                                }) : <option >!Opps check the Internet</option>}
                            </select>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label className="col-sm-3 col-form-label">Select State<span className='mustStar'>*</span></label>
                        <div className="col-sm-9">
                            <select className="form-select sailors-Input-field bg-white" value={state} onChange={(e) => setState(e.target.value)}>
                                {stateList.length > 0 && <option>Select State</option>}
                                {stateList.length > 0 ? stateList?.map((item, index) => {
                                    return (
                                        <option value={item?.id}>{item?.name}</option>
                                    )
                                }) : <option >Select State</option>}
                            </select>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label className="col-sm-3 col-form-label">Select City<span className='mustStar'>*</span></label>
                        <div className="col-sm-9">
                            <select className="form-select sailors-Input-field bg-white" value={city} onChange={(e) => setCity(e.target.value)}>
                                {cityList.length > 0 && <option>Select City</option>}
                                {cityList.length > 0 ? cityList?.map((item, index) => {
                                    return (
                                        <option value={item?.id}>{item?.name}</option>
                                    )
                                }) : <option >Select City</option>}
                            </select>
                        </div>
                    </div>
                    
                    <div className="row mb-3">
                        <label className="col-sm-3 col-form-label">Job Title<span className='mustStar'>*</span></label>
                        <div className="col-sm-9">
                            <input type="text" value={jobTitle} onChange={(e) => setJobTitle(e.target.value)}  className="form-control sailors-Input-field" placeholder="Enter Job Title" />
                        </div>
                    </div>
                    
                    <div className="row mb-3">
                        <label className="col-sm-3 col-form-label">Job Openings</label>
                        <div className="col-sm-9">
                            <input type="number" value={openings} onChange={(e) => {setOpenings(e.target.value); if(e.target.value<0){
                                errorToast("Value Should be more than 0");
                                setOpenings(0);
                            }}}  className="form-control sailors-Input-field" placeholder="Enter Job Openings" />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label className="col-sm-3 col-form-label">Job Role<span className='mustStar'>*</span></label>
                        <div className="col-sm-9">
                            <select className="form-select sailors-Input-field bg-white" value={jobRole} onChange={(e) => { setJobRole(e.target.value) }}>
                                {jobRoleList.length > 0  && <option>Select Job Role</option>}
                                {jobRoleList.length > 0 ? jobRoleList?.map((item, index) => {
                                    return (
                                        <option value={item?.id}>{item?.name}</option>
                                    )
                                }) : <option >!Opps check the Internet</option>}
                                
                            </select>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label className="col-sm-3 col-form-label">Job category<span className='mustStar'>*</span></label>
                        <div className="col-sm-9">
                            <select className="form-select sailors-Input-field bg-white" value={jobCategory} onChange={(e) => { setJobCategory(e.target.value) }} >
                                {jobCategoryList.length > 0  && <option>Select Job Category</option>}
                                {jobCategoryList.length > 0 ? jobCategoryList?.map((item, index) => {
                                    return (
                                        <option key={index.toString()} value={item?.id} >{item?.name}</option>
                                    )
                                }) : <option>!Opps check the Internet</option>}
                            </select>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label className="col-sm-3 col-form-label">Job Type<span className='mustStar'>*</span></label>
                        <div className="col-sm-9">
                            <select className="form-select sailors-Input-field bg-white" value={jobtype} onChange={(e) => { setJobType(e.target.value) }}>
                                {jobTypeList.length > 0 && <option>Select Job Type</option>}
                                {jobTypeList.length > 0 ? jobTypeList?.map((item, index) => {
                                    return (
                                        <option value={item.value} >{item?.lable.toUpperCase()}</option>
                                    )
                                }) : <option>!Opps check the Internet</option>}
                            </select>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label className="col-sm-3 col-form-label">Department<span className='mustStar'>*</span></label>
                        <div className="col-sm-9">
                            <select className="form-select sailors-Input-field bg-white" value={department} onChange={(e) => { setDepartment(e.target.value) }}>
                                <option>Select Job Type</option>
                                <option value='deck'>Deck</option>
                                <option value='engine'>Engine</option>
                                <option value='canteen'>Canteen</option>
                            </select>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label className="col-sm-3 col-form-label">Select Ship Type<span className='mustStar'>*</span></label>
                        <div className="col-sm-9">
                            <select className="form-select sailors-Input-field bg-white" value={selectedShips} onChange={(e) => { setSelectedShips(e.target.value) }}>
                                {allShipsData.length > 0  && <option>Select Ship Type</option>}
                                {allShipsData.length > 0 ? allShipsData?.map((item, index) => {
                                    return (
                                        <option value={item?.id} style={{ height: "50px", cursor: "pointer" }} >{item?.name}</option>
                                    )
                                }) : <option>!Opps check the Internet</option>}
                            </select>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label className="col-sm-3 col-form-label">Skills<span className='mustStar'>*</span></label>
                        <div className="col-sm-9">
                            <textarea type="text" value={skills} onInput={(e)=>handleInput(e)} onChange={(e) => setSkills(e.target.value)} rows="3" className="form-control sailors-Input-field" placeholder="Enter Skills" />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label className="col-sm-3 col-form-label">Requirements<span className='mustStar'>*</span></label>
                        <div className="col-sm-9">
                            <textarea  value={requirements} onInput={(e)=>handleInput(e)} onChange={(e) => {setRequirements(e.target.value)}} type="text" rows="3" className="form-control sailors-Input-field" placeholder="Enter Requirements" />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label className="col-sm-3 col-form-label">Responsibilities<span className='mustStar'>*</span></label>
                        <div className="col-sm-9">
                            <textarea value={responsibilities} onInput={(e)=>handleInput(e)} onChange={(e) => setResponsibilities(e.target.value)} type="text" rows="3" className="form-control sailors-Input-field" placeholder="Enter Responsibilities" />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label className="col-sm-3 col-form-label">Description<span className='mustStar'>*</span></label>
                        <div className="col-sm-9">
                            <textarea value={moduleDescription} onChange={(e) => setModuleDescription(e.target.value)} type="text" rows="3" className="form-control sailors-Input-field" placeholder="Enter Description" />
                        </div>
                    </div>
                    
                    <div className="row mb-3">
                        <label className="col-sm-3 col-form-label">Job Status<span className='mustStar'>*</span></label>
                        <div className="col-sm-5 col-xl-3 col-xs-5 d-flex flex-wrap align-items-center">
                            <label className="col-sm-6 form-label d-flex flex-row justify-content-center align-items-center">Active</label>
                            <input type="checkbox"  onChange={(e) => setJobStatus(!jobStatus)} checked={jobStatus} style={{width:"25px"}}/>
                        </div>
                    </div>

                    <div className="row mb-3 ">
                        <label className="col-sm-3 col-form-label">Who Can Apply?<span className='mustStar'>*</span></label>
                        <div className="col-sm-4 d-flex align-items-center justify-content-start" >
                            <label className="col-sm-6 form-label d-flex flex-row justify-content-center align-items-center" >Seafarer</label>
                            <input type="checkbox" checked={forSeafarer} onChange={(e) => setForSeafarer(!forSeafarer)} style={{width:"25px"}}/>
                        </div>
                        <div className="col-sm-5 col-xl-3 col-xs-5 d-flex  align-items-center">
                            <label className="col-sm-5 form-label d-flex flex-row justify-content-center align-items-center">Fresher</label>
                            <input type="checkbox" checked={forFresher} onChange={(e) => setForFresher(!forFresher)}  style={{width:"25px"}}/>
                        </div>
                    </div>
                    <div className="row mb-3 d-flex flex-row">
                        <label className="col-sm-3 col-form-label">OnShore / OffShore<span className='mustStar'>*</span></label>
                        <div className="col-sm-4 d-flex align-items-center justify-content-start align-items-center" style={{display:'flex',alignItems:'center',justifyContent:"start"}}>
                            <label className="col-sm-6 form-label d-flex flex-row justify-content-center align-items-center" style={{maxWidth:"130px"}}>OnShore</label>
                            <input type="radio" name='shore' checked={envType == 'shore' ? true : false} onClick={() => setEnvType('shore')}  style={{width:"25px"}}/>
                        </div>
                        <div className="col-sm-5 d-flex align-items-center justify-content-start  align-items-center">
                            <label className="col-sm-6 form-label d-flex flex-row justify-content-center align-items-center">OffShore</label>
                            <input type="radio" name='shore' checked={envType == 'sea' ? true : false} onClick={() => setEnvType('sea')}  style={{width:"25px"}}/>
                        </div>
                    </div>
                   
                    <div className="row mb-3">
                        <label className="col-sm-3 col-form-label">Period<span className='mustStar'>*</span></label>
                        <div className="col-sm-9 d-flex justify-content-around">
                            <input type='text' style={{ maxWidth: "auto" }} placeholder='From date 🗓️' className='dateInput w-100 form-control sailors-Input-field' onFocus={(e) => (e.target.type = 'date')} value={from_date} onBlur={(e) => {
                                if (from_date == "") {
                                    e.target.type = 'text'
                                }
                            }} onChange={(e) => setFromDate(e.target.value)} />
                            <p className='w-25 m-2 d-flex flex-row justify-content-center align-items-center' style={{maxWidth:"30px"}}>To</p>
                            <input type='text' style={{ maxWidth: "auto" }} placeholder='To date 🗓️' className='dateInput form-control w-100 sailors-Input-field' value={to_date} onChange={(e) => { setToDate(e.target.value) }} onFocus={(e) => (e.target.type = 'date')} onBlur={(e) => {
                                if (to_date == "") {
                                    e.target.type = 'text'
                                }
                            }} />
                        </div>
                    </div>


                    <div className="row mb-3">
                        <label className="col-sm-3 col-form-label">Min Experience<span className='mustStar'>*</span></label>
                        <div className="col-sm-5 col-xl-3 col-xs-5 w-75 d-flex flex-row align-items-center" style={{width:"100%"}}>
                            <input type="number" value={minValidityValue} onChange={(e) => setMinValidityValue(e.target.value)} className="form-control sailors-Input-field" placeholder='Enter Value' style={{minWidth:"200px"}}/>
                            <select className='w-100 sailors-Input-field' value={minValidityUnit} onChange={(e) => setMinValidityUnit(e.target.value)} style={{minWidth:"220px",marginLeft:"5px"}}>
                                <option>Select Month/year</option>
                                <option value={'months'}>Month</option>
                                <option value={'years'}>year</option>
                                <option value={'weeks'}>Week</option>
                                <option value={'days'}>Day</option>

                            </select>
                        </div>
                    </div>


                    <div className="row mb-3">
                        <label className="col-sm-3 col-form-label">Max Experience<span className='mustStar'>*</span></label>
                        <div className="col-sm-5 col-xl-3 col-xs-5 w-75 d-flex flex-row align-items-center" style={{width:"100%"}}>
                            <input type="number" value={maxValidityValue} onChange={(e) => setMaxValidityValue(e.target.value)} className="form-control sailors-Input-field" placeholder='Enter Value' style={{minWidth:"200px"}}/>
                            <select className='w-100 sailors-Input-field' value={maxValidityUnit} onChange={(e) => setMaxValidityUnit(e.target.value)} style={{minWidth:"220px",marginLeft:"5px"}}>
                                <option>Select Month/year</option>
                                <option value={'months'}>Month</option>
                                <option value={'years'}>year</option>
                                <option value={'weeks'}>Week</option>
                                <option value={'days'}>Day</option>

                            </select>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label className="col-sm-3 col-form-label">Job Doc</label>
                        <div className="w-50 col-sm-5 col-xl-3 col-xs-5 d-flex flex-wrap align-items-center">
                            <input type="file" onChange={handleFileChange}/>
                        </div>
                    </div>
                    
                    
                    <div className='form-btns' style={{ marginBottom: "20px", marginTop: "50px" }}>
                        <button type="submit" onClick={submitCourse} className="btn" style={{maxHeight:"40px"}}>{isEdit ? "Update" : "Save"}</button>
                        <button onClick={handleCancel} type='button' className="btn btn-OnboardForm" style={{maxHeight:"40px",border:'1px solid #005A80'}}>Cancel</button>
                    </div>
                </form>
            </div>


            {/* <div className='table-container'>
                <div className='allDropDown allField-learning-courses'>

                    <span className='add-course-container add-course-country-container' style={{ maxWidth: "100%", padding: "0px 20px" }}>
                        <p style={{ maxWidth: "231px" }}>Select Country</p>
                        <div className="dropdown dropDown-locality dropDown-subLearning-Courses" style={{ maxWidth: "928px", marginRight: "-22px" }}>
                            <button type="button" className={width < 500 ? "btn drdpbtn form-control sailors-Input-field col" : "btn form-control drdpbtn sailors-Input-field row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white", maxWidth: "100%" }}>
                                {getCountyById(country)}<FaCaretDown style={{ width: "15px" }} />
                            </button>
                            <div className="dropdown-menu" style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "95%", height: "200px", overflowY: 'scroll' }}>
                                <div className='searchBar-Container' style={{ maxHeight: "50px" }}>

                                    <div className="input-group position-sticky" style={{ marginBottom: "-100px", paddingLeft: "10px" }}>
                                        <input className='position-static form-control' type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search ......" aria-label="Recipient's username" />
                                    </div>

                                </div>
                                {search == '' && <React.Fragment>{countryList.length > 0 ? countryList?.map((item, index) => {
                                    return (
                                        <div className="dropdown-item" onClick={() => setCountry(item.id)} style={{ height: "50px", cursor: "pointer" }} >{item?.name}</div>
                                    )
                                }) : <center style={{ marginTop: "10%" }}>!Opps check the Internet</center>}</React.Fragment>}

                                {search !== '' && <>{searchData.length > 0 ? searchData.reverse()?.map((item, index) => {
                                    return (
                                        <div className="dropdown-item" onClick={() => setCountry(item.id)} style={{ height: "50px", cursor: "pointer" }} >{item.name}</div>
                                    )
                                }) : <center style={{ marginTop: "10%" }}>No Match</center>}</>}

                            </div>
                        </div>
                    </span>

                    <span className='d-flex add-course-container justify-content-center' style={{ maxWidth: "100%", padding: "10px" }}>
                        <p style={{ maxWidth: "231px" }}>Job Title</p>
                        <input type='text' value={jobTitle} onChange={(e) => setJobTitle(e.target.value)} className='form-control sailors-Input-field' style={{ maxWidth: "1024px" }} />
                    </span>

                    <span className='add-course-container add-course-country-container' style={{ maxWidth: "100%", padding: "20px" }}>
                        <p style={{ maxWidth: "231px", textAlign: 'start' }}>Job Role</p>
                        <div className="dropdown dropDown-locality dropDown-subLearning-Courses" style={{ maxWidth: "978px", marginRight: "-23px", marginLeft: "5px" }}>
                            <button type="button" className={width < 500 ? "btn drdpbtn form-control sailors-Input-field col" : "btn form-control drdpbtn sailors-Input-field row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white", maxWidth: "100%" }}>
                                {getJobRoleById(jobRole)}<FaCaretDown style={{ width: "15px" }} />
                            </button>
                            <div className="dropdown-menu" style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "300px", height: "200px", overflowY: 'scroll' }}>

                                {jobRoleList.length > 0 ? jobRoleList?.map((item, index) => {
                                    return (
                                        <div className="dropdown-item" onClick={() => { setJobRole(item.id) }} style={{ height: "50px", cursor: "pointer" }} >{item?.name}</div>
                                    )
                                }) : <center style={{ marginTop: "10%" }}>!Opps check the Internet</center>}
                            </div>
                        </div>
                    </span>

                    <span className='add-course-container add-course-country-container' style={{ maxWidth: "100%", padding: "20px" }}>
                        <p style={{ maxWidth: "231px", textAlign: 'start' }}>Job Category</p>
                        <div className="dropdown dropDown-locality dropDown-subLearning-Courses" style={{ maxWidth: "978px", marginRight: "-23px", marginLeft: "5px" }}>
                            <button type="button" className={width < 500 ? "btn drdpbtn form-control sailors-Input-field col" : "btn form-control drdpbtn sailors-Input-field row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white", maxWidth: "100%" }}>
                                {getJobCategoryById(jobCategory)}<FaCaretDown style={{ width: "15px" }} />
                            </button>
                            <div className="dropdown-menu" style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "300px", height: "200px", overflowY: 'scroll' }}>

                                {jobCategoryList.length > 0 ? jobCategoryList?.map((item, index) => {
                                    return (
                                        <div className="dropdown-item" onClick={() => { setJobCategory(item.id) }} style={{ height: "50px", cursor: "pointer" }} >{item?.name}</div>
                                    )
                                }) : <center style={{ marginTop: "10%" }}>!Opps check the Internet</center>}

                            </div>
                        </div>
                    </span>

                    <span className='add-course-container add-course-country-container' style={{ maxWidth: "100%", padding: "20px" }}>
                        <p style={{ maxWidth: "231px", textAlign: 'start' }}>Select Ship Type</p>
                        <div className="dropdown dropDown-locality dropDown-subLearning-Courses" style={{ maxWidth: "978px", marginRight: "-23px", marginLeft: "5px" }}>
                            <button type="button" className={width < 500 ? "btn drdpbtn form-control sailors-Input-field col" : "btn form-control drdpbtn sailors-Input-field row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white", maxWidth: "100%" }}>
                                {getShipById(selectedShips)}<FaCaretDown style={{ width: "15px" }} />
                            </button>
                            <div className="dropdown-menu" style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "300px", height: "200px", overflowY: 'scroll' }}>

                                {allShipsData.length > 0 ? allShipsData?.map((item, index) => {
                                    return (
                                        <div className="dropdown-item" onClick={() => { setSelectedShips(item.id) }} style={{ height: "50px", cursor: "pointer" }} >{item?.name}</div>
                                    )
                                }) : <center style={{ marginTop: "10%" }}>!Opps check the Internet</center>}

                            </div>
                        </div>
                    </span>

                    <span className='add-course-container add-course-country-container' style={{ maxWidth: "100%", padding: "20px" }}>
                        <p style={{ maxWidth: "231px" }}>Job Type</p>
                        <div className="dropdown dropDown-locality dropDown-subLearning-Courses" style={{ maxWidth: "978px", marginRight: "-23px", marginLeft: "5px" }}>
                            <button type="button" className={width < 500 ? "btn drdpbtn form-control sailors-Input-field col" : "btn form-control drdpbtn sailors-Input-field row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white", maxWidth: "100%" }}>
                                {jobtype.toUpperCase()}<FaCaretDown style={{ width: "15px" }} />
                            </button>
                            <div className="dropdown-menu" style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "300px", height: "200px", overflowY: 'scroll' }}>
                                {jobTypeList.length > 0 ? jobTypeList?.map((item, index) => {
                                    return (
                                        <div className="dropdown-item" onClick={() => { setJobType(item.value) }} style={{ height: "50px", cursor: "pointer" }} >{item?.lable.toUpperCase()}</div>
                                    )
                                }) : <center style={{ marginTop: "10%" }}>!Opps check the Internet</center>}

                            </div>
                        </div>
                    </span>

                    <span className='d-flex add-course-container justify-content-center' style={{ maxWidth: "100%", padding: "10px" }}>
                        <p style={{ maxWidth: "231px" }}>Skills</p>
                        <input type='text' value={skills} onChange={(e) => setSkills(e.target.value)} className='form-control sailors-Input-field' style={{ maxWidth: "1024px" }} />
                    </span>

                    <span className='d-flex add-course-container justify-content-center' style={{ maxWidth: "100%", padding: "10px" }}>
                        <p style={{ maxWidth: "231px" }}>Requirements</p>
                        <Textarea
                            placeholder='Here is a Job Responsibilities'
                            size='sm'
                            resize={'vertical'}
                            className='form-control sailors-Input-field'
                            value={requirements}
                            onInput={(e)=>handleInput(e)}
                            onChange={(e) => {setRequirements(e.target.value)}}
                        />
                    </span>

                    <span className='d-flex add-course-container justify-content-center' style={{ maxWidth: "100%", padding: "10px" }}>
                        <p style={{ maxWidth: "231px" }}>Responsibilities</p>
                        <Textarea
                            placeholder='Here is a Job Responsibilities'
                            size='sm'
                            resize={'vertical'}
                            className='form-control sailors-Input-field'
                            value={responsibilities}
                            onChange={(e) => setResponsibilities(e.target.value)}
                        />
                    </span>

                    <span className='d-flex add-course-container justify-content-center' style={{ maxWidth: "100%", padding: "10px" }}>
                        <p style={{ maxWidth: "231px" }}>Description</p>
                        <Textarea
                            placeholder='Here is a Job Description'
                            size='sm'
                            resize={'vertical'}
                            className='form-control sailors-Input-field'
                            value={moduleDescription}
                            onChange={(e) => setModuleDescription(e.target.value)}
                        />
                    </span>

                    <span className='d-flex add-course-container justify-content-center' style={{ maxWidth: "100%", padding: "10px" }}>
                        <p style={{ maxWidth: "231px" }}>Job Status</p>
                        <div className='form-control sailors-Input-field' style={{ display: "flex", justifyContent: 'flex-start' }}>
                            <label style={{ maxWidth: "150px" }}>{jobStatus ? 'Active' : 'Inactive'}</label>
                            <input type='checkbox' checked={jobStatus} onChange={(e) => setJobStatus(!jobStatus)} style={{ maxWidth: "24px" }} />
                        </div>
                    </span>

                    <span className='d-flex add-course-container justify-content-center align-items-center' style={{ maxWidth: "100%", padding: "10px" }}>
                        <p style={{ maxWidth: "231px" }}>Who Can Apply?</p>
                        <div className='form-control sailors-Input-field' style={{ display: "flex", justifyContent: 'flex-start', alignItems: 'start' }}>
                            <label style={{ maxWidth: "150px" }}>Seafarer</label>
                            <input type='checkbox' checked={forSeafarer} onChange={(e) => setForSeafarer(!forSeafarer)} style={{ maxWidth: "24px" }} />
                            <label style={{ maxWidth: "150px", marginLeft: "20px" }}>Fresher</label>
                            <input type='checkbox' checked={forFresher} onChange={(e) => setForFresher(!forFresher)} style={{ maxWidth: "24px" }} />
                        </div>
                    </span>

                    <span className='d-flex add-course-container justify-content-center align-items-center' style={{ maxWidth: "100%", padding: "10px" }}>
                        <p style={{ maxWidth: "231px" }}>OnShore/OffShore</p>
                        <div className='form-control sailors-Input-field' style={{ display: "flex", justifyContent: 'flex-start', alignItems: 'start' }}>
                            <label style={{ maxWidth: "150px" }}>OnShore</label>
                            <input type='radio' name='shore' value='shore' checked={envType == 'shore' ? true : false} onClick={() => setEnvType('shore')} style={{ maxWidth: "24px" }} />
                            <label style={{ maxWidth: "150px", marginLeft: "20px" }}>OffShore</label>
                            <input type='radio' name='shore' value='sea' checked={envType == 'sea' ? true : false} onClick={() => setEnvType('sea')} style={{ maxWidth: "24px" }} />
                        </div>
                    </span>

                    <span className='d-flex add-course-container justify-content-center' style={{ maxWidth: "100%", padding: "10px" }}>
                        <p style={{ maxWidth: "231px" }}>Period</p>
                        <div className='form-control sailors-Input-field' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <input type='text' style={{ maxWidth: "180px" }} placeholder='From date' className='dateInput' onFocus={(e) => (e.target.type = 'date')} value={from_date} onBlur={(e) => {
                                if (from_date == "") {
                                    e.target.type = 'text'
                                }
                            }} onChange={(e) => setFromDate(e.target.value)} />
                            <span style={{ maxWidth: "100px", textAlign: 'center' }}>To</span>
                            <input type='text' style={{ maxWidth: "180px" }} className='dateInput' placeholder='To Date' value={to_date} onChange={(e) => { setToDate(e.target.value) }} onFocus={(e) => (e.target.type = 'date')} onBlur={(e) => {
                                if (to_date == "") {
                                    e.target.type = 'text'
                                }
                            }} />
                        </div>
                    </span>

                    <span className='add-course-container add-course-country-container' style={{ maxWidth: "100%", padding: "0px 20px" }}>
                        <p style={{ maxWidth: "231px" }}>Min Experience</p>
                        
                        <div className="dropdown dropDown-locality dropDown-subLearning-Courses" style={{ maxWidth: "928px", marginRight: "-22px" }}>
                            <button type="button" className={width < 500 ? "btn drdpbtn form-control sailors-Input-field col" : "btn form-control drdpbtn sailors-Input-field row"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: "white", maxWidth: "100%" }}>
                                {minValidityValue}{" " + minValidityUnit}<FaCaretDown style={{ width: "15px" }} />
                            </button>
                            <div className="dropdown-menu" style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "95%", height: "200px", overflowY: 'scroll' }}>
                                <div className='searchBar-Container' style={{ maxHeight: "50px" }}>

                                    <div className="input-group position-sticky" style={{ marginBottom: "-100px", paddingLeft: "10px" }}>
                                        <input className='position-static form-control' type="number" value={minValidityValue} onChange={(e) => setMinValidityValue(e.target.value)} placeholder="value..." aria-label="Recipient's username" />
                                    </div>

                                </div>
                                {["months", "years", "weeks", "days"]?.map((item, index) => {
                                    return (
                                        <div className="dropdown-item" onClick={() => setMinValidityUnit(item)} style={{ height: "50px", cursor: "pointer" }} >{item.toUpperCase()}</div>
                                    )
                                })}

                            </div>
                        </div>
                    </span>

                    <span className='add-course-container add-course-country-container' style={{ maxWidth: "100%", padding: "0px 20px" }}>
                        <p style={{ maxWidth: "231px" }}>Max Experience</p>
                        
                        <div className="dropdown dropDown-locality dropDown-subLearning-Courses" style={{ maxWidth: "928px", marginRight: "-22px" }}>
                            <button type="button" className={width < 500 ? "btn drdpbtn form-control sailors-Input-field col" : "btn form-control drdpbtn sailors-Input-field row"} data-toggle="dropdown" style={{ backgroundColor: "white", maxWidth: "100%" }}>
                                {maxValidityValue}{" " + maxValidityUnit}<FaCaretDown style={{ width: "15px" }} />
                            </button>
                            <div className="dropdown-menu" style={{ boxShadow: "0px 8px 32px 0px rgba(31, 38, 135, 0.37)", width: "95%", height: "200px", overflowY: 'scroll' }}>
                                <div className='searchBar-Container' style={{ maxHeight: "50px" }}>

                                    <div className="input-group position-sticky" style={{ marginBottom: "-100px", paddingLeft: "10px" }}>
                                        <input className='position-static form-control' type="number" value={maxValidityValue} onChange={(e) => setMaxValidityValue(e.target.value)} placeholder="value..." />
                                    </div>

                                </div>
                                {["months", "years", "weeks", "days"]?.map((item, index) => {
                                    return (
                                        <div className="dropdown-item" onClick={() => setMaxValidityUnit(item)} style={{ height: "50px", cursor: "pointer" }} >{item.toUpperCase()}</div>
                                    )
                                })}

                            </div>
                        </div>
                    </span>

                    <span className='d-flex add-course-container justify-content-center' style={{ maxWidth: "100%", padding: "10px" }}>
                        <p style={{ maxWidth: "231px" }}> Job Document</p>
                        <input type='file' onChange={handleFileChange} />
                    </span>
                </div>
                <div className='form-btns' style={{ marginBottom: "10px" }}>
                    <button type="submit" onClick={submitCourse} className="btn">{isEdit ? "Update" : "Save"}</button>
                    <button onClick={handleCancel} className="btn btn-OnboardForm">Cancel</button>
                </div>
            </div> */}
            
            <div className='instituteOnboarding-list-view' style={{ marginTop: "20px" }}>
                <div className='headerPart'>
                    <h4>Job List</h4>
                </div>
            </div>
            <LazyLoad offset={30}>
                {loading ?
                    <div style={{ maxWidth: "100px", maxHeight: "100px" }}>

                        <BallTriangle
                            height={20}
                            width={20}
                            radius={5}
                            color="red"
                            ariaLabel="ball-triangle-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                        <span>Loading...</span>
                    </div>

                    : <div className='table-container'>

                        {
                            <React.Fragment>
                                {allPostedJobs.length > 0 ?
                                    <div className='table-Data'>
                                        <table className='table'>
                                            <thead>
                                                <tr style={{ display: 'flex', justifyContent: "flex-start", color:"#005A80" }}>
                                                    <th style={{ fontWeight: "700", maxWidth: "80px", color:"#005A80" }}>Sr No.</th>
                                                    <th style={{ fontWeight: "700", textAlign: 'start', color:"#005A80" }}>Job Title</th>
                                                    <th style={{ fontWeight: "700", textAlign: 'start', color:"#005A80" }}>Country</th>
                                                    <th style={{ fontWeight: "700", textAlign: 'start', color:"#005A80" }}>Job Type</th>
                                                    <th style={{ fontWeight: "700", textAlign: 'start', color:"#005A80" }}>shore/OffShore</th>
                                                    <th style={{ fontWeight: "700", color:"#005A80" }}>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {allPostedJobs.map((item, index) => {
                                                return (
                                                    <tr className='' style={{ display: 'flex', justifyContent: "space-around", borderBottom:'1px solid #00000099' }}>
                                                        <th scope="row">{index + 1}</th>
                                                        <td style={{ textAlign: 'start' }} >{item?.job_title}</td>
                                                        <td style={{ textAlign: 'start' }} >{getCountyById(item?.country)}</td>
                                                        <td style={{ textAlign: 'start' }} >{showJobType(item?.job_type)}</td>
                                                        <td style={{ textAlign: 'start' }} >
                                                            {item?.work_environment_type == "sea" ? "OffShore" : "onShore"}
                                                        </td>
                                                        <td >
                                                            <div style={{ cursor: "pointer", display: 'flex', flexDirection: "row", justifyContent: 'start' }}>
                                                              
                                                                    <FaPen onClick={() => editData(item)} data-toggle="modal" data-target="#instituteDetailsModal" title='Details' style={{ width: "30px", color: "#0CB1C0", border: "1px solid gray", padding: "5px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px" }} /> <RiDeleteBin6Line onClick={() => deleteModule(item.id)} title='Delete' style={{ width: "30px", color: "#F84F31", border: "1px solid gray", padding: "5px", borderTopRightRadius: "10px", borderBottomRightRadius: "10px" }} />
                                                             
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}                                                
                                            </tbody>


                                        </table>
                                    </div>
                                    :
                                    <center>No Data found</center>}
                            </React.Fragment>
                        }


                    </div>}
            </LazyLoad>

        </div>
    )
}

export default JobpostCompany;
