import axios from "axios";
import { base_url } from "../utils/endpoints/endpoints";

const stringConcatDateRange = (fromDate, toDate, range) => {
  const params = new URLSearchParams();

  if (range) params.append("range", range);
  if (fromDate) params.append("start_date", fromDate);
  if (toDate) params.append("end_date", toDate);

  return params.toString() ? `/?${params.toString()}` : "";
};

// Create an Axios instance with the base URL and default headers
const axiosInstance = axios.create({
  baseURL: base_url,
  headers: {
    "Content-type": "application/json",
  },
});

// Intercept requests to include the Authorization header
axiosInstance.interceptors.request.use((config) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const access = token?.access;
  if (access) {
    config.headers["Authorization"] = `Bearer ${access}`;
  }
  return config;
});

export const GetOnboardingInstitute = async (type) => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/web/users/?user_type=${type}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const GetOnboardingCompany = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/web/users/?user_type=company&status=accepted`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const GetLearningCourses = async (status) => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/web/course/?status=${status}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getLearningCourseSearch = async (search) => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/web/course/?search=${search}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getCountries = async () => {
  try {
    const response = await axiosInstance.get(`/api/v1/common/countries/`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getStates = async (id) => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/common/states/?country_id=${id}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getCities = async (id) => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/common/cities/?state_id=${id}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getCandidateList = async (status) => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/web/users/?user_type=seafarer&status=${status}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getCandidateFresherList = async (status) => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/web/users/?user_type=fresher&status=${status}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getInstituteList = async (status) => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/web/users/?user_type=institute&limit=10&status=${status}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getRejectedSeafarerList = async (status) => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/web/users/?user_type=seafarer&limit=10&status=${status}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getRejectedFresherList = async (status) => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/web/users/?user_type=seafarer&limit=10&status=${status}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getCompanyList = async (status) => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/web/users/?user_type=company&status=${status}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getJobCategoryList = async () => {
  try {
    const response = await axiosInstance.get(`/api/v1/web/job/job_category/`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getShipTypeList = async () => {
  try {
    const response = await axiosInstance.get(`/api/v1/web/users/ship_types/`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getRolesAndRank = async () => {
  try {
    const response = await axiosInstance.get(`/api/v1/web/job/job_roles`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getSubjects = async () => {
  try {
    const response = await axiosInstance.get(`/api/v1/web/course/subjects/`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getBlockedStatus = async (id) => {
  try {
    const response = await axiosInstance.get(`/api/v1/web/users/${id}/block`);
    return response.data.is_blocked_user;
  } catch (error) {
    throw error;
  }
};

export const getAllCreateCertificate = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/web/users/certificate_types/`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllCertificateExpiry = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/web/users/certificate_expiry/`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getDocumentsExpiry = async () => {
  try {
    const response = await axiosInstance.get(
      "/api/v1/web/utilities/todo_notification_schedule/"
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllRankQuestions = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/web/utilities/questions/`
    );
    console.log(response, "questions Data");
    return response;
  } catch (error) {
    throw error;
  }
};

export const getSubCourseUploadDocuments = async (id) => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/web/course/modules/course_module_docs/?module_id=${id}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllNewsAndAnnouncements = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/web/utilities/news_announcements/`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getModuleDocByModuleId = async (id) => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/web/course/modules/course_module_docs/?module_id=${id}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllAds = async () => {
  try {
    const response = await axiosInstance.get(`/api/v1/web/utilities/ads/`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getScheduledCourses = async (fromDate, toDate, range) => {
  const queryString = stringConcatDateRange(fromDate, toDate, range);
  try {
    const response = await axiosInstance.get(
      `/api/v1/web/course/course_details${queryString}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const stringConcat = (search, status, fromDate, toDate) => {
  const params = new URLSearchParams();

  if (search) params.append("search", search);
  if (status) params.append("status", status);
  if (fromDate) params.append("start_date", fromDate);
  if (toDate) params.append("end_date", toDate);

  return params.toString() ? `/?${params.toString()}` : "";
};

export const getEnrollData = async (search, status, fromDate, toDate) => {
  try {
    const queryString = stringConcat(search, status, fromDate, toDate);
    const response = await axiosInstance.get(
      `/api/v1/web/course/institute_course_enroll${queryString}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getOneInstituteData = async (id) => {
  try {
    const response = await axiosInstance.get(`/api/v1/web/users/${id}/`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllPostedJobByAdmin = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/web/job/?created_by_admin=True`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllJobsByCompany = async () => {
  try {
    const response = await axiosInstance.get(`/api/v1/web/job/`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getResumeData = async (type) => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/web/job/job_applications/?status=${type}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAppliedUserData = async (id) => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/web/job/job_application/${id}/`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getFeesById = async (id) => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/web/course/${id}/course_fees/`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getCandidateDataByJobAndStatus = async (id, status) => {
  console.log(id, status, "idStatus");
  try {
    const response = await axiosInstance.get(
      `/api/v1/web/job/job_applications/?status=${status}&job_id=${id}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const courseCategoryApi = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/web/course/course_categories/`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const courseTypeApi = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/web/course/course_types/`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const requestCourseListInstitute = async (status) => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/web/course/?status=${status}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllCompanyShip = async () => {
  try {
    const response = await axiosInstance.get(`/api/v1/web/users/ship/`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllOperationType = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/web/users/operation_types/`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllOtherCompanies = async (search) => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/common/companies/?search=${search}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getQuestionByCourseModule = async (course, module) => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/web/utilities/questions/?course_id=${course}&module_id=${module}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getFeesByCourseDetails = async (id) => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/web/course/course_fee/${id}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getCompanyDashBoardData = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/web/users/company_dashboard/`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getInstituteDashBoardData = async (fromDate, toDate, range) => {
  try {
    const queryString = stringConcatDateRange(fromDate, toDate, range);
    const response = await axiosInstance.get(
      `/api/v1/web/course/institute_dashboard${queryString}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllCompanies = async () => {
  try {
    const response = await axiosInstance.get("/api/v1/common/companies/");
    return response;
  } catch (error) {
    throw error;
  }
};
export const getAllCompaniesSearch = async (search) => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/common/companies/?search=${search}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const loadMoreData = async (url) => {
  try {
    const response = await axiosInstance.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const Recruitmentfunnel = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/web/users/recruitment_funnel_dashboard/`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const InterestedStudentsData = async (fromDate, toDate, range) => {
  const queryString = stringConcatDateRange(fromDate, toDate, range);
  try {
    const response = await axiosInstance.get(
      `/api/v1/web/users/interested_students${queryString}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const GetAdminDashboard = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/web/users/admin_dashboard/`
    );
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

export const getFollowupHistory = async (id) => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/web/users/${id}/follow_up/`
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const searchStudent = async (searchType, search) => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/web/users/app_users/?search_type=${searchType}&search=${search}`
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
