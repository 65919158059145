import React, { useState } from 'react'
import { Pie } from "react-chartjs-2";
import { colors } from '../../../utils/variables/folderStructureFiles';

const CompanyChart = ({ userData, lableData, navigate }) => {
    
    const [chartData, setChartData] = useState({
        labels: lableData.map((data) => data),
        datasets: [
            {
                label: "Total Applicants ",
                data: userData.map((data) => data),
                backgroundColor: colors,
                border: "none",
                borderWidth: 2,
            }
        ]
    });

    const options = {
        aspectRatio:1.225,
        plugins: {
            title: {
                display: true,
                text: "All Jobs and Candidates"
            }
        }
    }


    return (
        <div className='courseChart mx-auto shadow-lg rounded'>
            {userData.length && lableData.length ? <center className='chartContainer' >
                <Pie
                    data={chartData}
                    options={options}
                />
            </center> : <h4 className='welcomeHeading chartContainer'>No Data Available</h4>}

        </div>
    )
}

export default CompanyChart;
