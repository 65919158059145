const sizes = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px',
  };

  export const devices = {
    mobileS: `(min-width: ${sizes.mobileS})`,
    mobileM: `(min-width: ${sizes.mobileM})`,
    mobileL: `(min-width: ${sizes.mobileL})`,
    tablet: `(max-width: ${sizes.tablet})`,
    laptop: `(min-width: ${sizes.laptop})`,
    laptopL: `(min-width: ${sizes.laptopL})`,
    desktop: `(min-width: ${sizes.desktop})`,
  };


  export const monthsData = [
    {value:1,month:'January'},
    {value:2,month:'February'},
    {value:3,month:'March'},
    {value:4,month:'April'},
    {value:5,month:'May'},
    {value:6,month:'June'},
    {value:7,month:'July'},
    {value:8,month:'August'},
    {value:9,month:'September'},
    {value:10,month:'October'},
    {value:11,month:'November'},
    {value:12,month:'December'},
  ]
export const jobTypeList = [
  {value:'full_time',lable:'Full Time'},
  {value:'part_time',lable:'Part Time'},
  {value:'contract', lable:'Contract'},
  {value:'freelance',lable:'Freelance'},
  {value:'internship',lable:'Internship'},
  {value:'volunteer', lable:'Volunteer'},
  {value:'temporary', lable:'Temporary'},
  {value:'remote', lable:'Remote'},
  {value:'onsite', lable:'Onsite'},
  {value:'other', lable:'Other'},
]

export const certificatesForExpiry = [
  {id:"passport", name:'Passport'},
  {id:"visa", name:'Visa'},
  {id:"seaman_book", name:'Seaman Book'},
  {id:"insurance", name:'Insurance'},
  {id:"course", name:'Course'},
  {id:"certificate", name:'Certificate'},
  {id:"subscription", name:'Subscription'},
  {id:"cdc", name:'CDC'},
]

export const shipData = [
  {value:'bulk_carrier',lable:'Bulk Carrier'},
  {value:'gear_bulk',lable:'Gear Bulk'},
  {value:'bunker_tanker',lable:'Bunker Tanker'},
  {value:'chemical_tanker',lable:'Chemical Tanker'},
  {value:'bitumen_tanker',lable:'Bitumen Tanker'},
  {value:'vlcc',lable:'VLCC'},
  {value:'vlgc',lable:'VLGC'},
  {value:'general_cargo',lable:'General Cargo'},
  {value:'oil_tanker',lable:'Oil Tanker'},
  {value:'mini_bulk_carrier',lable:'Mini Bulk Carrier'},
  {value:'tanker',lable:'Tanker'},
  {value:'product_tanker',lable:'Product Tanker'},
  {value:'survey_vessel',lable:'Survey Vessel'},
  {value:'ulcc',lable:'ULCC'},
  {value:'lng_carrier',lable:'LNG Carrier'},
  {value:'dp-II',lable:'DP - II'},
  {value:'ahts',lable:'AHTS'},
  {value:'ahts_dp-II',lable:'AHTS DP - II'},
] 

export const allowedTypes = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/webp',
  'image/gif',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'text/plain',
  'text/csv',
  'application/rtf',
  'application/msword',
    'application/doc',
    'application/docx',
    'application/ms-doc',
    'application/msword',
    "application/excel",
    "application/vnd.ms-excel",
    'application/x-excel',
    'application/x-msexcel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/mspowerpoint',
    'application/powerpoint',
    'application/vnd.ms-powerpoint',
    'application/x-mspowerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/pdf',
];

export const allowedTypesImages = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/webp',
  'image/gif',
]

export const colors = [
  "#1B4F72", "#154360", "#2E86C1", "#5DADE2", "#2980B9", "#2471A3", "#1A5276", "#0E6251", "#117A65", "#1ABC9C",
  "#16A085", "#148F77", "#0E6655", "#D4E6F1", "#85C1E9", "#AED6F1", "#3498DB", "#2E86C1", "#2874A6", "#1F618D",
  "#7FB3D5", "#5499C7", "#2980B9", "#2471A3", "#1B4F72", "#117A65", "#138D75", "#16A085", "#1ABC9C", "#48C9B0",
  "#F4D03F", "#F39C12", "#E67E22", "#D35400", "#E74C3C", "#C0392B", "#A93226", "#922B21", "#7B241C", "#641E16",
  "#FAD7A0", "#EDBB99", "#E59866", "#DC7633", "#D35400", "#BA4A00", "#AF601A", "#A04000", "#873600", "#6E2C00",
  "#F5B041", "#DC7633", "#D68910", "#D4AC0D", "#B7950B", "#9A7D0A", "#7D6608", "#6E2C00", "#5D6D7E", "#85929E",
  "#34495E", "#2C3E50", "#212F3C", "#1B2631", "#17202A", "#ABB2B9", "#808B96", "#566573", "#2E4053", "#283747",
  "#212F3C", "#1B2631", "#17202A", "#7B7D7D", "#626567", "#515A5A", "#424949", "#273746", "#1C2833", "#34495E",
  "#212F3C", "#2C3E50", "#2980B9", "#2E86C1", "#85C1E9", "#5DADE2", "#3498DB", "#154360", "#1A5276", "#2471A3",
  "#5D6D7E", "#85929E", "#34495E", "#2C3E50", "#212F3C", "#1B2631", "#17202A", "#ABB2B9", "#808B96", "#566573"
];


export const StudentsDummyData = [
  { name: "Ganesh Kumar", status: "FollowUp" },
  { name: "Amit Sharma", status: "Admitted" },
  { name: "Rohit Verma", status: "Non-Admitted" },
  { name: "Priya Singh", status: "FollowUp" },
  { name: "Anjali Mehta", status: "Admitted" },
  { name: "Rakesh Patel", status: "Non-Admitted" },
  { name: "Sunita Chauhan", status: "FollowUp" },
  { name: "Suresh Rao", status: "Admitted" },
  { name: "Vikas Gupta", status: "Non-Admitted" },
  { name: "Neha Kapoor", status: "FollowUp" },
  { name: "Vivek Pandey", status: "Admitted" },
  { name: "Kiran Desai", status: "Non-Admitted" },
  { name: "Sanjay Reddy", status: "FollowUp" },
  { name: "Ritu Yadav", status: "Admitted" },
  { name: "Pooja Jain", status: "Non-Admitted" },
  { name: "Manoj Joshi", status: "FollowUp" },
  { name: "Deepak Malhotra", status: "Admitted" },
  { name: "Aarti Kaur", status: "Non-Admitted" },
  { name: "Rajesh Nair", status: "FollowUp" },
  { name: "Simran Gill", status: "Admitted" },
  { name: "Anil Bhatia", status: "Non-Admitted" },
  { name: "Naveen Dutta", status: "FollowUp" },
  { name: "Preeti Tiwari", status: "Admitted" },
  { name: "Bhavna Saxena", status: "Non-Admitted" },
  { name: "Mohit Chawla", status: "FollowUp" },
  { name: "Swati Bansal", status: "Admitted" },
  { name: "Gaurav Aggarwal", status: "Non-Admitted" },
  { name: "Sneha Ranjan", status: "FollowUp" },
  { name: "Nitin Kumar", status: "Admitted" },
  { name: "Pankaj Arora", status: "Non-Admitted" },
  { name: "Shalini Pathak", status: "FollowUp" },
  { name: "Ashish Bhardwaj", status: "Admitted" },
  { name: "Kavita Sharma", status: "Non-Admitted" },
  { name: "Tarun Chopra", status: "FollowUp" },
  { name: "Rohini Mathur", status: "Admitted" },
  { name: "Vijay Khanna", status: "Non-Admitted" },
  { name: "Meena Agarwal", status: "FollowUp" },
  { name: "Ravi Shankar", status: "Admitted" },
  { name: "Sudhir Goel", status: "Non-Admitted" },
  { name: "Sonia Kohli", status: "FollowUp" },
  { name: "Arun Sharma", status: "Admitted" },
  { name: "Harshita Gupta", status: "Non-Admitted" },
  { name: "Rajiv Menon", status: "FollowUp" },
  { name: "Pallavi Rao", status: "Admitted" },
  { name: "Sandeep Singh", status: "Non-Admitted" },
  { name: "Sushma Reddy", status: "FollowUp" },
  { name: "Rahul Kapoor", status: "Admitted" },
  { name: "Prakash Mehta", status: "Non-Admitted" },
  { name: "Jaya Iyer", status: "FollowUp" },
  { name: "Sagar Deshmukh", status: "Admitted" },
  { name: "Lakshmi Nair", status: "Non-Admitted" },
  { name: "Ishita Aggarwal", status: "FollowUp" },
  { name: "Vishal Jain", status: "Admitted" },
  { name: "Priyanka Gupta", status: "Non-Admitted" },
  { name: "Rohan Singh", status: "FollowUp" },
  { name: "Neelam Saxena", status: "Admitted" },
  { name: "Aditya Verma", status: "Non-Admitted" },
  { name: "Usha Chauhan", status: "FollowUp" },
  { name: "Kunal Roy", status: "Admitted" },
  { name: "Shobha Sharma", status: "Non-Admitted" },
  { name: "Vinay Tiwari", status: "FollowUp" },
  { name: "Rachna Patel", status: "Admitted" },
  { name: "Siddharth Bhatnagar", status: "Non-Admitted" },
  { name: "Kalpana Joshi", status: "FollowUp" },
  { name: "Rajeev Kumar", status: "Admitted" },
  { name: "Madhuri Jain", status: "Non-Admitted" },
  { name: "Shivani Chopra", status: "FollowUp" },
  { name: "Anupam Sinha", status: "Admitted" },
  { name: "Bhupinder Singh", status: "Non-Admitted" },
  { name: "Renu Kapoor", status: "FollowUp" },
  { name: "Ashwini Desai", status: "Admitted" },
  { name: "Deepti Nair", status: "Non-Admitted" }
];

  const allFollowUp = StudentsDummyData.filter((item)=>item.status=='FollowUp');
  const allAdmittedData = StudentsDummyData.filter((item)=>item.status=='Admitted');
  const nonAdmittedData = StudentsDummyData.filter((item)=>item.status=='Non-Admitted')

export const followpDummyData = [
  {name:"Total Interested",percent:StudentsDummyData ,color:"#A09CE1"},
  {name:"Follow-up",percent:allFollowUp,color:"#E19FBA"},
  {name:"Admitted",percent:allAdmittedData,color:"#E59E18"},
  {name:"No Admission",percent:nonAdmittedData,color:"#50AF95"}
]
