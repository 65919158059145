// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.infoData{
    display: flex;
    justify-content: center;
    background-color: rgb(242, 170, 76);
    width: 100%;
    margin-left: auto;
}
.navbarContainer{
    max-height: 100px;
    position: fixed;
}
.horizontal-container{
    display: flex;
    flex-direction: row;
    overflow: none;
}
.adminDashboard-container{
    overflow: none;
}
.dashBoard-data-charts{
    display: flex;
    flex-direction: row;
    gap: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/Admin/pages/adminDashboard.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mCAAmC;IACnC,WAAW;IACX,iBAAiB;AACrB;AACA;IACI,iBAAiB;IACjB,eAAe;AACnB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,cAAc;AAClB;AACA;IACI,cAAc;AAClB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb","sourcesContent":["\n.infoData{\n    display: flex;\n    justify-content: center;\n    background-color: rgb(242, 170, 76);\n    width: 100%;\n    margin-left: auto;\n}\n.navbarContainer{\n    max-height: 100px;\n    position: fixed;\n}\n.horizontal-container{\n    display: flex;\n    flex-direction: row;\n    overflow: none;\n}\n.adminDashboard-container{\n    overflow: none;\n}\n.dashBoard-data-charts{\n    display: flex;\n    flex-direction: row;\n    gap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
