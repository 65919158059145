// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    max-width: 800px;
  }
  
  .table-custom {
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .table-custom thead th {
    background-color: #f8f9fa;
    font-weight: bold;
    color: #0056b3;
    border-bottom: 2px solid #dee2e6;
  }
  
  .table-custom tbody tr {
    border-bottom: 1px solid #dee2e6;
  }
  
  .table-custom tbody tr:hover {
    background-color: #f5f5f5;
  }
  
  .table-custom td, .table-custom th {
    vertical-align: middle;
    text-align: center;
  }
  
  .table-custom .btn {
    padding: 0;
  }`, "",{"version":3,"sources":["webpack://./src/Admin/pages/masters/Add_Department/addDepartment.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;IACzB,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;IAChB,wCAAwC;EAC1C;;EAEA;IACE,yBAAyB;IACzB,iBAAiB;IACjB,cAAc;IACd,gCAAgC;EAClC;;EAEA;IACE,gCAAgC;EAClC;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,UAAU;EACZ","sourcesContent":[".container {\n    max-width: 800px;\n  }\n  \n  .table-custom {\n    border-collapse: separate;\n    border-spacing: 0;\n    border-radius: 10px;\n    overflow: hidden;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  }\n  \n  .table-custom thead th {\n    background-color: #f8f9fa;\n    font-weight: bold;\n    color: #0056b3;\n    border-bottom: 2px solid #dee2e6;\n  }\n  \n  .table-custom tbody tr {\n    border-bottom: 1px solid #dee2e6;\n  }\n  \n  .table-custom tbody tr:hover {\n    background-color: #f5f5f5;\n  }\n  \n  .table-custom td, .table-custom th {\n    vertical-align: middle;\n    text-align: center;\n  }\n  \n  .table-custom .btn {\n    padding: 0;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
