import React, { Suspense, useState } from "react";
import ChartComponent from "../../../../company/pages/CompanyDashboard/chartComponent";
import InstitutePieChart from "../piechartInsitute/InstitutePieChart";
import { errorToast } from "../../../../utils/helperFunctions/HelperFunctions";

const SubscriptionCard = React.lazy(() => import("./SubscriptionCard"));

const ChartAndSubCard = React.memo(
  ({
    lableData,
    userData,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    range,
    setRange,
  }) => {
    const dateErrorFunction = () => {
      if (fromDate > toDate && fromDate !== "" && toDate !== "") {
        errorToast("From Date Should be smaller than To Date");
        setFromDate("");
        setToDate("");
      }
    };
    return (
      <div
        className="institute-dashboard-chartAndSubCard w-100 mb-6 mt-2"
        style={{ minHeight: "22em" }}
      >
        <div
          className="mb-6 w-100"
          style={{
            maxHeight: "28em",
            borderRadius: "20px",
            minheight: "20em",
            borderTop: "4px solid #63c3ec",
            background: "#FFFFFF",
            boxShadow: "-1px 3px 20px 0px #0000001A",
          }}
        >
          <div
            className="d-flex justify-content-between flex-row flex-wrap"
            style={{ maxHeight: "80px" }}
          >
            <p
              className="ms-4 mt-2 w-25"
              style={{
                fontWeight: "500",
                fontSize: "18px",
                color: "#337B99",
                textDecoration: "underline 1px #2A737A66",
                textUnderlineOffset: "10px",
              }}
            >
              Student in all courses
            </p>
            <div className="w-50 ">
              <input
                type="text "
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => {
                  if (fromDate == "") {
                    e.target.type = "text";
                  }
                  dateErrorFunction();
                }}
                value={fromDate}
                onChange={(e) => {
                  setFromDate(e.target.value);
                  setRange("");
                }}
                className="w-75 h-50 dateBox"
                placeholder="From Date 🗓️"
              />
              <input
                type="text"
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => {
                  if (toDate == "") {
                    e.target.type = "text";
                  }
                  dateErrorFunction();
                }}
                value={toDate}
                onChange={(e) => {
                  setToDate(e.target.value);
                  setRange("");
                }}
                className="w-50 h-50 dateBox"
                placeholder="To Date 🗓️"
              />

              <select
                className="w-50 m-1 h-25 p-1 rounded position-relative top-0 end-25"
                value={range}
                onChange={(e) => {
                  setRange(e.target.value);
                  setFromDate("");
                  setToDate("");
                }}
                style={{ border: "1px solid #005A80", maxHeight: "35px" }}
              >
                <option>Select Range</option>
                <option value="one_day">1D</option>
                <option value="one_week">1W</option>
                <option value="one_month">1M</option>
                <option value="three_month">3M</option>
                <option value="six_month">6M</option>
                <option value="one_year">1Y</option>
                <option value="three_year">3Y</option>
                <option value="five_year">5Y</option>
              </select>
            </div>
          </div>
          <div className="mt-5">
            <InstitutePieChart lableData={lableData} userData={userData} />
          </div>
        </div>
        <Suspense fallback={<>Loading...</>}>
          <SubscriptionCard />
        </Suspense>
      </div>
    );
  }
);

export default ChartAndSubCard;
