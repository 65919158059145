import React, { useEffect, useState } from "react";
import { getCompanyList, loadMoreData } from "../../../../../Apis/getApis";
import { BallTriangle } from "react-loader-spinner";
import ResponsivePagination from "react-responsive-pagination";
import { FaEye } from "react-icons/fa";
import Swal from "sweetalert2";
import { Modal } from "react-responsive-modal";

import { RiDeleteBin6Line } from "react-icons/ri";
import { deleteInstitute } from "../../../../../Apis/deleteApis";
import { updateBlockStatus } from "../../../../../Apis/updateApis";
import { AiOutlineClose } from "react-icons/ai";
import { base_url } from "../../../../../utils/endpoints/endpoints";
import {
  deleteToast,
  successToast,
} from "../../../../../utils/helperFunctions/HelperFunctions";
const VerifiedCompanies = () => {
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [count, setCount] = useState(1);

  const pageChange = (e) => {
    setTableData([]);
    setCurrentPage(e);
    let url = `${base_url}/api/v1/web/users/?user_type=company&status=accepted&page=${e}`;
    loadMoreData(url)
      .then((res) => {
        setTableData([...res?.data?.data?.results]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateUserBlockStatus = (item) => {
    if (selectedItem && selectedItem?.id === item?.id) {
      setSelectedItem();
    } else {
      setSelectedItem(item);
      Swal.fire({
        title: "Are you sure?",
        text: item?.is_blocked_user
          ? "Do you want to Unblock User ?"
          : "Do you want to Block User ?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: item?.is_blocked_user
          ? "Yes, Unblock User!"
          : "Yes, Block user!",
      }).then((result) => {
        if (result.isConfirmed) {
          updateBlockStatus(item.id)
            .then((res) => {
              console.log(res);
              getAllInstitutes();
            })
            .catch((err) => {
              console.log(err);
            });
          successToast(
            item?.is_blocked_user ? "User is Unblocked." : "User is blocked."
          );
        } else {
          setSelectedItem();
        }
      });
    }
  };

  const showModal = (item) => {
    console.log(item, "selectedUser");
    console.log("selectedUser");
    setSelectedUser(item);
    setVisible(true);
  };

  function getAllInstitutes() {
    getCompanyList("accepted")
      .then((res) => {
        console.log(res);
        setCount(res?.data?.data?.count);
        setLoading(false);

        setTableData(res?.data?.data?.results);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  useEffect(() => {
    setLoading(true);
    getAllInstitutes();
  }, []);

  useEffect(() => {
    let timer;
    if (search !== "") {
      timer = setTimeout(() => {
        let url = `${base_url}/api/v1/web/users/?user_type=company&search=${search}&status=accepted`;
        function getAllData(next) {
          if (next == null) {
            return;
          }

          loadMoreData(next)
            .then((res) => {
              setSearchData((prev) => [...prev, ...res?.data?.data?.results]);
              getAllData(res?.data?.data?.next);
            })
            .catch((err) => {
              console.log(err);
            });
        }

        loadMoreData(url)
          .then((res) => {
            setSearchData([...res?.data?.data?.results]);
            getAllData(res?.data?.data?.next);
          })
          .catch((err) => {
            console.log(err);
          });
      }, 1000);
    } else {
      setSearchData([]);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [search]);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You can revert Changes once you delete!",
      icon: "error",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete Company!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteInstitute(id)
          .then((res) => {
            console.log(res);
            getAllInstitutes();
          })
          .catch((err) => {
            console.log(err);
          });
        deleteToast("Company Deleted Successfully");
      }
    });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div
        className="input-group position-sticky"
        style={{ marginBottom: "50px", paddingLeft: "10px" }}
      >
        <input
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="form-control position-static"
          placeholder="Search ......🔍"
          aria-label="Recipient's username"
        />
      </div>
      {loading ? (
        <div style={{ maxWidth: "100px", maxHeight: "100px", margin: "auto" }}>
          <BallTriangle
            height={20}
            width={20}
            radius={5}
            color="red"
            ariaLabel="ball-triangle-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
          <span>Loading...</span>
        </div>
      ) : (
        <div className="table-container">
          {tableData?.length > 0 ? (
            <div className="table-Data">
              <table className="table">
                <thead>
                  <tr style={{ display: "flex", justifyContent: "flex-start" }}>
                    <th style={{ fontWeight: "700", maxWidth: "80px" }}>
                      Sr No.
                    </th>
                    <th style={{ fontWeight: "700", textAlign: "start" }}>
                      Company Name
                    </th>
                    <th style={{ fontWeight: "700" }}>Status</th>
                    <th style={{ fontWeight: "700" }}>Details</th>
                  </tr>
                </thead>
                <tbody>
                  {search == "" &&
                    tableData?.map((item, index) => {
                      return (
                        <tr
                          key={item.id}
                          className=""
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          <th scope="row">{index + 1}</th>
                          <td
                            className="tableText"
                            style={{
                              overflowWrap: "break-word",
                              maxWidth: "500px",
                              maxHeight: "100px",
                              display: "flex",
                              justifyContent: "start",
                              flexWrap: "wrap",
                            }}
                          >
                            {item?.first_name}
                          </td>
                          <td
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            onClick={() => updateUserBlockStatus(item)}
                          >
                            <img
                              loading="lazy"
                              src={
                                item?.is_blocked_user
                                  ? "/assets/commonAssets/toggleFalse.svg"
                                  : "/assets/commonAssets/toggleTrue.svg"
                              }
                              style={{ cursor: "pointer" }}
                              alt="logo"
                            />
                          </td>
                          <td>
                            <div
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                              }}
                            >
                              <FaEye
                                onClick={() => showModal(item)}
                                title="Details"
                                style={{
                                  width: "40px",
                                  height: "30px",
                                  color: "#0CB1C0",
                                  border: "1px solid gray",
                                  padding: "5px",
                                  borderTopLeftRadius: "10px",
                                  borderBottomLeftRadius: "10px",
                                }}
                              />
                              <RiDeleteBin6Line
                                onClick={() => handleDelete(item?.id)}
                                title="Delete"
                                style={{
                                  width: "40px",
                                  height: "30px",
                                  color: "#F84F31",
                                  border: "1px solid gray",
                                  padding: "5px",
                                  borderTopRightRadius: "10px",
                                  borderBottomRightRadius: "10px",
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  {search !== "" && searchData.length > 0 ? (
                    searchData?.map((item, index) => {
                      return (
                        <tr
                          key={item.id}
                          className=""
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          <th scope="row">{index + 1}</th>
                          <td>{item?.first_name}</td>
                          <td onClick={() => updateUserBlockStatus(item)}>
                            <img
                              loading="lazy"
                              src={
                                item?.is_blocked_user
                                  ? "/assets/commonAssets/toggleTrue.svg"
                                  : "/assets/commonAssets/toggleFalse.svg"
                              }
                              style={{ cursor: "pointer" }}
                              alt="logo"
                            />
                          </td>
                          <td>
                            <div
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                              }}
                            >
                              <FaEye
                                onClick={() => showModal(item)}
                                title="Details"
                                style={{
                                  width: "30px",
                                  color: "#0CB1C0",
                                  border: "1px solid gray",
                                  padding: "5px",
                                  borderTopLeftRadius: "10px",
                                  borderBottomLeftRadius: "10px",
                                }}
                              />
                              <RiDeleteBin6Line
                                title="Delete"
                                style={{
                                  width: "30px",
                                  color: "#F84F31",
                                  border: "1px solid gray",
                                  padding: "5px",
                                  borderTopRightRadius: "10px",
                                  borderBottomRightRadius: "10px",
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <>
                      {search !== "" && (
                        <center style={{ marginTop: "20px" }}>
                          No Match Found
                        </center>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            <center>No Data found</center>
          )}
        </div>
      )}

      <Modal open={visible} onClose={() => setVisible(false)} center={true}>
        <div className="candidateDetails-body-Container">
          <div className="details-row">
            <h6 className="card-title">Name</h6>
            <p>-</p>
            <h6>{selectedUser?.first_name}</h6>
          </div>
          <div className="details-row">
            <h6 className="card-title">Contact Person</h6>
            <p>-</p>
            <h6>{selectedUser?.contact_person}</h6>
          </div>

          <div className="details-row">
            <h6 className="card-title">Website</h6>
            <p>-</p>
            <h6>{selectedUser?.website}</h6>
          </div>
          <div className="details-row">
            <h6 className="card-title">Email</h6>
            <p>-</p>
            <h6>{selectedUser?.email}</h6>
          </div>
          <div className="details-row">
            <h6 className="card-title">Country</h6>
            <p>-</p>
            <h6>{selectedUser?.country}</h6>
          </div>
          <div className="details-row">
            <h6 className="card-title">Address</h6>
            <p>-</p>
            <h6>{selectedUser?.address1}</h6>
          </div>
          {selectedUser?.address2 !== "" && (
            <div className="details-row">
              <h6 className="card-title">Address Line2</h6>
              <p>-</p>
              <h6>{selectedUser?.address2}</h6>
            </div>
          )}
          <div className="details-row">
            <h6 className="card-title">PinCode</h6>
            <p>-</p>
            <h6>{selectedUser?.pin_code}</h6>
          </div>
          <div className="details-row">
            <h6 className="card-title">Mobile</h6>
            <p>-</p>
            <h6>{selectedUser?.mobile_number1}</h6>
          </div>

          <div className="details-row">
            <button
              className="logOutButton modalButton"
              onClick={() => {
                setVisible(false);
              }}
              style={{ backgroundColor: "#c06c84" }}
              data-dismiss="modal"
            >
              <AiOutlineClose
                style={{ width: "30px", height: "30px", marginLeft: "20px" }}
              />
              <span>Close</span>
            </button>
          </div>
        </div>
      </Modal>

      {tableData.length > 0 && (
        <div style={{ maxWidth: "50px", marginTop: "30px", margin: "auto" }}>
          <ResponsivePagination
            current={currentPage}
            total={Math.ceil(count / 20)}
            onPageChange={(e) => pageChange(e)}
          />
        </div>
      )}
    </div>
  );
};

export default VerifiedCompanies;
