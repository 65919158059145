import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../Apis/postApis";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setLoginUser } from "../../store/actions/commonSlice";
import { FaEye, FaEyeSlash, FaRegEnvelope } from "react-icons/fa";
import { IoMdKey } from "react-icons/io";
import {ReactComponent as LineIcon} from "./Line.svg"
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const notify = (message) => toast.success(message, { closeOnClick: false });
  const errorToast = () => toast.error("Invalid Credentials", { position: "top-center" });
  const errorToastBlankFields = (message) => toast.error(message, { closeOnClick: false });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.clear();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email === "" || password === "") {
      errorToastBlankFields("Please Fill All the Fields");
    } else {
      setLoading(true);
      login({ email, password, device: "web" })
        .then((res) => {
          notify(res?.data?.message);
          localStorage.setItem("token", JSON.stringify(res?.data?.data?.token));
          localStorage.setItem("userData", JSON.stringify(res?.data?.data?.user));
          dispatch(setLoginUser(res?.data?.data?.user));
          setTimeout(() => {
            const userType = res?.data?.data?.user?.user_type;
            if (userType === "super_admin") navigate("/admin");
            else if (userType === "institute") navigate("/institute");
            else navigate("/company/dashboard");
          }, 2000);
          setLoading(false);
        })
        .catch(() => {
          errorToast();
          setLoading(false);
        });
    }
  };

  return (
    <div className="MainContainer px-8 py-10 flex justify-center items-center w-full h-screen bg-gradient-to-br from-teal-700 via-white to-teal-100 bg-cover bg-center fixed">
      <form
        className="w-10 mx-2 md:w-96 max-h-[30rem] h-auto bg-blue-50 p-8 rounded-[2.75rem] shadow-lg flex flex-col"
      >
        <center className="flex justify-center h-14 my-8">
          <Link to="https://sailorshub.us.tempcloudsite.com/">
            <img
              src="/assets/authAssets/Sailors-Hub-logo.gif"
              alt="logo"
              className="w-60 h-14 object-contain  mx-auto"
              style={{filter:"drop-shadow(10px 10px 5px #999)"}}
            />
          </Link>
        </center>

        <div className="h-20 mb-2 md:mb-2 rounded-xl">
          <label className="block h-4 text-[#767676] text-base font-semibold font-montserrat">Email</label>
          <div className="relative h-12 flex items-center">
            <FaRegEnvelope className="absolute left-4 text-gray-400 size-5" />
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full h-10 pl-12 pr-4 shadow-custom py-2 text-sm border rounded-xl border-gray-300 focus:outline-none focus:ring-2 focus:ring-teal-400"
              placeholder="Enter your email"
            />
            <LineIcon className="absolute left-11 h-6 w-[2px]"/>
          </div>
        </div>

        <div className=" h-20">
          <label className="block h-4 text-[#767676] text-base font-semibold font-montserrat">Password</label>
          <div className="relative flex h-12 items-center">
            <IoMdKey className="absolute left-4 text-gray-400 size-5 after:content-['|']" />
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full h-10 pl-12 pr-4 shadow-custom py-2 text-sm border rounded-xl border-gray-300 focus:outline-none focus:ring-2 focus:ring-teal-400"
              placeholder="Enter your password"
            />
            <LineIcon className="absolute left-11 h-6 w-[2px]"/>
            {showPassword ? (
              <FaEye
                onClick={() => setShowPassword(false)}
                className="absolute right-2 text-gray-400 cursor-pointer size-5"
              />
            ) : (
              <FaEyeSlash
                onClick={() => setShowPassword(true)}
                className="absolute right-2 text-gray-400 cursor-pointer size-5"
              />
            )}
          </div>
        </div>

        <div className="flex justify-between items-center h-8 text-end">
          <Link to="/forgotPassword" className="text-teal-600 font-light text-xs hover:underline">
            Forgot Password?
          </Link>
        </div>

        <button
          type="button"
          onClick={handleSubmit}
          className="w-32 h-8 bg-[#2A737A] text-white py-1 rounded hover:bg-teal-700 transition mb-4"
          disabled={loading}
        >
          {loading ? (
            <img
              src="/assets/appLoaders/web_SailorsHub_Loader_crop.gif"
              alt="loading..."
              className="w-6 h-6 mx-auto"
            />
          ) : (
            <span>Login</span>
          )}
        </button>

      </form>
    </div>
  );
};

export default Login;
