// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-item:hover{
    background: rgb(171,232,255);
    background: linear-gradient(266deg, rgba(171,232,255,1) 0%, rgba(255,255,255,1) 100%);
   border-right: 3px solid rgb(7, 144, 194);
   max-height: 50px;
}
.allDropDown{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 20px;
    gap: 20px;
}
.dropDown-locality{
    max-width: 300px;
}
@media only screen and (max-width:700px) {
    .allDropDown{
        display: flex;
        flex-direction: column;
    }
    .dropDown-locality{
        max-width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/Admin/pages/masters/Country_state_city/locality.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,qFAAqF;GACtF,wCAAwC;GACxC,gBAAgB;AACnB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,aAAa;IACb,SAAS;AACb;AACA;IACI,gBAAgB;AACpB;AACA;IACI;QACI,aAAa;QACb,sBAAsB;IAC1B;IACA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".dropdown-item:hover{\n    background: rgb(171,232,255);\n    background: linear-gradient(266deg, rgba(171,232,255,1) 0%, rgba(255,255,255,1) 100%);\n   border-right: 3px solid rgb(7, 144, 194);\n   max-height: 50px;\n}\n.allDropDown{\n    display: flex;\n    flex-direction: row;\n    justify-content: space-around;\n    padding: 20px;\n    gap: 20px;\n}\n.dropDown-locality{\n    max-width: 300px;\n}\n@media only screen and (max-width:700px) {\n    .allDropDown{\n        display: flex;\n        flex-direction: column;\n    }\n    .dropDown-locality{\n        max-width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
